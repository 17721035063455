let FRONTEND_ENDPOINT;

switch (process.env.REACT_APP_MODE) {
  case "development":
    FRONTEND_ENDPOINT = "https://web.lab.storiusapp.com";
    break;
  case "production":
    FRONTEND_ENDPOINT = "https://web.storiusapp.com";
    break;
  default:
    FRONTEND_ENDPOINT = "http://localhost:3000";
}

export default FRONTEND_ENDPOINT;
