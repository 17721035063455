import React from "react";
import Spinner from "../Spinner";
import { toast } from "react-toastify";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";

const imageTypes = ["JPG", "JPEG", "PNG"];

const RenderLanguageForm = ({ formik, item, idx }) => {
  const axiosPrivate = useAxiosPrivate();

  const fileUpload = async (blob, type) => {
    let formData = new FormData();
    formData.append(type, blob);

    if (type === "image") {
      const res = await axiosPrivate.post(endpoints.UPLOAD_IMAGE, formData);
      return res.data.UPLOAD;
    } else if (type === "audio") {
      const res = await axiosPrivate.post(endpoints.UPLOAD_ADUIO, formData);
      return res.data.UPLOAD;
    } else {
      throw new Error("UNKNOWN_TYPE");
    }
  };

  const handleFileUpload = async (file, idx, field, type) => {
    if (file.length > 0) {
      // Activate Loader
      formik.setFieldValue(`languages[${idx}].${field}`, [
        ...formik.values.languages[idx][field],
        ...Array(file.length).fill("loader"),
      ]);

      // Upload to server
      const urls = await Promise.all(
        Array.from(file).map((item) => fileUpload(item, type))
      );

      // Update URL to form
      formik.setFieldValue(`languages[${idx}].${field}`, [
        ...formik.values.languages[idx][field],
        ...urls,
      ]);
    } else {
      // Activate Loader
      formik.setFieldValue(`languages[${idx}].${field}`, "loader");

      // Upload to server
      const url = await fileUpload(file, type);

      // Update URL to form
      formik.setFieldValue(`languages[${idx}].${field}`, url);
    }
  };

  const copyToOtherLanguages = (idx, field) => {
    const value = formik.values.languages[idx][field];
    const languages = formik.values.languages;
    languages.forEach((item, i) => {
      if (i !== idx) {
        formik.setFieldValue(`languages[${i}].${field}`, value);
      }
    });

    toast.success("Copied to other languages!", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const deleteImage = (langIdx, idx, field) => {
    // deleteImage(idx, mediaIdx, "medias");
    if (idx !== null) {
      formik.setFieldValue(
        `languages[${langIdx}].${field}`,
        formik.values.languages[langIdx][field].filter(
          (item, index) => index !== idx
        )
      );
    } else {
      formik.setFieldValue(`languages[${langIdx}].${field}`, "");
    }
  };

  const moveImageToTop = (langIdx, idx, field) => {
    // deleteImage(idx, mediaIdx, "medias");

    if (idx) {
      formik.setFieldValue(`languages[${langIdx}].${field}`, [
        formik.values.languages[langIdx][field][idx],
        ...formik.values.languages[langIdx][field].filter(
          (item, index) => index !== idx
        ),
      ]);
    } else {
      formik.setFieldValue(`languages[${langIdx}].${field}`, "");
    }
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
      <div className="flex flex-col space-y-2">
        <div className="flex justify-between items-center sm:block sm:space-y-4">
          <label
            htmlFor="coverImg"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Cover image <span className="text-rose-500">*</span>
          </label>
          {formik.values.languages[idx].coverImg && (
            <button
              type="button"
              onClick={() => {
                copyToOtherLanguages(idx, "coverImg");
              }}
              className="btn btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
            >
              Copy to other language(s)
            </button>
          )}
        </div>

        <div className="mt-1 sm:mt-0 sm:col-span-2">
          {formik.values.languages[idx].coverImg &&
            (formik.values.languages[idx].coverImg === "loader" ? (
              <div className="relative h-24 w-full">
                <Spinner />
              </div>
            ) : (
              <div className="relative w-auto h-48 aspect-square mb-2">
                <img
                  src={formik.values.languages[idx].coverImg}
                  alt="Cover Image"
                  className="h-full w-full object-cover rounded-lg"
                />
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    // TODO: Delete Cover Images
                    deleteImage(idx, null, "coverImg");
                  }}
                  className="group flex justify-center items-center absolute h-5 w-5 top-0 right-0 -mt-1 -mr-1 z-20"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="flex absolute h-4 w-4 z-10 text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                  <span className="relative inline-flex rounded-full h-5 w-5 bg-rose-500 group-hover:bg-rose-600"></span>
                </button>
              </div>
            ))}
          <input
            type="file"
            multiple={false}
            onChange={(e) => {
              handleFileUpload(e.target.files[0], idx, "coverImg", "image");
            }}
            name={`languages[${idx}].coverImg`}
            classes="h-48 max-w-lg flex justify-center px-6 py-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg"
            types={imageTypes}
          />
          <input
            onChange={(e) => {
              // Change value to only be the ID
              formik.setFieldValue(
                `languages[${idx}].coverImg`,
                e.target.value
              );
            }}
            onBlur={formik.handleBlur}
            value={formik.values.languages[idx].coverImg}
            placeholder="URL"
            className="mt-2 p-2 max-w-lg shadow-sm block w-full focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border border-gray-300 rounded-lg"
          />
          <p className="text-red-600 text-sm">
            {formik.errors.languages &&
            formik.touched.languages &&
            formik.errors.languages[idx]?.coverImg
              ? `Error: ${formik.errors.languages[idx]?.coverImg}`
              : null}
          </p>
        </div>
      </div>

      <div>
        <div className="flex flex-col space-y-2">
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Title <span className="text-rose-500">*</span>
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <textarea
              id="title"
              name={`languages[${idx}].title`}
              rows={3}
              value={formik.values.languages[idx].title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="max-w-lg shadow-sm block w-full focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border border-gray-300 rounded-lg resize-none"
            />
            <p className="text-red-600 text-sm">
              {formik.errors.languages &&
              formik.touched.languages &&
              formik.errors.languages[idx]?.title
                ? `Error: ${formik.errors.languages[idx]?.title}`
                : null}
            </p>
          </div>
        </div>

        <div className="flex flex-col space-y-2">
          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Description
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <textarea
              id="description"
              name={`languages[${idx}].description`}
              rows={10}
              value={formik.values.languages[idx].description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="max-w-lg shadow-sm block w-full focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border border-gray-300 rounded-lg resize-none"
            />
            <p className="text-red-600 text-sm">
              {formik.errors.languages &&
              formik.touched.languages &&
              formik.errors.languages[idx]?.description
                ? `Error: ${formik.errors.languages[idx]?.description}`
                : null}
            </p>
            <p className="mt-2 text-sm text-gray-500">
              Write a few sentences about the tour.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col space-y-2">
        <div className="flex justify-between items-center sm:block sm:space-y-4">
          <label
            htmlFor="medias"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Other photo(s)
          </label>
          {formik.values.languages[idx].medias &&
            formik.values.languages[idx].medias.length > 0 && (
              <button
                type="button"
                onClick={() => {
                  copyToOtherLanguages(idx, "medias");
                }}
                className="btn btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
              >
                Copy to other language(s)
              </button>
            )}
        </div>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="grid grid-cols-2 md:grid-cols-3 gap-x-2">
            {formik.values.languages[idx].medias &&
              formik.values.languages[idx].medias.length > 0 &&
              formik.values.languages[idx].medias.map((media, mediaIdx) => {
                if (media === "loader") {
                  return (
                    <div className="relative h-24 w-full" key={mediaIdx}>
                      <Spinner />
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={mediaIdx}
                      className="relative w-auto h-48 aspect-square mb-2"
                    >
                      <img
                        src={media}
                        alt="Cover Image"
                        className="w-full h-full object-cover rounded-lg"
                      />
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          deleteImage(idx, mediaIdx, "medias");
                        }}
                        className="group flex justify-center items-center absolute h-5 w-5 top-0 right-0 -mt-1 -mr-1 z-20"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={2}
                          stroke="currentColor"
                          className="flex absolute h-4 w-4 z-10 text-white"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                        <span className="relative inline-flex rounded-full h-5 w-5 bg-rose-500 group-hover:bg-rose-600"></span>
                      </button>
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          moveImageToTop(idx, mediaIdx, "medias");
                        }}
                        className="group flex justify-center items-center absolute h-5 w-5 top-7 right-0 -mt-1 -mr-1 z-20"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="flex absolute h-4 w-4 z-10 text-white"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"
                          />
                        </svg>
                        <span className="relative inline-flex rounded-full h-5 w-5 bg-amber-500 group-hover:bg-amber-600"></span>
                      </button>
                    </div>
                  );
                }
              })}
          </div>

          <input
            type="file"
            multiple={true}
            onChange={(e) =>
              handleFileUpload(e.target.files, idx, "medias", "image")
            }
            name={`languages[${idx}].medias`}
            classes="h-48 max-w-lg flex justify-center px-6 py-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg"
            types={imageTypes}
          />
          <p className="text-red-600 text-sm">
            {formik.errors.languages &&
            formik.touched.languages &&
            formik.errors.languages[idx]?.medias
              ? `Error: ${formik.errors.languages[idx]?.medias}`
              : null}
          </p>
        </div>
      </div>
    </div>
  );
};

export default RenderLanguageForm;
