import { Map, GeolocateControl, Marker } from "react-map-gl";
import GeocoderControl from "../maps/geocoder-control";
import SearchUserBar from "../users/SearchUserBar";

const GlobalSettingComponent = ({
  marker,
  setMarker,
  formik,
  story,
  user,
  storyOwner,
  setStoryOwner,
}) => {
  const updateLocation = (lnglat) => {
    // Update Formik
    formik.setFieldValue("location", lnglat);

    // Update Marker
    setMarker(lnglat);
  };

  const handleMapClick = (event) => {
    const lnglat = [event.lngLat.lng, event.lngLat.lat];
    updateLocation(lnglat);
  };

  const renderPublishStatus = (story) => {
    if (formik.values.visibility === 1 || formik.values.visibility === "1") {
      switch (story.publishStatus) {
        case "pending":
          return (
            <span className="text-xs inline-flex font-medium bg-slate-100 text-slate-500 rounded-full text-center px-2.5 py-1">
              Pending to review
            </span>
          );
        case "rejected":
          return (
            <span className="text-xs inline-flex font-medium bg-rose-100 text-rose-600 rounded-full text-center px-2.5 py-1">
              Rejected
            </span>
          );
        default:
          return;
      }
    }
  };

  const getBtnCSS = (isActive) => {
    if (isActive) {
      return "w-full sm:w-48 flex justify-center items-center px-3 py-2 border border-transparent text-sm leading-4 font-bold rounded-md shadow-sm text-white bg-emerald-600 hover:bg-emerald-700";
    } else {
      return "w-full sm:w-48 flex justify-center items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-bold rounded-md text-gray-700 bg-white hover:bg-gray-50";
    }
  };

  const handleThemeBtnClick = (theme) => {
    // If the themes array already contains the theme, remove it
    if (formik.values.themes.includes(theme)) {
      formik.setFieldValue(
        "themes",
        formik.values.themes.filter((item) => {
          return item !== theme;
        })
      );
    } else {
      // Else add it to the array
      formik.setFieldValue("themes", [...formik.values.themes, theme]);
    }
  };

  return (
    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
      {/*  Global Setting */}
      <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Global Settings
          </h3>
        </div>
        <div className="space-y-6 sm:space-y-5">
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
            <label
              htmlFor="owner"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Location <span className="text-rose-500">*</span>
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <Map
                initialViewState={{
                  longitude: marker[0] || 114.152666056,
                  latitude: marker[1] || 22.28416553,
                  zoom: marker.length === 2 ? 15 : 10,
                }}
                style={{
                  height: 400,
                  borderRadius: 10,
                }}
                mapStyle="mapbox://styles/mapbox/streets-v9"
                attributionControl={false}
                onClick={handleMapClick}
                mapboxAccessToken="pk.eyJ1Ijoid2FxYXM0NjkzIiwiYSI6ImNrcWF2MHF4OTBhYjQydG4weHVuZHRtbjMifQ.XSXJ4c0-03CovvpTCnDSqw"
              >
                {marker.length === 2 && (
                  <Marker longitude={marker[0]} latitude={marker[1]}></Marker>
                )}
                <GeolocateControl position="bottom-right" />
                <GeocoderControl
                  mapboxAccessToken="pk.eyJ1Ijoid2FxYXM0NjkzIiwiYSI6ImNrcWF2MHF4OTBhYjQydG4weHVuZHRtbjMifQ.XSXJ4c0-03CovvpTCnDSqw"
                  position="top-left"
                  language={navigator.language || navigator.userLanguage}
                  updateLocation={updateLocation}
                />
              </Map>
            </div>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
            <label
              htmlFor="themes"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Themes
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <div className="grid grid-cols-3 gap-2">
                <button
                  type="button"
                  onClick={() => {
                    // If the themes array already contains the theme, remove it
                    handleThemeBtnClick("History");
                  }}
                  className={getBtnCSS(
                    formik.values.themes.includes("History")
                  )}
                >
                  History
                </button>
                <button
                  type="button"
                  onClick={() => {
                    // If the themes array already contains the theme, remove it
                    handleThemeBtnClick("Religion");
                  }}
                  className={getBtnCSS(
                    formik.values.themes.includes("Religion")
                  )}
                >
                  Religion
                </button>
                <button
                  type="button"
                  onClick={() => {
                    // If the themes array already contains the theme, remove it
                    handleThemeBtnClick("Architecture");
                  }}
                  className={getBtnCSS(
                    formik.values.themes.includes("Architecture")
                  )}
                >
                  Architecture
                </button>
                <button
                  type="button"
                  onClick={() => {
                    // If the themes array already contains the theme, remove it
                    handleThemeBtnClick("Food");
                  }}
                  className={getBtnCSS(formik.values.themes.includes("Food"))}
                >
                  Food
                </button>
                <button
                  type="button"
                  onClick={() => {
                    // Set formik value
                    handleThemeBtnClick("Nature");
                  }}
                  className={getBtnCSS(formik.values.themes.includes("Nature"))}
                >
                  Nature
                </button>
                <button
                  type="button"
                  onClick={() => {
                    // Set formik value
                    handleThemeBtnClick("Pop-culture");
                  }}
                  className={getBtnCSS(
                    formik.values.themes.includes("Pop-culture")
                  )}
                >
                  Pop-culture
                </button>
                <button
                  type="button"
                  onClick={() => {
                    // Set formik value
                    handleThemeBtnClick("Personal");
                  }}
                  className={getBtnCSS(
                    formik.values.themes.includes("Personal")
                  )}
                >
                  Personal
                </button>
              </div>
              <p className="text-red-600 text-sm">
                {formik.errors.themes && formik.touched.themes
                  ? `Error: ${formik.errors.themes}`
                  : null}
              </p>
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
            <label
              htmlFor="isPremium"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Type <span className="text-rose-500">*</span>
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <div className="flex items-center space-x-2 mb-2">
                <button
                  type="button"
                  onClick={() => {
                    // Set formik value
                    formik.setFieldValue("isPremium", false);
                    formik.setFieldValue("isExclusive", false);
                  }}
                  className={getBtnCSS(
                    formik.values.isPremium === false &&
                    formik.values.isExclusive === false
                  )}
                >
                  Free
                </button>
                <button
                  type="button"
                  onClick={() => {
                    // Set formik value
                    formik.setFieldValue("isPremium", true);
                    formik.setFieldValue("isExclusive", false);
                  }}
                  className={getBtnCSS(
                    formik.values.isPremium === true &&
                    formik.values.isExclusive === false
                  )}
                >
                  Premium
                </button>
                <button
                  type="button"
                  onClick={() => {
                    // Set formik value
                    formik.setFieldValue("isPremium", false);
                    formik.setFieldValue("isExclusive", true);
                  }}
                  className={getBtnCSS(
                    formik.values.isPremium === false &&
                    formik.values.isExclusive === true
                  )}
                >
                  Exclusive
                </button>
              </div>
              <p className="text-red-600 text-sm mb-2">
                {formik.errors.isPremium && formik.touched.isPremium
                  ? `Error: ${formik.errors.isPremium}`
                  : null}
              </p>
              {/* WIP: Add premium/unlock users */}
              {/* {!(
                formik.values.isPremium === false &&
                formik.values.isExclusive === false
              ) && (
                <div className="mb-2">
                  <div>Add User to access:</div>
                </div>
              )} */}
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
            <div>
              <div
                className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                id="label-notifications"
              >
                Visibility <span className="text-rose-500">*</span>
              </div>
              <p className="text-sm text-gray-500">
                {renderPublishStatus(story)}
              </p>
            </div>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <div className="flex items-center space-x-2">
                <button
                  type="button"
                  onClick={() => {
                    // Set formik value
                    formik.setFieldValue("visibility", 0);
                  }}
                  className={getBtnCSS(formik.values.visibility === 0)}
                >
                  Public
                </button>
                <button
                  type="button"
                  onClick={() => {
                    // Set formik value
                    formik.setFieldValue("visibility", 1);
                  }}
                  className={getBtnCSS(formik.values.visibility === 1)}
                >
                  Unlisted
                </button>
                <button
                  type="button"
                  onClick={() => {
                    // Set formik value
                    formik.setFieldValue("visibility", 2);
                  }}
                  className={getBtnCSS(formik.values.visibility === 2)}
                >
                  Private
                </button>
              </div>

              <p className="text-red-600 text-sm">
                {formik.errors.visibility && formik.touched.visibility
                  ? `Error: ${formik.errors.visibility}`
                  : null}
              </p>
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
            <div>
              <div
                className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                id="label-notifications"
              >
                Publish Status <span className="text-rose-500">*</span>
              </div>
              <p className="text-sm text-gray-500">
                Whether this story is published
              </p>
            </div>
            <div className="sm:col-span-2">
              <div className="flex items-center space-x-2">
                <button
                  type="button"
                  onClick={() => {
                    // Set formik value
                    formik.setFieldValue("isDraft", false);
                  }}
                  className={getBtnCSS(formik.values.isDraft === false)}
                >
                  Published
                </button>
                <button
                  type="button"
                  onClick={() => {
                    // Set formik value
                    formik.setFieldValue("isDraft", true);
                  }}
                  className={getBtnCSS(formik.values.isDraft === true)}
                >
                  Draft
                </button>
              </div>
              <p className="text-red-600 text-sm">
                {formik.errors.isDraft && formik.touched.isDraft
                  ? `Error: ${formik.errors.isDraft}`
                  : null}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Admin Settings */}
      {user.role === "admin" && (
        <div className="divide-y divide-gray-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Admin Settings
            </h3>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
            <label
              htmlFor="type"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Priority
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <div className="sm:col-span-2">
                <div className="mt-4 space-y-4">
                  <select
                    id="priority"
                    name="priority"
                    value={formik.values.priority}
                    onChange={(e) => {
                      // Convert the value to an integer before setting it
                      const intValue = parseInt(e.target.value);
                      formik.handleChange({
                        target: {
                          name: "priority",
                          value: intValue,
                        },
                      });
                    }}
                    onBlur={formik.handleBlur}
                    className="max-w-lg block focus:ring-emerald-500 focus:border-emerald-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    {Array.from({ length: 101 }, (_, index) => (
                      <option key={index} value={index}>{index}</option>
                    ))}
                  </select>
                  {/* <p className="text-red-600 text-sm">
                              {formik.errors.type && formik.touched.type
                                ? `Error: ${formik.errors.type}`
                                : null}
                            </p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:py-5">
              <label
                htmlFor="owner"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Owner
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <span className="mb-2 text-medium inline-flex items-cetner font-extrabold bg-emerald-100 text-emerald-600 rounded-full text-center px-2.5 py-1">
                  {storyOwner.username}
                </span>
                <SearchUserBar setUser={setStoryOwner} />

                <p className="text-red-600 text-sm">
                  {formik.errors.owner && formik.touched.owner
                    ? `Error: ${formik.errors.owner}`
                    : null}
                </p>
              </div>
            </div>
            <div className="pt-6 sm:py-5">
              <div role="group" aria-labelledby="label-notifications">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                  <div>
                    <div
                      className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                      id="label-notifications"
                    >
                      Enable Status <span className="text-rose-500">*</span>
                    </div>
                    <p className="text-sm text-gray-500">
                      Whether this tour is visible to normal user (User are not
                      able to see this tour even if they are the owner)
                    </p>
                  </div>
                  <div className="sm:col-span-2">
                    <div className="flex items-center space-x-2">
                      <button
                        type="button"
                        onClick={() => {
                          // Set formik value
                          formik.setFieldValue("isEnabled", true);
                        }}
                        className={getBtnCSS(formik.values.isEnabled === true)}
                      >
                        Enabled
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          // Set formik value
                          formik.setFieldValue("isEnabled", false);
                        }}
                        className={getBtnCSS(formik.values.isEnabled === false)}
                      >
                        Disabled
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GlobalSettingComponent;
