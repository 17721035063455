import React from "react";
import { ReactComponent as LoadingIcon } from "../images/Loader.svg";

const Loader = ({ useFullScreen }) => {
  return (
    <div
      className={`${
        useFullScreen !== false ? "h-screen w-full" : "h-full w-full"
      } flex justify-center items-center`}
    >
      <LoadingIcon className="w-10 h-10 animate-spin fill-emerald-500 group-hover:fill-white" />
    </div>
  );
};

export default Loader;
