/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

export default function AddLanguagesBtn({ languages, addLanguagesHandler }) {
  return (
    <Menu as="li" className="relative text-left w-full sm:w-fit">
      <div>
        <Menu.Button className="btn text-center w-full bg-emerald-500 hover:bg-emerald-600 text-white">
          <div className="p-5 font-bold flex items-center">
            New Language
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="currentColor"
              className="ml-2 h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
          </div>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-30 max-h-48 overflow-auto origin-top-right absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          {languages && (
            <div className="py-1">
              {languages.map((language) => {
                return (
                  <Menu.Item key={language.value}>
                    <button
                      onClick={() => {
                        addLanguagesHandler(language.value);
                      }}
                      className="text-gray-700 hover:bg-gray-50 block px-5 py-2 text-sm text-left w-full"
                    >
                      {language.title}
                    </button>
                  </Menu.Item>
                );
              })}
            </div>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
