import StoriusLogoS from "../../assets/logo_s.svg";
import Message from "../../partials/listen/Message";

const NotFound = ({ code }) => {
  const renderMsg = (code) => {
    switch (code) {
      case "STORY_NOT_FOUND":
        return "Story not found";
      case "Tour_NOT_FOUND":
        return "Tour not found";
      case "UNAUTHORIZED":
        return "Unauthorized";
      case "ACCESS_DENIED":
        return "Access denied";
      case "IS_PREMIUM":
        return "This is a premium content, please login with a premium account";
      case "IS_PREMIUM_NO_ACCESS":
        return "This is a premium content, please upgrade your account to premium";
      default:
        return "Error occured";
    }
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center w-screen">
        <div className="m-auto flex flex-col justify-center items-center space-y-10">
          <div
            className={`drop-shadow-md flex flex-col justify-center items-center py-10 px-20 rounded-lg bg-gradient-to-br from-storius-orange to-storius-blue`}
          >
            <img src={StoriusLogoS} className="h-20 w-20" />
            <h1 className="font-bold text-white text-4xl">{renderMsg(code)}</h1>
          </div>
        </div>
        <div className="fixed z-10 bottom-0">
          <Message />
        </div>
      </div>
    </>
  );
};

export default NotFound;
