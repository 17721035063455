/* eslint-disable no-undef */
import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import useAuth from "../../hooks/useAuth"; // Auth
import ProcessingBtn from "../../components/btn/ProcessingBtn";
import { ReactComponent as Logo } from "../../images/logo/Logo.svg";
import axios from "../../api/axios";
import endpoints from "../../api/endpoints";
import FRONTEND_ENDPOINT from "../../utils/frontend";

import Success from "../../components/alert/Success";
import Warning from "../../components/alert/Warning";
import Danger from "../../components/alert/Danger";

function Signin() {
  // Warning
  const [warning, setWarning] = useState();

  // Auth
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const loginHandler = async (values, { setErrors, resetForm }) => {
    setLoading(true);
    try {
      const res = await axios.post(
        endpoints.LOGIN_URL,
        JSON.stringify(formik.values),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      // Set Auth Payload
      setAuth(res?.data);

      // Return to previous page
      navigate(from, { replace: true });
    } catch (err) {
      if (!err?.response) {
        console.error("No Server res");
      } else {
        if (!err?.response) {
          console.error("No Server res");
          setWarning(<Danger title="No Server Response" />);
        } else {
          let error = err?.response?.data?.errors?.msg;
          setWarning(<Warning title={error} />);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  // Google SSO
  useEffect(() => {
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: async (response) => {
        if (response.credential) {
          // Send credential to server
          const res = await axios.post(
            endpoints.GOOGLE_SSO_AUTH,
            {
              credential: response.credential,
            },
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          );

          // Set Auth Payload
          setAuth(res?.data);

          // Return to previous page
          navigate(from, { replace: true });
        }
      },
    });

    google.accounts.id.renderButton(
      document.getElementById("googleSSOBtn"),
      { theme: "outline", size: "large" } // customization attributes
    );

    // Apple SSO Button
    AppleID.auth.init({
      clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
      scope: "name email",
      redirectURI: `${FRONTEND_ENDPOINT}/signin`,
      usePopup: true,
    });

    // Listen for authorization success.
    document.addEventListener("AppleIDSignInOnSuccess", async (event) => {
      // Handle successful response.
      console.log("AppleIDSignInOnSuccess fired!");
      console.log(event.detail.authorization);
      if (event.detail.authorization.id_token) {
        // Send credential to server
        const res = await axios.post(
          endpoints.APPLE_SSO_AUTH,
          {
            credential: event.detail.authorization.id_token,
          },
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );

        // Set Auth Payload
        setAuth(res?.data);

        // Return to previous page
        navigate(from, { replace: true });
      }
    });

    // Listen for authorization failures.
    document.addEventListener("AppleIDSignInOnFailure", (event) => {
      // Handle error.
      console.log("AppleIDSignInOnFailure fired!");
      console.log(event.detail);
    });
  }, []);

  const [isLoading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      // rememberMe: false,
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Username/Email is required"),
      password: Yup.string().trim().required("Password is required"),
      // rememberMe: Yup.boolean(),
    }),
    onSubmit: loginHandler,
  });

  return (
    <main className="bg-white">
      <div className="relative md:flex">
        {/* Content */}
        <div className="w-full">
          <div className="min-h-screen h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <Logo className="h-8 w-8" />
                </Link>
              </div>
            </div>

            <div className="max-w-sm mx-auto sm:px-4 py-8 w-96">
              <h1 className="text-3xl text-slate-800 font-bold mb-4">
                Welcome back! ✨
              </h1>

              {/* Warning */}
              {warning !== null && <div className="mb-4">{warning}</div>}

              {/* Form */}
              <form onSubmit={formik.handleSubmit}>
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="username"
                    >
                      Username/Email
                    </label>
                    <input
                      name="username"
                      className="form-input w-full text-lg sm:text-base"
                      type="string"
                      autoComplete="on"
                      value={formik.username}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <p className="text-red-600 text-sm">
                      {formik.errors.username && formik.touched.username
                        ? `Error: ${formik.errors.username}`
                        : null}
                    </p>
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      name="password"
                      className="form-input w-full text-lg sm:text-base"
                      type="password"
                      autoComplete="on"
                      value={formik.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <p className="text-red-600 text-sm">
                      {formik.errors.password && formik.touched.password
                        ? `Error: ${formik.errors.password}`
                        : null}
                    </p>
                  </div>
                </div>
                {/* <div className="flex items-center justify-between mt-6">
                  <div className="mr-1">
                    <label className="flex items-center">
                      <input
                        name="rememberMe"
                        type="checkbox"
                        className="form-checkbox"
                        value={formik.rememberMe}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <span className="text-sm ml-2">Remember me</span>
                    </label>
                  </div>
                </div> */}
                <div className="flex items-center justify-end mt-6">
                  <div className="mr-1">
                    <Link
                      className="text-sm underline hover:no-underline"
                      to="/reset-password"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                </div>
                <div className="flex items-center justify-between mt-6">
                  {!isLoading && (
                    <button
                      type="submit"
                      className="btn bg-emerald-500 hover:bg-emerald-600 text-white w-full"
                    >
                      Sign In
                    </button>
                  )}
                  {isLoading && <ProcessingBtn title="Sign In" />}
                </div>
              </form>
              {/* Footer */}
              <div className="pt-5 mt-6 border-t border-slate-200 space-y-6">
                <div className="relative flex flex-col items-center justify-center space-y-4">
                  {/* Sign In With Google */}
                  <div
                    id="googleSSOBtn"
                    className="w-full flex justify-center"
                  ></div>

                  {/* AppleID signin button */}
                  <button
                    className="btn bg-black text-white w-full"
                    onClick={AppleID.auth.signIn}
                  >
                    Sign In with Apple
                  </button>
                </div>
                <div className="flex justify-center text-slate-800 text-sm gap-x-2">
                  <p>Don’t you have an account?</p>
                  <Link
                    className="font-medium text-emerald-500 hover:text-emerald-600"
                    to="/signup"
                  >
                    Sign Up
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Signin;
