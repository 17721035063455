import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAuth from "../../hooks/useAuth";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import Loader from "../../partials/Loader";
import AlertForm from "../../components/alert/AlertForm";

function Alert() {
  // Get User Profile
  const { auth } = useAuth();
  const user = auth.user;
  const [userAlert, setUserAlert] = useState({});
  const [languages, setLanguages] = useState({});

  const [isLoading, setLoading] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [isFetching, setIsFetching] = useState(true);

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  // Render Stories
  const getUserAlert = async () => {
    try {
      const res = await axiosPrivate.get(endpoints.ALERT_URL, {
        signal: controller.signal,
        headers: {
          "Accept-Language": "*",
        },
      });

      // Set Form
      if (res.data.hasAlert === true) {
        const alert = res.data.alert;
        setUserAlert(alert);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const submitHandler = async (values, { setErrors, resetForm }) => {
    setIsFetching(true);
    try {
      await axiosPrivate.post(endpoints.ALERT_URL, values, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      // Reload Page
      await getUserAlert();

      // Toast
      toast.success("Alert Created Successfully", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (err) {
      console.log(err);

      if (!err?.response) {
        console.error("No Server res");
      } else {
        const error = err?.response.data.errors.msg;
        toast.error(error, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } finally {
      setIsFetching(false);
    }
  };

  // Clear Alert
  const clearAlert = async () => {
    try {
      setIsFetching(true);

      await axiosPrivate.delete(endpoints.ALERT_URL, {
        signal: controller.signal,
      });

      setUserAlert({
        type: "general",
        languages: [],
      });

      await getUserAlert();

      toast.success("Alert Clear Successfully", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  const getLanguages = async () => {
    try {
      const res = await axiosPrivate.get(endpoints.LANGS_URL, {
        signal: controller.signal,
      });

      setLanguages(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const main = async () => {
    try {
      setIsFetching(true);
      await Promise.all([getUserAlert(), getLanguages()]);
    } catch (e) {
      console.error(e);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    main();
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        user={user}
      />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
        />

        <main>
          {/* Toast */}
          <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />

          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0 grid grid-flow-col sm:auto-cols-max justify-between sm:justify-start sm:justify-end gap-2">
                {/* Left: Title */}
                <div className="mb-4 sm:mb-0 flex items-center">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                    User Alert
                  </h1>
                  {userAlert.hasAlert === true && (
                    <div className="ml-2 text-xs inline-flex font-medium bg-emerald-100 text-emerald-600 rounded-full text-center px-2.5 py-1">
                      Alert Currently Active
                    </div>
                  )}

                  {userAlert.hasAlert === false && (
                    <div className="ml-2 text-xs inline-flex font-medium bg-rose-100 text-rose-600 rounded-full text-center px-2.5 py-1">
                      Currently No Alert
                    </div>
                  )}
                </div>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-between gap-2"></div>
            </div>
            {/* Table */}
            <AlertForm
              languages={languages}
              value={userAlert}
              isFetching={isFetching}
              onSubmit={submitHandler}
              onClear={clearAlert}
            />
          </div>
        </main>
      </div>
    </div>
  );
}

export default Alert;
