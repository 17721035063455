import React, { useState, useEffect } from "react";
import User from "./UsersTableItem";

function UsersTable({ selectedItems, handleSelectedItems, isFetching, users }) {
  return (
    <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative">
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-slate-200">
              <tr>
                {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="flex items-center">
                    <label className="inline-flex">
                      <span className="sr-only">Select all</span>
                      <input
                        className="form-checkbox"
                        type="checkbox"
                        onChange={handleSelectAll}
                      />
                    </label>
                  </div>
                </th> */}
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Username</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Email</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Role</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Premium</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Tipable</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <span className="sr-only">Menu</span>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="table-auto text-sm divide-y divide-slate-200">
              {users.map((user) => {
                return (
                  <User
                    key={user.id}
                    user={user}
                    handleClick={() => handleSelectedItems(user.id)}
                    isChecked={selectedItems.includes(user.id)}
                  />
                );
              })}
              {!isFetching && users.length === 0 && (
                <tr>
                  <td colSpan="6">
                    <div className="col-span-12 flex items-center justify-center py-5 text-xl">
                      {/* Show Empty */}
                      No Found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default UsersTable;
