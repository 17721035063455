import { useSearchParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import {
  queryToJson,
  constructQueryParams,
  constructNewURL,
} from "../../utils/listen/helper.js";
import Loader from "../../partials/Loader";
import Trending from "./Trending";

import useAxiosTryPrivate from "../../hooks/useAxiosTryPrivate";
import endpoints from "../../api/endpoints";

export default function CheckLegacy() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isInit, setIsInit] = useState(false);

  const axiosPrivate = useAxiosTryPrivate();
  const controller = new AbortController();

  const navigate = useNavigate();

  const init = async () => {
    const payload = queryToJson(searchParams.entries());

    // Check if storyId tourId langId inside the payload
    if (!("type" in payload)) {
      if ("storyId" in payload || "langId" in payload || "tourId" in payload) {
        // Do conversion
        // const result = await getLegacyConversions(
        //   constructQueryParams(payload)
        // );

        const res = await axiosPrivate.get(
          `${endpoints.LEGACY_URL}/${constructQueryParams(payload)}`,
          {
            signal: controller.signal,
          }
        );

        const result = res.data;

        // Negative to new url

        const newURL = constructNewURL(result);

        console.log(newURL);
        navigate(`/listen${newURL}`);
      }
    }

    setIsInit(true);
  };

  useEffect(() => {
    init();
  }, []);

  if (!isInit) return <Loader />;
  // Home
  return <Trending />;
}
