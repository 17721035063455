export function fstm(second) {
  // round to nearest second
  let s = Math.round(second);
  return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
}

export const constructQueryParams = (payload) => {
  let url = "?";
  for (const key of Object.keys(payload)) {
    url += `${key}=${payload[key]}&`;
  }

  return url;
};

export const constructNewURL = (payload) => {
  let url = "";

  const keys = Object.keys(payload);

  // Check if keys has tourId
  if (keys.includes("tourId")) {
    url += `/tour/${payload.tourId}`;

    // Check if keys has storyId (tour mode)
    if (keys.includes("storyId")) {
      url += `/${payload.storyId}`;
    }
  } else {
    // Check if keys has storyId
    if (keys.includes("storyId")) {
      url += `/story/${payload.storyId}`;
    }
  }

  // Check if keys has langId
  if (keys.includes("locale")) {
    url += `?locale=${payload.locale}`;
  }

  return url;
};

export const queryToJson = (entries) => {
  const payload = {};

  for (let entry of entries) {
    payload[entry[0]] = entry[1];
  }

  return payload;
};

export const renderLocales = (locales, languages) => {
  const payload = languages.filter((lang) => {
    return locales.includes(lang.value);
  });

  return payload;
};
