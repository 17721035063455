import React from "react";

import UserMenu from "../components/DropdownProfile";

import NotificationBtn from "./notification/NotificationBtn";

function Header(props) {
  return (
    <header className="sticky top-0 bg-white border-b border-slate-200 z-30">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">
          {/* Header: Left side */}
          <div className="flex gap-x-2">
            {/* Hamburger button */}
            {!props.leftBtnComponent ? (
              <button
                className="text-slate-500 hover:text-slate-600 lg:hidden"
                aria-controls="sidebar"
                aria-expanded={props.sidebarOpen}
                onClick={() => props.setSidebarOpen(!props.sidebarOpen)}
              >
                <span className="sr-only">Open sidebar</span>
                <svg
                  className="w-6 h-6 fill-current"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="4" y="5" width="16" height="2" />
                  <rect x="4" y="11" width="16" height="2" />
                  <rect x="4" y="17" width="16" height="2" />
                </svg>
              </button>
            ) : (
              props.leftBtnComponent
            )}

            {process.env.REACT_APP_MODE === "development" && (
              <span className="text-rose-600 font-bold">Dev Build</span>
            )}
            {process.env.REACT_APP_MODE === "locale" && (
              <span className="text-sky-600 font-bold">Local Build</span>
            )}
          </div>

          {/* Header: Right side */}
          <div className="flex items-center space-x-4">
            <NotificationBtn />

            <UserMenu align="right" user={props?.user} />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
