import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";
import BulkCreateCodeModal from "../../partials/exclusivepackages/BulkCreateCodeModal";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";

import LoadingBtn from "../../components/LoadingBtn";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import Loader from "../../partials/Loader";
import StorySelection from "../../partials/stories/StorySelection";
import TourSelection from "../../partials/tours/TourSelection";
import DeletePackageModal from "../../partials/exclusivepackages/DeletePackageModal";

export default function EditExPackage(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const { auth } = useAuth();
  const user = auth?.user;
  const [stories, setStories] = useState([]);
  const [tours, setTours] = useState([]);
  const [exPackage, setExPackage] = useState({
    title: "",
    remark: "",
    stories: [],
    tours: [],
    isEnabled: true,
    isSingleCode: false,
  });

  // Params
  const { id } = useParams();

  const getExPackage = async () => {
    setLoading(true);
    try {
      const res = await axiosPrivate.get(
        endpoints.EXCLUSIVE_PACKAGES_URL + `/${id}`,
        {
          signal: controller.signal,
        }
      );

      setExPackage(res.data);
      setStories(res.data.stories || []);
      setTours(res.data.tours || []);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getExPackage();
  }, []);

  // Download All Code
  const downloadAllCode = async () => {
    try {
      const res = await axiosPrivate.get(
        endpoints.EXCLUSIVE_PACKAGES_URL + `/${id}/code`,
        {
          responseType: "text/csv",
        }
      );

      const url = window.URL.createObjectURL(new Blob([res.data]));

      // trigger click event
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${exPackage.title || "untitled"}-redemption-codes.csv`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };

  const submitHandler = async () => {
    setIsSaving(true);
    const values = formik.values;
    try {
      // Preprocess stories and tours
      values.stories = stories.map((story) => story.id);
      values.tours = tours.map((tour) => tour.id);

      await axiosPrivate.patch(
        endpoints.EXCLUSIVE_PACKAGES_URL + `/${values.id}`,
        values,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      toast.success("Edit Successfully!", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setIsSaving(false);
    } catch (err) {
      console.log(err);

      toast.error("Error occurred!", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      if (!err?.response) {
        console.error("No Server res");
      } else {
        let error = err?.response.data.errors.msg;
        // setWarning(<Warning title="Invalid Token" />);
      }
    } finally {
      setIsSaving(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      id: exPackage.id,
      title: exPackage.title,
      remark: exPackage.remark,
      stories: exPackage.stories.map((story) => story.id),
      tours: exPackage.tours.map((tour) => tour.id),
      isEnabled: exPackage.isEnabled || true,
      isSingleCode: exPackage.isSingleCode || false,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("This field is required"),
      remark: Yup.string(),
      isEnabled: Yup.boolean().required("This field is required"),
      isSingleCode: Yup.boolean().required("This field is required"),
    }),
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  const updateRedemptionCount = async () => {
    try {
      const res = await axiosPrivate.get(
        endpoints.EXCLUSIVE_PACKAGES_URL + `/${id}`,
        {
          signal: controller.signal,
        }
      );

      // Update totalCodeCount and redeemedCodeCount
      setExPackage({
        ...exPackage,
        totalCodeCount: res.data?.totalCodeCount,
        redeemedCodeCount: res.data?.redeemedCodeCount,
      });
    } catch (error) {
      console.error(error);
    }
  };

  if (isLoading) return <Loader />;
  return (
    <>
      {/* Modal */}
      <BulkCreateCodeModal
        open={open}
        setOpen={setOpen}
        id={id}
        isSingleCode={formik.values.isSingleCode}
        updateRedemptionCount={updateRedemptionCount}
      />

      {/* Delete Modal */}
      <DeletePackageModal open={deleteOpen} setOpen={setDeleteOpen} id={id} />

      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
        />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            user={user}
          />

          <main>
            <ToastContainer />

            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full">
              <form onSubmit={formik.handleSubmit}>
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                  {/*  Global Setting */}
                  <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Edit Exclusive Package
                      </h3>
                    </div>
                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                        <label
                          htmlFor="title"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Title <span className="text-rose-500">*</span>
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="sm:col-span-2">
                            <div className="mt-4 space-y-4">
                              <div className="relative w-96">
                                <input
                                  type="text"
                                  name="title"
                                  value={formik.values.title}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  className="block w-full shadow-sm focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>

                              <p className="text-red-600 text-sm">
                                {formik.errors.title && formik.touched.title
                                  ? `Error: ${formik.errors.title}`
                                  : null}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                        <label
                          htmlFor="remark"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Remark
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="sm:col-span-2">
                            <div className="mt-4 space-y-4">
                              <div className="relative w-96">
                                <input
                                  type="text"
                                  name="remark"
                                  value={formik.values.remark}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  className="block w-full shadow-sm focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>

                              <p className="text-red-600 text-sm">
                                {formik.errors.remark && formik.touched.remark
                                  ? `Error: ${formik.errors.remark}`
                                  : null}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                        <label
                          htmlFor="isPremium"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Stories <span className="text-rose-500">*</span>
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="sm:col-span-2">
                            <div className="mt-4 space-y-4">
                              <StorySelection
                                formik={formik}
                                stories={stories}
                                setStories={setStories}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                        <label
                          htmlFor="isPremium"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Tours <span className="text-rose-500">*</span>
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="sm:col-span-2">
                            <div className="mt-4 space-y-4">
                              <TourSelection
                                formik={formik}
                                tours={tours}
                                setTours={setTours}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                        <div>
                          <div
                            className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                            id="label-notifications"
                          >
                            Enable Status{" "}
                            <span className="text-rose-500">*</span>
                          </div>
                          <p className="text-sm text-gray-500">
                            Whether this exPackage is visible to normal user
                            (User are not able to see this exPackage even if
                            they are the user)
                          </p>
                        </div>
                        <div className="sm:col-span-2">
                          <div className="max-w-lg">
                            <div className="mt-4 space-y-4">
                              <select
                                id="isEnabled"
                                name="isEnabled"
                                value={formik.values.isEnabled}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className="max-w-lg block focus:ring-emerald-500 focus:border-emerald-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                              >
                                <option value="true">Enabled</option>
                                <option value="false">Disabled</option>
                              </select>
                              <p className="text-red-600 text-sm">
                                {formik.errors.isEnabled &&
                                  formik.touched.isEnabled
                                  ? `Error: ${formik.errors.isEnabled}`
                                  : null}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                        <div>
                          <div
                            className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                            id="label-notifications"
                          >
                            Single Code{" "}
                            <span className="text-rose-500">*</span>
                          </div>
                          <p className="text-sm text-gray-500">
                            If you choose Single code Enable, please click "Bulk Create Redemption Code" to create a new code and one code can be used for multiple users.
                          </p>
                        </div>
                        <div className="sm:col-span-2">
                          <div className="max-w-lg">
                            <div className="mt-4 space-y-4">
                              <select
                                id="isSingleCode"
                                name="isSingleCode"
                                value={formik.values.isSingleCode}
                                onChange={(e) => {
                                  formik.setFieldValue("isSingleCode", e.target.value === 'true')
                                }}
                                onBlur={formik.handleBlur}
                                className="max-w-lg block focus:ring-emerald-500 focus:border-emerald-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                              >
                                <option value="true">Enabled</option>
                                <option value="false">Disabled</option>
                              </select>
                              <p className="text-red-600 text-sm">
                                {formik.errors.isSingleCode &&
                                  formik.touched.isSingleCode
                                  ? `Error: ${formik.errors.isSingleCode}`
                                  : null}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                        <div>
                          <div
                            className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                            id="label-notifications"
                          >
                            Redemption Status
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                          <div className="max-w-lg">
                            <div className="space-y-4 ">
                              <div>
                                {/* Redemption Count */}
                                <div className="flex items-center space-x-2">
                                  <div className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700">
                                    Redemption Count
                                  </div>
                                  <div className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700">
                                    {exPackage.redeemedCodeCount}/
                                    {exPackage.isSingleCode ? "unlimited" : exPackage.totalCodeCount}
                                  </div>
                                </div>
                              </div>
                              <div className="space-x-2">
                                {/* Download All Code Button */}
                                <button
                                  type="button"
                                  onClick={() => {
                                    downloadAllCode();
                                  }}
                                  className="btn bg-black text-white"
                                >
                                  Download All Code
                                </button>

                                {/* Create new code */}
                                <button
                                  type="button"
                                  onClick={() => {
                                    setOpen(true);
                                  }}
                                  className="btn bg-emerald-500 text-white"
                                >
                                  Bulk Create Redemption Code
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="py-5">
                  <div className="flex justify-between items-center space-x-4">
                    <div>
                      {/* Delete button with rose bg */}
                      <button
                        type="button"
                        className="btn bg-rose-500 text-white"
                        onClick={() => {
                          setDeleteOpen(true);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                    <div className="flex items-center space-x-2">
                      <Link
                        to={props.fallback || "/stories"}
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                      >
                        Go Back
                      </Link>
                      <div>
                        {isSaving ? (
                          <LoadingBtn text="Saving" />
                        ) : Object.keys(formik.errors).length > 0 ? (
                          <button
                            type="button"
                            disabled
                            className="btn bg-emerald-400 text-white cursor-not-allowed"
                          >
                            Save Changes
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn bg-emerald-500 hover:bg-emerald-600 text-white"
                          >
                            Save Changes
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              {/* Content */}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
