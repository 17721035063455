import SearchTourBar from "./SearchTourBar";
import { toast } from "react-toastify";
import { List, arrayMove } from "react-movable";

const TourSelection = ({ formik, tours, setTours }) => {
  const editTours = (payload) => {
    setTours(payload);

    formik.setFieldValue(
      "tours",
      payload.map((story) => story.id)
    );
  };

  const addTour = (story) => {
    // Check if story already exists
    if (tours.find((s) => s.id === story.id)) {
      toast.info("Tour already exists", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      editTours([...tours, story]);
    }
  };

  const removeTour = (id) => {
    editTours(tours.filter((story) => story.id !== id));
  };

  const renderToursList = () => {
    return (
      <List
        values={tours}
        onChange={({ oldIndex, newIndex }) =>
          editTours(arrayMove(tours, oldIndex, newIndex))
        }
        renderList={({ children, props }) => (
          <ul
            role="list"
            className="divide-y divide-gray-200 max-w-screen-xs"
            {...props}
          >
            {children}
          </ul>
        )}
        renderItem={({ value, props }) => (
          <li
            className="py-4 flex items-center justify-between cursor-move"
            {...props}
          >
            <div className="flex items-center">
              {value.coverImg ? (
                <img
                  className="h-10 w-10 rounded-full"
                  src={value.coverImg}
                  alt=""
                />
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-10 h-10"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"
                    />
                  </svg>
                </>
              )}

              <div className="ml-3">
                <div className="flex items-center gap-x-1">
                  {value.isPremium ? (
                    <span className="text-sm font-bold text-indigo-500">
                      [Premium]
                    </span>
                  ) : (
                    <span className="text-sm font-bold text-emerald-500">
                      [Free]
                    </span>
                  )}
                  <p className="text-sm font-medium text-gray-900">
                    {value.title}
                  </p>
                </div>

                <p className="text-sm text-gray-500">
                  {value?.username || "Unknown username"}
                </p>
              </div>
            </div>

            <div className="flex items-center gap-x-2">
              <a
                target="_blank"
                href={`/alltours/${value.id}/edit`}
                className="text-sky-500 underline"
              >
                Edit
              </a>
              <button
                onClick={() => {
                  removeTour(value.id);
                }}
                type="button"
                className="text-rose-500 underline"
              >
                Remove
              </button>
            </div>
          </li>
        )}
      />
    );
  };

  return (
    <div>
      {<SearchTourBar addTour={addTour} locale={navigator.language} />}
      <div className="max-h-48 overflow-scroll">{renderToursList()}</div>
      <p className="text-red-600 text-sm">
        {formik.errors.tours && formik.touched.tours
          ? `Error: ${formik.errors.tours}`
          : null}
      </p>
    </div>
  );
};

export default TourSelection;
