import { CheckIcon } from "@heroicons/react/solid";

export default function Success({ title, msg }) {
  return (
    <div className="rounded-md bg-emerald-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckIcon className="h-5 w-5 text-emerald-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-emerald-800">
            {title ? title : "Success"}
          </h3>
          {msg && <div className="mt-2 text-sm text-emerald-700">{msg}</div>}
        </div>
      </div>
    </div>
  );
}
