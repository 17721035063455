import { useEffect, useState } from "react";
import SearchUserBar from "./SearchUserBar";
import { toast } from "react-toastify";
import { List, arrayMove } from "react-movable";
import { ReactComponent as LoadingIcon } from "../../images/Loader.svg";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";

export default function UserSelectionUnlock({ tourId }) {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  const axiosPrivate = useAxiosPrivate();

  const getUsers = async () => {
    try {
      setLoading(true);
      const res = await axiosPrivate.get(
        endpoints.TOURS_URL + `/${tourId}/unlock/users`
      );

      setUsers(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const editUsers = (payload) => {
    setUsers(payload);
  };

  const addUser = async (user) => {
    // Check if user already exists
    if (users.find((s) => s.id === user.id)) {
      toast.info("User already exists", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      try {
        const res = await axiosPrivate.post(
          endpoints.TOURS_URL + `/${tourId}/unlock/users`,
          {
            userId: user.id,
          }
        );

        toast.success("User added", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setUsers(res.data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const removeUser = async (id) => {
    // Check if user already exists
    if (!users.find((s) => s.id === id)) {
      toast.info("User not exists", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      try {
        const res = await axiosPrivate.delete(
          endpoints.TOURS_URL + `/${tourId}/unlock/users`,
          {
            params: {
              userId: id,
            },
          }
        );

        toast.success("User removed", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setUsers(res.data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const renderUsersList = () => {
    return (
      <List
        values={users}
        onChange={({ oldIndex, newIndex }) =>
          editUsers(arrayMove(users, oldIndex, newIndex))
        }
        renderList={({ children, props }) => (
          <ul role="list" className="divide-y divide-gray-200" {...props}>
            {children}
          </ul>
        )}
        renderItem={({ value, props }) => (
          <li
            className="py-4 flex items-center justify-between cursor-move"
            {...props}
          >
            <div className="flex items-center">
              <div className="ml-3">
                <div className="flex items-center gap-x-1">
                  <p className="text-sm font-medium text-gray-900">
                    {value.username}
                  </p>
                </div>

                <p className="text-sm text-gray-500">{value?.id}</p>
              </div>
            </div>

            <div className="flex items-center gap-x-2">
              <button
                onClick={() => {
                  removeUser(value.id);
                }}
                type="button"
                className="text-rose-500 underline"
              >
                Remove
              </button>
            </div>
          </li>
        )}
      />
    );
  };

  if (loading) {
    return (
      <div className="h-40 p-4 rounded-lg shadow flex justify-center items-center">
        <LoadingIcon className="animate-spin h-12 w-12 " />
      </div>
    );
  }

  return (
    <div className="p-4 rounded-lg shadow">
      {<SearchUserBar setUser={addUser} locale={navigator.language} />}
      <div className="max-h-48 overflow-scroll">{renderUsersList()}</div>
    </div>
  );
}
