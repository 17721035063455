import {
  useSearchParams,
  useParams,
} from "react-router-dom";
import { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";

import { getLanguages } from "../../utils/listen/api.js";
import { renderLocales } from "../../utils/listen/helper.js";
import Player from "../../partials/listen/Player.js";
import Header from "../../partials/listen/Header.js";
import NotFound from "./NotFound";
import Loader from "../../partials/Loader";
import PremiumModal from "../../partials/listen/PremiumModal";
import useAxiosTryPrivate from "../../hooks/useAxiosTryPrivate";
import endpoints from "../../api/endpoints";

export default function StoryTourPlayer() {
  let [searchParams, setSearchParams] = useSearchParams();
  const { id, storyId } = useParams();
  const [story, setStory] = useState("");
  const [tour, setTour] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  // popup Premium story
  const [dangerPremiumModalOpen, setDangerPremiumModalOpen] = useState(false);

  // Get User Profile
  const [user, setUser] = useState(null);

  const axiosPrivate = useAxiosTryPrivate();
  const controller = new AbortController();

  // Get User Profile
  const getUserProfile = async () => {
    try {
      const res = await axiosPrivate.get(endpoints.PROFILE_URL, {
        signal: controller.signal,
      });

      setUser(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getStory = async (id, locale) => {
    let URL = `${endpoints.LISTEN_STORY_URL}/${id}`;

    if (locale !== undefined && locale !== null) {
      URL += `?locale=${locale}`;
    }

    const res = await axiosPrivate.get(URL, {
      signal: controller.signal,
    });

    return res?.data;
  };

  const getTour = async (id, locale) => {
    let URL = `${endpoints.LISTEN_TOUR_URL}/${id}`;

    if (locale !== undefined && locale !== null) {
      URL += `?locale=${locale}`;
    }

    const res = await axiosPrivate.get(URL, {
      signal: controller.signal,
    });

    return res?.data;
  };

  const init = async () => {
    setIsLoading(true);

    try {
      // Get User Profile
      await getUserProfile();

      // Get Locale
      const locale = searchParams.get("locale");

      // Check if storyId tourId langId inside the payload
      const [languages, story, tour] = await Promise.all([
        getLanguages(),
        getStory(storyId, locale),
        getTour(id, locale),
      ]);

      // Apply locale
      story.locale = renderLocales([story.locale], languages);
      story.locale = story.locale[0];
      story.otherLangs = renderLocales(story.otherLangs, languages);

      setStory(story);
      setTour(tour);
      if(story.hasPremiumLock) {
        setDangerPremiumModalOpen(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, [id, storyId]);

  if (!isLoading) {
    // TODO: Check if storyId inside tour stories
    let isExisting = tour.stories.find((tstory) => tstory.id === story.id);

    if (isExisting) {
      return (
        <>
          <ToastContainer />
          <PremiumModal
            id={story.id}
            open={dangerPremiumModalOpen}
            setOpen={setDangerPremiumModalOpen}
          />
          <Header tour={tour} story={story} user={user} />
          <div className="pt-[72px] md:pt-24 flex justify-center">
            <Player tour={tour} story={story} />
          </div>
        </>
      );
    } else {
      return (
        <>
          <ToastContainer />
          <Header tour={tour} story={story} user={user} />
          <div className="pt-[72px] md:pt-24 flex justify-center">
            <NotFound />
          </div>
        </>
      );
    }
  } else {
    return <Loader />;
  }
}
