import { Link, useLocation } from "react-router-dom";
import { ReactComponent as StoriusLogo } from "../../assets/logo.svg";
import studioLogo from "../../assets/recording.svg";
import LangSelect from "./LangSelect";
import UserMenu from "../../components/DropdownProfile";
import NotificationBtn from "../notification/NotificationBtn";

const Header = ({ story, tour, user }) => {
  const location = useLocation();

  return (
    <header className="fixed top-0 w-full z-20 transition-all">
      <nav
        className="
          flex flex-nowrap
          items-center
          justify-between
          py-4
          px-4 sm:px-8
          text-lg text-gray-700
          bg-white
          drop-shadow
          sticky 
          top-0 
          h-18
        "
      >
        <Link className="p-2 flex items-center space-x-2" to="/">
          <StoriusLogo className="h-6" />{" "}
          {process.env.REACT_APP_MODE === "development" && (
            <span className="text-rose-600 font-bold">Dev Build</span>
          )}
          {process.env.REACT_APP_MODE === "local" && (
            <span className="text-sky-600 font-bold">Local Build</span>
          )}
        </Link>
        <div className="flex gap-5 justify-end items-center">
          {/* {tour && story && <StorySelect tour={tour} story={story} />} */}
          {story && <LangSelect story={story} />}

          {!user && (
            <Link
              className="text-sm px-4 py-2 rounded-md bg-emerald-600 text-white"
              to="/signin"
              state={{ from: location }}
            >
              Sign in
            </Link>
          )}
          {/* Login */}
          {user && (
            <div className="flex items-center space-x-4">

                          <Link className="p-2 flex items-center space-x-2" to="/stories">
                <img
                  src={studioLogo}
                  alt="Studio Logo"
                  className="h-6"
                  style={{ fill: "red" }}
                />
              </Link>
              {/* Notification Btn */}
              <NotificationBtn />

              <UserMenu align="right" user={user} />
            </div>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Header;
