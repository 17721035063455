import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDDPnXqoyPByWIltqOIaIwZwqXK0dIN7v4",
  authDomain: "storius-319003.firebaseapp.com",
  projectId: "storius-319003",
  storageBucket: "storius-319003.appspot.com",
  messagingSenderId: "974789019224",
  appId: "1:974789019224:web:725ff7f1a0fad23aa36c2d",
  measurementId: "G-PN6Q205BNN",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
