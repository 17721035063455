import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FileUploader } from "react-drag-drop-files";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";

import LoadingBtn from "../../components/LoadingBtn";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import Loader from "../../partials/Loader";
import Spinner from "../../partials/Spinner";

const imageTypes = ["JPG", "JPEG", "PNG"];

function EditUser(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const [account, setAccount] = useState();
  const [user, setUser] = useState();

  // Params
  const { id } = useParams();

  // Get User Profile
  const getUserProfile = async () => {
    try {
      const res = await axiosPrivate.get(endpoints.PROFILE_URL, {
        signal: controller.signal,
      });

      setAccount(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getUser = async () => {
    try {
      const res = await axiosPrivate.get(endpoints.USERS_URL + `/${id}`, {
        signal: controller.signal,
      });

      setUser(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const sendResetPasswordEmail = async () => {
    try {
      setIsSending(true);
      await axiosPrivate.post(
        endpoints.FORGOTPW_URL,
        { email: user.email },
        {
          signal: controller.signal,
        }
      );

      toast.success("Successfully Sent Reset Email!", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  };

  const main = async () => {
    try {
      await Promise.all([getUserProfile(), getUser()]);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    main();
  }, []);

  const fileUpload = async (blob, type) => {
    let formData = new FormData();
    formData.append(type, blob);

    if (type === "image") {
      const res = await axiosPrivate.post(endpoints.UPLOAD_IMAGE, formData);
      return res.data.UPLOAD;
    } else if (type === "audio") {
      const res = await axiosPrivate.post(endpoints.UPLOAD_ADUIO, formData);
      return res.data.UPLOAD;
    } else {
      throw new Error("UNKNOWN_TYPE");
    }
  };

  const handleAvatarUpload = async (file) => {
    // Activate Loader
    formik.setFieldValue("avatar", "loader");

    // Upload to server
    const url = await fileUpload(file, "image");

    // Update URL to form
    formik.setFieldValue("avatar", url);
  };

  const submitHandler = async (values, { setErrors, resetForm }) => {
    setIsSaving(true);
    try {
      await axiosPrivate.patch(
        endpoints.USERS_URL + `/${formik.values.id}`,
        formik.values,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      console.log(values);

      toast.success("Edit Successfully!", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setIsSaving(false);
    } catch (err) {
      console.log(err);

      if (!err?.response) {
        toast.error("Error occurred!", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        let error = err?.response.data.errors.msg;

        toast.error(error, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } finally {
      setIsSaving(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      id: user?.id || "",
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      username: user?.username || "",
      bio: user?.bio || "",
      avatar: user?.avatar || "",
      isTippable: user?.isTippable || false,
      isPremium: user?.isPremium || false,
      sex: user?.sex || "unknown",
      phone: user?.phone || "",
      email: user?.email || "",
      premiumExpires: user?.premiumExpires || moment().add(7, "days"),
      role: user?.role || "user",
      emailVerified: user?.emailVerified || false,
      verified: user?.verified || false,
      isEnabled: user?.isEnabled || false,
    },
    validationSchema: Yup.object({
      id: Yup.string().required("This field is required"),
      firstName: Yup.string().required("This field is required"),
      lastName: Yup.string().optional(),
      bio: Yup.string().optional(),
      username: Yup.string().required("This field is required"),
      isTippable: Yup.boolean().required("This field is required"),
      sex: Yup.string()
        .oneOf(["male", "female", "unknown"])
        .required("This field is required"),
      phone: Yup.string().optional(),
      email: Yup.string().required("This field is required"),
      premiumExpires: Yup.date().required("This field is required"),
      role: Yup.string()
        .oneOf(["user", "admin"])
        .required("This field is required"),
      verified: Yup.boolean().required("This field is required"),
    }),
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  const setPremiumExp = (type) => {
    let date = null;

    if (type === "now") {
      date = moment();
    } else if (type === "reset") {
      date = user.premiumExpires;
    } else {
      date = moment(formik.values.premiumExpires)
        .add(1, type)
        .endOf("day")
        .format();
    }

    formik.setFieldValue("premiumExpires", date);
    formik.setFieldValue("isPremium", moment(date).isAfter());
  };

  console.log(formik.errors);

  if (isLoading) return <Loader />;
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        user={account}
      />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={account}
        />

        <main>
          <ToastContainer />

          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full">
            <form onSubmit={formik.handleSubmit}>
              <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                {/*  Global Setting */}
                <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Edit User
                    </h3>
                  </div>
                  <div className="space-y-6 sm:space-y-5">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t sm:border-gray-200 py-5">
                      <label
                        htmlFor="username"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Username <span className="text-rose-500">*</span>
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <textarea
                          id="username"
                          name="username"
                          rows="1"
                          value={formik.values.username}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="resize-none max-w-lg shadow-sm block w-full focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border border-gray-300 rounded-md"
                        />
                        <p className="text-red-600 text-sm">
                          {formik.errors.username && formik.touched.username
                            ? `Error: ${formik.errors.username}`
                            : null}
                        </p>
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t sm:border-gray-200 py-5">
                      <label
                        htmlFor="firstName"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        First Name <span className="text-rose-500">*</span>
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <textarea
                          id="firstName"
                          name="firstName"
                          rows="1"
                          value={formik.values.firstName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="resize-none max-w-lg shadow-sm block w-full focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border border-gray-300 rounded-md"
                        />
                        <p className="text-red-600 text-sm">
                          {formik.errors.firstName && formik.touched.firstName
                            ? `Error: ${formik.errors.firstName}`
                            : null}
                        </p>
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t sm:border-gray-200 py-5">
                      <label
                        htmlFor="lastName"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Last Name
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <textarea
                          id="lastName"
                          name="lastName"
                          rows="1"
                          value={formik.values.lastName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="resize-none max-w-lg shadow-sm block w-full focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border border-gray-300 rounded-md"
                        />
                        <p className="text-red-600 text-sm">
                          {formik.errors.lastName && formik.touched.lastName
                            ? `Error: ${formik.errors.lastName}`
                            : null}
                        </p>
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t sm:border-gray-200 py-5">
                      <label
                        htmlFor="bio"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Biography
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <textarea
                          id="bio"
                          name="bio"
                          rows="3"
                          value={formik.values.bio}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="resize-y max-w-lg shadow-sm block w-full focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border border-gray-300 rounded-md"
                        />
                        <p className="text-red-600 text-sm">
                          {formik.errors.bio && formik.touched.bio
                            ? `Error: ${formik.errors.bio}`
                            : null}
                        </p>
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t sm:border-gray-200 py-5">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Email <span className="text-rose-500">*</span>
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <textarea
                          id="email"
                          name="email"
                          rows="1"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="resize-none max-w-lg shadow-sm block w-full focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border border-gray-300 rounded-md"
                        />
                        <p className="text-red-600 text-sm">
                          {formik.errors.email && formik.touched.email
                            ? `Error: ${formik.errors.email}`
                            : null}
                        </p>
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t sm:border-gray-200 py-5">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Phone
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <textarea
                          id="phone"
                          name="phone"
                          rows="1"
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="resize-none max-w-lg shadow-sm block w-full focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border border-gray-300 rounded-md"
                        />
                        <p className="text-red-600 text-sm">
                          {formik.errors.phone && formik.touched.phone
                            ? `Error: ${formik.errors.phone}`
                            : null}
                        </p>
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t sm:border-gray-200 py-5">
                      <div className="flex justify-between items-center sm:block sm:space-y-4">
                        <label
                          htmlFor="avatar"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Avatar
                        </label>
                      </div>

                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        {formik.values.avatar &&
                          (formik.values.avatar === "loader" ? (
                            <div className="relative h-24 w-full">
                              <Spinner />
                            </div>
                          ) : (
                            <div className="relative flex justify-center">
                              <img
                                src={formik.values.avatar}
                                alt="Avatar"
                                className="w-48 h-48 rounded-full object-cover mb-2"
                              />
                              <button
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  // Delete Avatar
                                  formik.setFieldValue("avatar", "");
                                }}
                                className="group flex justify-center items-center absolute h-5 w-5 top-0 right-0 -mt-1 -mr-1 z-20"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={2}
                                  stroke="currentColor"
                                  className="flex absolute h-4 w-4 z-10 text-white"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                  />
                                </svg>
                                <span className="relative inline-flex rounded-full h-5 w-5 bg-rose-500 group-hover:bg-rose-600"></span>
                              </button>
                            </div>
                          ))}
                        <FileUploader
                          multiple={false}
                          handleChange={(file) => handleAvatarUpload(file)}
                          name="avatar"
                          classes="h-48 max-w-lg flex justify-center px-6 py-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
                          types={imageTypes}
                        />
                        <p className="text-red-600 text-sm">
                          {formik.errors.languages && formik.touched.languages
                            ? `Error: ${formik.errors.avatar}`
                            : null}
                        </p>
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                      <label
                        htmlFor="sex"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Sex
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="sm:col-span-2">
                          <div className="mt-4 space-y-4">
                            <select
                              id="sex"
                              name="sex"
                              value={formik.values.sex}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className="max-w-lg block focus:ring-emerald-500 focus:border-emerald-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            >
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                              <option value="unknown">N/A</option>
                            </select>
                            <p className="text-red-600 text-sm">
                              {formik.errors.sex && formik.touched.sex
                                ? `Error: ${formik.errors.sex}`
                                : null}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Admin Settings */}
                {account.role === "admin" && (
                  <div className="divide-y divide-gray-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Admin Settings
                      </h3>
                    </div>
                    <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
                      <div className="pt-6 sm:py-5">
                        <div role="group" aria-labelledby="label-notifications">
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                            <div>
                              <div
                                className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                                id="label-notifications"
                              >
                                Premium <span className="text-rose-500">*</span>
                              </div>
                              <p className="text-sm text-gray-500">
                                To set the "Free" plan, you just need to set
                                "Premium expires" date to past. In this case,
                                you can click "Now".
                              </p>
                            </div>
                            <div className="sm:col-span-2">
                              <div className="max-w-lg">
                                <div className="mt-4 space-y-4">
                                  <div
                                    className={`inline-flex items-center text-sm font-extrabold text-slate-100 ${
                                      moment(
                                        formik.values.premiumExpires
                                      ).isBefore()
                                        ? "bg-rose-500"
                                        : "bg-emerald-500"
                                    } rounded-full text-center px-4 py-1`}
                                  >
                                    Premium{" "}
                                    {moment(
                                      formik.values.premiumExpires
                                    ).isBefore()
                                      ? "expired"
                                      : "activated"}
                                    :{" "}
                                    {moment(
                                      formik.values.premiumExpires
                                    ).format("DD-MM-YYYY")}{" "}
                                    (
                                    {moment(
                                      formik.values.premiumExpires
                                    ).fromNow()}
                                    )
                                  </div>
                                  <div className="flex flex-wrap -space-x-px">
                                    <button
                                      type="button"
                                      onClick={() => setPremiumExp("now")}
                                      className="btn bg-white border-slate-200 hover:bg-slate-50 text-indigo-500 rounded-none first:rounded-l last:rounded-r"
                                    >
                                      Now
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => setPremiumExp("days")}
                                      className="btn bg-white border-slate-200 hover:bg-slate-50 text-slate-600 rounded-none first:rounded-l last:rounded-r"
                                    >
                                      +1 Day
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => setPremiumExp("weeks")}
                                      className="btn bg-white border-slate-200 hover:bg-slate-50 text-slate-600 rounded-none first:rounded-l last:rounded-r"
                                    >
                                      +1 Week
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => setPremiumExp("months")}
                                      className="btn bg-white border-slate-200 hover:bg-slate-50 text-slate-600 rounded-none first:rounded-l last:rounded-r"
                                    >
                                      +1 Month
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => setPremiumExp("years")}
                                      className="btn bg-white border-slate-200 hover:bg-slate-50 text-slate-600 rounded-none first:rounded-l last:rounded-r"
                                    >
                                      +1 Year
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => setPremiumExp("reset")}
                                      className="btn bg-white border-slate-200 hover:bg-slate-50 text-rose-600 rounded-none first:rounded-l last:rounded-r"
                                    >
                                      Reset
                                    </button>
                                  </div>
                                  <p className="text-red-600 text-sm">
                                    {formik.errors.isPremium &&
                                    formik.touched.isPremium
                                      ? `Error: ${formik.errors.isPremium}`
                                      : null}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t sm:border-gray-200 py-5">
                        <label
                          htmlFor="reset-password"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Reset Password
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          {isSending ? (
                            <span className="inline-flex items-center px-4 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-400">
                              Send Reset Password to Email...
                            </span>
                          ) : (
                            <button
                              type="button"
                              onClick={() => {
                                sendResetPasswordEmail();
                              }}
                              className="inline-flex items-center px-4 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                            >
                              Send Reset Password to Email
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="pt-6 sm:py-5">
                        <div role="group" aria-labelledby="label-notifications">
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                            <div>
                              <div
                                className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                                id="label-notifications"
                              >
                                Tipability{" "}
                                <span className="text-rose-500">*</span>
                              </div>
                              <p className="text-sm text-gray-500">
                                Whether this user can be tipped by other users
                              </p>
                            </div>
                            <div className="sm:col-span-2">
                              <div className="max-w-lg">
                                <div className="mt-4 space-y-4">
                                  <select
                                    id="isTippable"
                                    name="isTippable"
                                    value={formik.values.isTippable}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className="max-w-lg block focus:ring-emerald-500 focus:border-emerald-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                  >
                                    <option value="true">Enable</option>
                                    <option value="false">Disable</option>
                                  </select>
                                  <p className="text-red-600 text-sm">
                                    {formik.errors.isTippable &&
                                    formik.touched.isTippable
                                      ? `Error: ${formik.errors.isTippable}`
                                      : null}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pt-6 sm:py-5">
                        <div role="group" aria-labelledby="label-notifications">
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                            <div>
                              <div
                                className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                                id="label-notifications"
                              >
                                Verified User
                                <span className="text-rose-500">*</span>
                              </div>
                              <p className="text-sm text-gray-500">
                                Verified user will have a dedicated badge
                              </p>
                            </div>
                            <div className="sm:col-span-2">
                              <div className="max-w-lg">
                                <div className="mt-4 space-y-4">
                                  <select
                                    id="verified"
                                    name="verified"
                                    value={formik.values.verified}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className="max-w-lg block focus:ring-emerald-500 focus:border-emerald-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                  >
                                    <option value="true">Enable</option>
                                    <option value="false">Disable</option>
                                  </select>
                                  <p className="text-red-600 text-sm">
                                    {formik.errors.verified &&
                                    formik.touched.verified
                                      ? `Error: ${formik.errors.verified}`
                                      : null}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pt-6 sm:py-5">
                        <div role="group" aria-labelledby="label-notifications">
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                            <div>
                              <div
                                className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                                id="label-notifications"
                              >
                                Enable Status{" "}
                                <span className="text-rose-500">*</span>
                              </div>
                              <p className="text-sm text-gray-500">
                                Whether this user is visible to normal user
                                (User are not able to see this user even if they
                                are the owner)
                              </p>
                            </div>
                            <div className="sm:col-span-2">
                              <div className="max-w-lg">
                                <div className="mt-4 space-y-4">
                                  <select
                                    id="isEnabled"
                                    name="isEnabled"
                                    value={formik.values.isEnabled}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className="max-w-lg block focus:ring-emerald-500 focus:border-emerald-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                  >
                                    <option value="true">Enabled</option>
                                    <option value="false">Disabled</option>
                                  </select>
                                  <p className="text-red-600 text-sm">
                                    {formik.errors.isEnabled &&
                                    formik.touched.isEnabled
                                      ? `Error: ${formik.errors.isEnabled}`
                                      : null}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="py-5">
                <div className="flex justify-end items-center space-x-4">
                  <Link
                    to={props.fallback || "/stories"}
                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                  >
                    Go Back
                  </Link>
                  <div>
                    {isSaving ? (
                      <LoadingBtn text="Saving" />
                    ) : Object.keys(formik.errors).length > 0 ? (
                      <button
                        type="button"
                        disabled
                        className="btn bg-emerald-400 text-white cursor-not-allowed"
                      >
                        Save Changes
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn bg-emerald-500 hover:bg-emerald-600 text-white"
                      >
                        Save Changes
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
            {/* Content */}
          </div>
        </main>
      </div>
    </div>
  );
}

export default EditUser;
