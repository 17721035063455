import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import FRONTEND_ENDPOINT from "../../utils/frontend";
import DeleteModal from "./DeleteModal";
import EditMenu from "../../components/DropdownEditMenu";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";
import { toast } from "react-toastify";
import { ReactComponent as Logo } from "../../images/logo/LogoWhite.svg";
import { RWebShare } from "react-web-share";

function TourCard(props) {
  // Delete Tour
  const [dangerModalOpen, setDangerModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  let share_link = FRONTEND_ENDPOINT || "https://web.storiusapp.com";

  // Remove Tour Handler
  const removeTourHandler = async (tourId) => {
    try {
      setIsLoading(true);
      await axiosPrivate.delete(endpoints.TOURS_URL + "/" + tourId, {
        signal: controller.signal,
      });

      props.fetchHandler();

      // Toast
      toast.success("Tour has been removed successfully", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderPublishStatus = (status) => {
    switch (status) {
      case "pending":
        return (
          <span className="absolute top-0 w-full flex justify-center items-center px-2.5 py-0.5 text-xs font-bold bg-gray-500 text-white rounded-t-md">
            Pending to publish
          </span>
        );
      case "approved":
        return (
          <span className="absolute top-0 w-full flex justify-center items-center px-2.5 py-0.5 text-xs font-bold bg-emerald-500 text-white rounded-t-md">
            Published
          </span>
        );
      case "rejected":
        return (
          <span className="absolute top-0 w-full flex justify-center items-center px-2.5 py-0.5 text-xs font-bold bg-rose-500 text-white rounded-t-md">
            Rejected to publish
          </span>
        );
      default:
        return null;
    }
  };

  const deleteItem = (e) => {
    e.stopPropagation();
    setDangerModalOpen(true);
  };

  if (props.isLoading) {
    return (
      <div className="animate-pulse col-span-full sm:col-span-6 xl:col-span-3 bg-white shadow-lg rounded-lg border border-slate-200">
        <div className="flex flex-col h-full">
          {/* Card top */}
          <div className="grow">
            <div className="relative flex justify-between items-start">
              {/* Image + name */}
              <header className="w-full">
                <div className="flex flex-col mb-2">
                  <div className="relative w-full h-48">
                    <div className="h-full w-full bg-slate-200 rounded-t-lg" />
                  </div>
                </div>

                <div className="mt-1 p-5">
                  <div className="flex flex-col items-start gap-y-2">
                    <div className="h-5 bg-slate-200 w-1/2 rounded-full"></div>
                    <div className="h-5 bg-slate-200 w-full rounded-full"></div>
                  </div>
                </div>
              </header>
            </div>
          </div>
          {/* Card footer */}
          <div className="border-t border-slate-200">
            <div className="flex divide-x divide-slate-200r">
              <div className="block flex-1 text-center text-sm text-emerald-500 hover:text-emerald-600 font-medium px-3 py-4">
                <div className="flex items-center justify-center">
                  <div className="h-5 w-full bg-emerald-200 rounded-full"></div>
                </div>
              </div>
              <div className="block flex-1 text-center text-sm text-slate-600 hover:text-slate-800 font-medium px-3 py-4 group">
                <div className="flex items-center justify-center">
                  <div className="h-5 w-full bg-slate-200 rounded-full"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        {/* Empty Trash Modal */}
        <DeleteModal
          id={props.tour.id}
          deleteFunction={removeTourHandler}
          open={dangerModalOpen}
          setOpen={setDangerModalOpen}
          isLoading={isLoading}
        />

        {/* Rest */}
        <div className="col-span-full sm:col-span-6 xl:col-span-3 bg-white shadow-lg rounded-lg border border-slate-200">
          <div className="flex flex-col h-full">
            {/* Card top */}
            <div className="grow">
              <div className="relative flex justify-between items-start">
                {/* Image + name */}
                <header className="w-full">
                  <div className="flex flex-col">
                    <div className="relative w-full h-48">
                      {renderPublishStatus(props.tour.publishStatus)}

                      {props.tour.coverImg ? (
                        <img
                          className="h-full w-full object-cover overflow-hidden rounded-t-md"
                          src={props.tour.coverImg}
                          alt={props.tour.title}
                        />
                      ) : (
                        <Logo className="h-8 w-8 " />
                      )}

                      {/* View, Like, Bookmarked Badge */}
                      <div className="absolute bottom-0 left-0 text-sm inline-flex font-bold bg-slate-800 text-slate-100 rounded-r-md text-start px-2.5 py-1">
                        {props.tour.isDraft && (
                          <span className="mr-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                            Draft
                          </span>
                        )}
                        {props.tour.title}
                      </div>
                      {/* Premium Badges*/}
                      {props.tour.isPremium && (
                        <div className="absolute top-5 left-5 text-sm inline-flex font-bold bg-gradient-to-r from-main to-secondary text-slate-100 rounded-md text-center px-2.5 py-1">
                          Premium
                        </div>
                      )}
                    </div>
                    <div className="p-5 flex justify-between items-center w-full">
                      <div className="text-sm text-gray-500 inline-flex items-center gap-x-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                          />
                        </svg>
                        {props.tour.owner?.username ?? "[NOT FOUND]"}
                      </div>
                       {(props.tour?.owner?.id ==  props.user.id || props.user?.role === "admin") && (
                        <div className="flex items-center space-x-2">
                          <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.00003 5.8877C1.00003 4.86122 2.05557 4.60522 3.1111 4.60522V9.10515C2.55121 9.10515 2.01425 8.89444 1.61835 8.51937C1.22245 8.14431 1.00003 7.63561 1.00003 7.10518V5.8877Z" stroke="#919191" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M11.5554 5.8877C11.5554 4.86122 10.4999 4.60522 9.44434 4.60522V9.10515C10.0042 9.10515 10.5412 8.89444 10.9371 8.51937C11.333 8.14431 11.5554 7.63561 11.5554 7.10518V5.8877Z" stroke="#919191" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M3.11111 9.1051V4.10518C3.11111 3.30954 3.44474 2.54649 4.03859 1.98389C4.63245 1.42129 5.43789 1.10522 6.27773 1.10522C7.11756 1.10522 7.923 1.42129 8.51686 1.98389C9.11071 2.54649 9.44434 3.30954 9.44434 4.10518V9.1051" stroke="#919191" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                          <p className="w-auto truncate text-sm font-bold text-gray-700">
                            {props.tour?.view}
                          </p>
                        </div>
                      )} 
                    </div>
                  </div>
                </header>
                {/* Menu button */}
                <EditMenu
                  align="right"
                  className="absolute top-0 right-0 p-5 inline-flex shrink-0 drop-shadow-md border-gray-500"
                >
                  <li>
                    <RWebShare
                      data={{
                        text: "Listen to this amazing tour!",
                        url:
                          share_link +
                          "/listen/tour/" +
                          props.tour.id +
                          "?locale=" +
                          props.tour.locale,
                        title: props.tour.title,
                      }}
                      onClick={() => console.log("shared successfully!")}
                    >
                      <Link className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3">
                        Share
                      </Link>
                    </RWebShare>
                  </li>

                  <li>
                    <button
                      className="w-full font-medium text-sm text-rose-500 hover:text-rose-600 flex py-1 px-3"
                      onClick={deleteItem}
                    >
                      Delete
                    </button>
                  </li>
                </EditMenu>
              </div>
            </div>
            {/* Card footer */}
            <div className="border-t border-slate-200">
              <div className="flex divide-x divide-slate-200r">
                <a
                  className="block flex-1 text-center text-sm text-emerald-500 hover:text-emerald-600 font-medium px-3 py-4"
                  href={`${FRONTEND_ENDPOINT}/listen/tour/${props.tour.id}?locale=${props.tour.locale}`}
                >
                  <div className="flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4 shrink-0 mr-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                      />
                    </svg>

                    <span>Listen Tour</span>
                  </div>
                </a>
                <Link
                  className="block flex-1 text-center text-sm text-slate-600 hover:text-slate-800 font-medium px-3 py-4 group"
                  to={`${props.editUrl}/${props.tour.id}/edit`}
                >
                  <div className="flex items-center justify-center">
                    <svg
                      className="w-4 h-4 fill-current text-slate-400 group-hover:text-slate-500 shrink-0 mr-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.7.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM4.6 14H2v-2.6l6-6L10.6 8l-6 6zM12 6.6L9.4 4 11 2.4 13.6 5 12 6.6z" />
                    </svg>
                    <span>Edit</span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TourCard;
