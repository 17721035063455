import React, { useEffect } from "react";
import { ShareIcon } from "@heroicons/react/outline";
import { RWebShare } from "react-web-share";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";

import FRONTEND_ENDPOINT from "../../utils/frontend";
import StoryList from "./StoryList";
import CoverPicture from "./CoverPicture";
import Message from "./Message";
import Related from "./Related";

const TourPlayer = ({ tour }) => {
  const location = useLocation();

  const renderInfo = () => {
    return (
      <div className="flex items-center space-x-2 mb-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>

        <p className="truncate text-left text-gray-800 font-bold">
          {tour?.owner?.username ?? "Unknown username"}
        </p>
      </div>
    );
  };

  let share_link = FRONTEND_ENDPOINT || "https://web.storiusapp.com";
  share_link += `/listen/tour/${tour.id}?locale=${tour.locale}`;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{tour.title} | Storius</title>
        <link rel="canonical" href="http://storiusapp.com" />
        <meta
          property="og:description"
          content={`${tour.description} | Get more story tours from Storius App`}
        />
        <meta property="og:image" content={tour.coverImg} />
      </Helmet>

      <div className="fixed z-10 bottom-0">
        <Message tour={tour} />
      </div>
      <div className="w-full sm:w-[80vw] md:w-[50vw] lg:w-[35vw] grid grid-cols-1 gap-2 justify-center items-center">
        <CoverPicture img={tour.coverImg} isPremium={tour.isPremium} />
        <div className="px-4 sm:px-0">
          <div className="text-left font-bold text-2xl mb-2 text-gray-800 flex justify-between items-center">
            {tour.title}
            {/* Edit Btn */}
            <div className="flex items-center space-x-2">
              {tour.isOwner && (
                <Link to={`/tours/${tour.id}/edit`} state={{ from: location }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="h-8 w-8 transition ease-in-out hover:scale-110 duration-150"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                    />
                  </svg>
                </Link>
              )}
              <RWebShare
                data={{
                  text: "Listen to this amazing tour!",
                  url: share_link,
                  title: tour.title,
                }}
                sites={[
                  "whatsapp",
                  "facebook",
                  "twitter",
                  "telegram",
                  "mail",
                  "copy",
                ]}
                onClick={() => console.log("shared successfully!")}
              >
                <button>
                  <ShareIcon className="h-8 w-8 transition ease-in-out hover:scale-110 duration-150" />
                </button>
              </RWebShare>
            </div>
          </div>
          {renderInfo()}
          <StoryList tour={tour} storyList={tour.stories} />
          <Related id={tour.id} locale={tour.locale} />
        </div>
      </div>
    </>
  );
};

export default TourPlayer;
