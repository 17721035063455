import React, { useState, useEffect } from "react";
import { useDebouncedValue } from "@mantine/hooks";
import { ToastContainer } from "react-toastify";
import useAuth from "../../hooks/useAuth";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";

import PaginationClassic from "../../components/PaginationClassic";
import PaginationPageNumber from "../../components/PaginationPageNumber";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import SearchForm from "../../partials/actions/SearchForm";
import UsersTable from "../../partials/users/UsersTable";

function AllUsers() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
    console.log([...selectedItems]);
  };

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  // Get User Profile
  const { auth } = useAuth();
  const user = auth.user;

  // Get Stories
  const [users, setUsers] = useState(Array(20).fill(""));
  const [searchString, setSearchString] = useState("");
  const [debouncedSearchString] = useDebouncedValue(searchString, 200, {
    leading: true,
  });
  const [page, onChange] = useState(1);
  const [count, setCount] = useState(0);

  const [offset, setOffset] = useState(0);
  const limit = 20;

  // Render Stories
  const getUsers = async () => {
    try {
      setIsFetching(true);
      // Check if debouncedSearchString
      let params = {};
      if (debouncedSearchString) {
        console.log("Search String: " + debouncedSearchString);
        params = { search: debouncedSearchString };
      }

      params = { ...params, offset, limit };

      const res = await axiosPrivate.get(endpoints.USERS_URL, {
        signal: controller.signal,
        params: params,
      });

			const count =
        res.headers['x-total-count'] && parseInt(res.headers['x-total-count'])
      if (typeof count === 'number' && !Number.isNaN(count)) {
        setCount(count)
      } else {
				const resCount = await axiosPrivate.get(endpoints.USERS_URL + "/count", {
					signal: controller.signal,
					params: params,
				});

				setCount(resCount.data.count);
			}

      // Check update page number if total page is less than current page
      if (page > res?.data?.totalPages) {
        onChange(res?.data?.totalPages);
      }

      setUsers(res?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    // Reset Page Number when input
    onChange(1);
    setOffset(0);
  }, [debouncedSearchString]);

  useEffect(() => {
    // Render Stories
    getUsers();
  }, [debouncedSearchString, offset, limit]);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        user={user}
      />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
        />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <ToastContainer />

            {/* Page header */}
            <h1 className="text-2xl md:text-3xl text-slate-800 font-bold mb-8">
              All Users ✨
            </h1>
            <div className="sm:flex sm:justify-between sm:items-center mb-4">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0 grid grid-flow-col sm:auto-cols-max justify-between sm:justify-start gap-2">
                {/* Search form */}
                <SearchForm value={searchString} setValue={setSearchString} />
                {/* Filter button */}
                <div>
                  {/* <Link
                    to="/allusers/new"
                    className="btn border-slate-200 hover:border-slate-300 bg-white text-emerald-500 hover:bg-emerald-500 hover:text-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 mr-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                      />
                    </svg>
                    New Story
                  </Link> */}
                </div>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-between gap-2 gap-x-4">
                <div className="text-slate-600 text-sm flex items-center">
                  <PaginationPageNumber
                    offset={offset}
                    limit={limit}
                    count={count}
                  />
                </div>
                {/* Pagination */}
                <PaginationClassic
                  offset={offset}
                  setOffset={setOffset}
                  limit={limit}
                  count={count}
                  result={users.length}
                  isFetching={isFetching}
                />
              </div>
            </div>

            {/* Table */}
            <UsersTable
              users={users}
              selectedItems={selectedItems}
              handleSelectedItems={handleSelectedItems}
              isLoading={isFetching}
            />

            <div className="sm:flex sm:justify-between sm:items-center sm:mt-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0 grid grid-flow-col sm:auto-cols-max justify-between sm:justify-start gap-2"></div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-between gap-2 gap-x-4">
                <div className="text-slate-600 text-sm flex items-center">
									<PaginationPageNumber
                    offset={offset}
                    limit={limit}
                    count={count}
                  />
                </div>
                {/* Pagination */}
                <PaginationClassic
                  offset={offset}
                  setOffset={setOffset}
                  limit={limit}
                  count={count}
                  result={users.length}
                  isFetching={isFetching}
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default AllUsers;
