import React from "react";

const Transcript = ({ transcript }) => {
  return (
    <div className="mt-5 sm:rounded-lg overflow-hidden shadow-lg bg-[#25C9CA] text-white whitespace-pre-line">
      <div className="px-6 py-4">
        <div className="font-bold text-2xl mb-2">Transcript</div>
        <p className="text-base">{transcript}</p>
      </div>
    </div>
  );
};

export default Transcript;
