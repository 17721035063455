import { Fragment, useState, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

import Loader from "../Loader";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";

import { getMessaging, getToken, onMessage } from "firebase/messaging";
import app from "../../utils/FirebaseConnect.js";
import { toast } from "react-toastify";

const NotificationBtn = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [hasNew, setHasNew] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  let messaging;

  const getNotifications = async () => {
    try {
      const res = await axiosPrivate.get(endpoints.NOTIFICATION_URL);
      setNotifications(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Firebase Code
  const getTokenFromFirebase = (serviceWorkerRegistration) => {
    getToken(messaging, {
      vapidKey:
        "BDl7c1RcTXeCg_eMZoLwGhauK9iheVz2vMuZ5OFd834Sd1pWEFqoVXlR7_ew1Cd8ju79r7-lOdpDXubYsj3wWFw",
      serviceWorkerRegistration,
    })
      .then(async (currentToken) => {
        // Send Token
        await axiosPrivate.post(endpoints.NOTIFICATION_URL + "/session", {
          token: currentToken,
        });
      })
      .catch((err) => {
        console.log("Error retrieving registration token. ", err);
      });
  };

  useEffect(() => {
    try {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.register("/sw.js").then((registration) => {
          getTokenFromFirebase(registration);
        });
      }

      messaging = getMessaging(app);
      if (messaging) {
        onMessage(messaging, (payload) => {
          console.log("Message received. ", payload);

          const msg = JSON.parse(payload.data.raw);

          toast(
            <div className="flex items-start">
              <div className="ml-3 w-0 flex-1">
                <p className="text-sm font-medium text-gray-900">
                  {msg.kind.replace(/([A-Z])/g, " $1").trim()}
                </p>
                <p className="mt-1 text-sm text-gray-500">
                  {payload.data.title}
                </p>
              </div>
            </div>,
            {
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );

          setHasNew(true);
        });
      }
    } catch (err) {
      console.log("Cannot get service worker to work: ", err);
    }
  }, []);

  // End Firebase Code

  const NotiicationItem = (notification) => {
    let url = "#";

    if (notification?.type === "Story") {
      url = `/listen/story/${notification?.id}`;
    } else if (notification?.type === "Tour") {
      url = `/listen/tour/${notification?.id}`;
    }

    return (
      <Menu.Item key={notification.id}>
        <Link to={url} className="p-4 flex items-center hover:bg-gray-100">
          <div className="flex-shrink-0">
            {notification?.from?.avatar ? (
              <img
                className="h-12 w-12 rounded-full object-cover"
                src={notification?.from?.avatar}
                alt=""
              />
            ) : (
              <div className="h-12 w-12 flex items-center justify-center bg-gray-200 rounded-full object-cover">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                  />
                </svg>
              </div>
            )}
          </div>
          <div className="ml-4">
            <h3 className="text-sm leading-6 font-medium text-gray-900 line-clamp-2">
              {notification.plainText}
            </h3>
          </div>
        </Link>
      </Menu.Item>
    );
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          onClick={() => {
            setHasNew(false);
            getNotifications();
          }}
          className="relative px-4 py-2 hover:bg-gray-100 rounded-md"
        >
          <div className="relative px-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
              />
            </svg>
            {hasNew && (
              <span className="absolute top-0 right-0 flex h-3 w-3">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-rose-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-rose-500"></span>
              </span>
            )}
          </div>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute origin-top-right right-0 mt-5 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
          <div className="px-4 py-3">
            <p className="text-2xl font-bold">Notification</p>
          </div>
          <div className="w-full max-h-96 min-h-20 overflow-scroll">
            {isLoading ? (
              <div className="h-20">
                <Loader useFullScreen={false} />
              </div>
            ) : (
              notifications.map((notification) => NotiicationItem(notification))
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default NotificationBtn;
