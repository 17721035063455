import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../images/logo/Logo.svg";

function Sidebar({ sidebarOpen, setSidebarOpen, user }) {
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  );

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      // setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
        onClick={() => setSidebarOpen(!sidebarOpen)}
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-gray-900 p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "-translate-x-64"
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-slate-500 hover:text-slate-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <NavLink
            end
            to="/"
            className="flex items-center space-x-2"
            onClick={() => setSidebarOpen(!sidebarOpen)}
          >
            <Logo className="h-8" />
          </NavLink>
        </div>

        {/* Links */}
        <div className="h-full flex justify-between flex-col">
          {/* Pages group */}
          <div>
            <h3 className="text-xs uppercase text-slate-500 font-semibold pl-3">
              <span
                className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6"
                aria-hidden="true"
              >
                •••
              </span>
              <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                Storius
              </span>
            </h3>
            <ul className="mt-3">
              
            {/* Search */}
            <li>
              <NavLink
                end
                to="/SearchResult"
                className={`
                  px-3 py-2 rounded-sm mb-0.5 last:mb-0  && "bg-slate-900"
                  } 
                  block text-slate-200 hover:text-white truncate transition duration-150 ${
                     "hover:text-slate-200"
                  }`}
                onClick={() => setSidebarOpen(!sidebarOpen)}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" strokeLinejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>

                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Search
                    </span>
                  </div>
                </div>
              </NavLink>
            </li>

            
              {/* My Stories (Home) */}
              <li>
                <NavLink
                  end
                  to="/stories"
                  className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                    pathname.includes("/stories") && "bg-slate-900"
                  } block text-slate-200 hover:text-white truncate transition duration-150 ${
                    pathname.includes("/stories") && "hover:text-slate-200"
                  }`}
                  onClick={() => setSidebarOpen(!sidebarOpen)}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          className={`${
                            pathname.includes("/stories") && "text-emerald-500"
                          }`}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                        <path
                          className={`${
                            pathname.includes("/stories") && "text-emerald-500"
                          }`}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z"
                        />
                      </svg>

                      <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        My Stories
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>

              {/* My Tours */}
              <li>
                <NavLink
                  end
                  to="/tours"
                  className={`
                  px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                    pathname.includes("/tours") && "bg-slate-900"
                  } 
                  block text-slate-200 hover:text-white truncate transition duration-150 ${
                    pathname.includes("/tours") && "hover:text-slate-200"
                  }`}
                  onClick={() => setSidebarOpen(!sidebarOpen)}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          className={`${
                            pathname.includes("/tours") && "text-emerald-500"
                          }`}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12.75 3.03v.568c0 .334.148.65.405.864l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 01-1.161.886l-.143.048a1.107 1.107 0 00-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 01-1.652.928l-.679-.906a1.125 1.125 0 00-1.906.172L4.5 15.75l-.612.153M12.75 3.031a9 9 0 00-8.862 12.872M12.75 3.031a9 9 0 016.69 14.036m0 0l-.177-.529A2.25 2.25 0 0017.128 15H16.5l-.324-.324a1.453 1.453 0 00-2.328.377l-.036.073a1.586 1.586 0 01-.982.816l-.99.282c-.55.157-.894.702-.8 1.267l.073.438c.08.474.49.821.97.821.846 0 1.598.542 1.865 1.345l.215.643m5.276-3.67a9.012 9.012 0 01-5.276 3.67m0 0a9 9 0 01-10.275-4.835M15.75 9c0 .896-.393 1.7-1.016 2.25"
                        />
                      </svg>

                      <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        My Tours
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            </ul>
            {user && user?.role === "admin" && (
              <>
                <h3 className="text-xs uppercase text-slate-500 font-semibold pl-3">
                  <span
                    className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6"
                    aria-hidden="true"
                  >
                    •••
                  </span>
                  <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                    Admin
                  </span>
                </h3>
                <ul className="mt-3">
                  {/* All Stories (Home) */}
                  <li>
                    <NavLink
                      end
                      to="/allstories"
                      className={`
                      px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                        pathname.includes("/allstories") && "bg-slate-900"
                      } 
                      block text-slate-200 hover:text-white truncate transition duration-150 ${
                        pathname.includes("/allstories") &&
                        "hover:text-slate-200"
                      }`}
                      onClick={() => setSidebarOpen(!sidebarOpen)}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              className={`${
                                pathname.includes("/allstories") &&
                                "text-emerald-500"
                              }`}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                            <path
                              className={`${
                                pathname.includes("/allstories") &&
                                "text-emerald-500"
                              }`}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z"
                            />
                          </svg>

                          <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                            All Stories
                          </span>
                        </div>
                      </div>
                    </NavLink>
                  </li>

                  {/* All Tours */}
                  <li>
                    <NavLink
                      end
                      to="/alltours"
                      className={`
                      px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                        pathname.includes("/alltours") && "bg-slate-900"
                      } 
                      block text-slate-200 hover:text-white truncate transition duration-150 ${
                        pathname.includes("/alltours") && "hover:text-slate-200"
                      }`}
                      onClick={() => setSidebarOpen(!sidebarOpen)}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              className={`${
                                pathname.includes("/alltours") &&
                                "text-emerald-500"
                              }`}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12.75 3.03v.568c0 .334.148.65.405.864l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 01-1.161.886l-.143.048a1.107 1.107 0 00-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 01-1.652.928l-.679-.906a1.125 1.125 0 00-1.906.172L4.5 15.75l-.612.153M12.75 3.031a9 9 0 00-8.862 12.872M12.75 3.031a9 9 0 016.69 14.036m0 0l-.177-.529A2.25 2.25 0 0017.128 15H16.5l-.324-.324a1.453 1.453 0 00-2.328.377l-.036.073a1.586 1.586 0 01-.982.816l-.99.282c-.55.157-.894.702-.8 1.267l.073.438c.08.474.49.821.97.821.846 0 1.598.542 1.865 1.345l.215.643m5.276-3.67a9.012 9.012 0 01-5.276 3.67m0 0a9 9 0 01-10.275-4.835M15.75 9c0 .896-.393 1.7-1.016 2.25"
                            />
                          </svg>

                          <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                            All Tours
                          </span>
                        </div>
                      </div>
                    </NavLink>
                  </li>

                  {/* All Users */}
                  <li>
                    <NavLink
                      end
                      to="/allusers"
                      className={`
                      px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                        pathname.includes("/allusers") && "bg-slate-900"
                      } 
                      block text-slate-200 hover:text-white truncate transition duration-150 ${
                        pathname.includes("/allusers") && "hover:text-slate-200"
                      }`}
                      onClick={() => setSidebarOpen(!sidebarOpen)}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              className={`${
                                pathname.includes("/allusers") &&
                                "text-emerald-500"
                              }`}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                            />
                          </svg>

                          <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                            All Users
                          </span>
                        </div>
                      </div>
                    </NavLink>
                  </li>

                  {/* Giftcode */}
                  <li>
                    <NavLink
                      end
                      to="/giftcodes"
                      className={`
                      px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                        pathname.includes("/giftcodes") && "bg-slate-900"
                      } 
                      block text-slate-200 hover:text-white truncate transition duration-150 ${
                        pathname.includes("/giftcodes") &&
                        "hover:text-slate-200"
                      }`}
                      onClick={() => setSidebarOpen(!sidebarOpen)}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className={`${
                                pathname.includes("/giftcodes") &&
                                "stroke-emerald-500"
                              }`}
                              d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z"
                            />
                          </svg>

                          <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                            Gift Codes
                          </span>
                        </div>
                      </div>
                    </NavLink>
                  </li>     
                  {/* Banners */}
                  <li>
                    <NavLink
                      end
                      to="/allbanners"
                      className={`
                      px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                        pathname.includes("/allbanners") && "bg-slate-900"
                      } 
                      block text-slate-200 hover:text-white truncate transition duration-150 ${
                        pathname.includes("/allbanners") &&
                        "hover:text-slate-200"
                      }`}
                      onClick={() => setSidebarOpen(!sidebarOpen)}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className={`${
                                pathname.includes("/allbanners") &&
                                "stroke-emerald-500"
                              }`}
                              d="M3 3v1.5M3 21v-6m0 0l2.77-.693a9 9 0 016.208.682l.108.054a9 9 0 006.086.71l3.114-.732a48.524 48.524 0 01-.005-10.499l-3.11.732a9 9 0 01-6.085-.711l-.108-.054a9 9 0 00-6.208-.682L3 4.5M3 15V4.5"
                            />
                          </svg>

                          <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                            Banners
                          </span>
                        </div>
                      </div>
                    </NavLink>
                  </li>

                  {/* Exclusive Packages */}
                  <li>
                    <NavLink
                      end
                      to="/exclusivepackages"
                      className={`
                      px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                        pathname.includes("/exclusivepackages") &&
                        "bg-slate-900"
                      } 
                      block text-slate-200 hover:text-white truncate transition duration-150 ${
                        pathname.includes("/exclusivepackages") &&
                        "hover:text-slate-200"
                      }`}
                      onClick={() => setSidebarOpen(!sidebarOpen)}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className={`${
                                pathname.includes("/exclusivepackages") &&
                                "stroke-emerald-500"
                              }`}
                              d="M15.75 5.25a3 3 0 0 1 3 3m3 0a6 6 0 0 1-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1 1 21.75 8.25Z"
                            />
                          </svg>

                          <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                            Exclusive Packages
                          </span>
                        </div>
                      </div>
                    </NavLink>
                  </li>

                  {/* Publish Request */}
                  <li>
                    <NavLink
                      end
                      to="/publishes"
                      className={`
                      px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                        pathname.includes("/publishes") && "bg-slate-900"
                      } 
                      block text-slate-200 hover:text-white truncate transition duration-150 ${
                        pathname.includes("/publishes") &&
                        "hover:text-slate-200"
                      }`}
                      onClick={() => setSidebarOpen(!sidebarOpen)}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                              className={`${
                                pathname.includes("publishes") &&
                                "stroke-emerald-500"
                              }`}
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                              className={`${
                                pathname.includes("publishes") &&
                                "stroke-emerald-500"
                              }`}
                            />
                          </svg>

                          <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                            Publish Request
                          </span>
                        </div>
                      </div>
                    </NavLink>
                  </li>

                  {/* User Alert */}
                  <li>
                    <NavLink
                      end
                      to="/alert"
                      className={`
                      px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                        pathname.includes("/alert") && "bg-slate-900"
                      } 
                      block text-slate-200 hover:text-white truncate transition duration-150 ${
                        pathname.includes("/alert") && "hover:text-slate-200"
                      }`}
                      onClick={() => setSidebarOpen(!sidebarOpen)}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className={`${
                                pathname.includes("alert") &&
                                "stroke-emerald-500"
                              }`}
                              d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46"
                            />
                          </svg>

                          <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                            User Alert
                          </span>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                </ul>
              </>
            )}
          </div>
          {/* More group */}
          <div>
            <h3 className="text-xs uppercase text-slate-500 font-semibold pl-3">
              <span
                className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6"
                aria-hidden="true"
              >
                •••
              </span>
              <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                Others
              </span>
            </h3>
            <ul className="mt-3">
              {/* Trash */}
              {/* <li>
                <NavLink
                  end
                  to="/trash"
                  className={`
                  px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                    pathname.includes("trash") && "bg-slate-900"
                  }
                  block text-slate-200 hover:text-white truncate transition duration-150 ${
                    pathname.includes("trash") && "hover:text-slate-200"
                  }`}
                  onClick={() => setSidebarOpen(!sidebarOpen)}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          className={`${
                            pathname.includes("trash") && "stroke-emerald-500"
                          }`}
                        />
                      </svg>

                      <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Trash
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li> */}

              {/* Settings */}
              <li>
                <NavLink
                  end
                  to="/settings"
                  className={`
                  px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                    pathname.includes("settings") && "bg-slate-900"
                  }
                  block text-slate-200 hover:text-white truncate transition duration-150 ${
                    pathname.includes("settings") && "hover:text-slate-200"
                  }`}
                  onClick={() => setSidebarOpen(!sidebarOpen)}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          className={`${
                            pathname.includes("settings") &&
                            "stroke-emerald-500"
                          }`}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                        />
                      </svg>

                      <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Settings
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
          <div className="px-3 py-2">
            <button
              onClick={() => {
                setSidebarExpanded(!sidebarExpanded);
              }}
            >
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg
                className="w-6 h-6 fill-current sidebar-expanded:rotate-180"
                viewBox="0 0 24 24"
              >
                <path
                  className="text-slate-400"
                  d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                />
                <path className="text-slate-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
