import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAuth from "../../hooks/useAuth";
import useRefreshToken from "../../hooks/useRefreshToken";
import endpoints from "../../api/endpoints";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useLogout from "../../hooks/useLogout";
import { useNavigate } from "react-router-dom";

function Delete() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  // Get User Profile
  const { auth } = useAuth();
  const user = auth.user;
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const logout = useLogout();
  const refresh = useRefreshToken();

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleDeleteAccount = async () => {
    if (inputValue === "DELETE" && user.role !== "admin") {
      // Call the delete account API
      try {
        const response = await axiosPrivate.delete(
          endpoints.PROFILE_URL,
          {
            data: {
              confirmMsg: "permanently delete"
            }
          }
        );
        // Check the response status and show success message
        if (response.status === 200) {
          toast.success("Account deleted successfully");
          await logout();
          navigate("/");
        } else {
          toast.error("Failed to delete account");
        }
      } catch (error) {
        toast.error("An error occurred while deleting the account");
        console.error(error);
      }
    } else {
      toast.error("Input does not match. Please type DELETE to confirm.");
    }
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        user={user}
      />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
        />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <h1 className="text-2xl md:text-3xl text-slate-800 font-bold mb-8">
              Delete Account⚠
            </h1>
            <h1 className="text-2xl md:text-3xl text-slate-800 ">
              Permanently delete your account?
            </h1>
            <h1 className="text-lg md:text-xl text-slate-800 font-bold mb-4">
              We are sorry to see you go
            </h1>

            <h1 className="text-lg md:text-xl text-slate-800 font-bold">
              Deleting account will do the following:
              <ul className="list-disc pl-6">
                <li className="mb-2">Log you out of the website</li>
                <li className="mb-2">Delete all of your account information</li>
              </ul>
            </h1>

            <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
              You don't need to delete your account to log out or unsubscribe
              from premium
            </h1>

            <h1 className="text-2xl md:text-3xl text-slate-800 font-bold mb-4">
              Are you sure you want to delete all your account data?
            </h1>

            <h1 className="text-2xl md:text-3xl text-gray-500">
              Type DELETE to confirm
            </h1>
            <input
              type="text"
              id="inputField"
              placeholder="Input"
              value={inputValue}
              onChange={handleInputChange}
            />

            <button
              type="submit"
              onClick={handleDeleteAccount}
              className="btn bg-red-500 hover:bg-red-600 text-white"
            >
              Delete Account
            </button>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Delete;
