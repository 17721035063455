import axios from "axios";
import API_ENDPOINT from "../utils/backend";

export default axios.create({
  baseURL: API_ENDPOINT,
});

export const axiosPrivate = axios.create({
  baseURL: API_ENDPOINT,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
