import React, { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import DeleteModal from "./DeleteModal";
import EditMenu from "../../components/DropdownEditMenu";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";
import { toast } from "react-toastify";

function BannerCard(props) {
  // Delete Banner
  const [dangerModalOpen, setDangerModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  // Remove Banner Handler
  const removeBannerHandler = async (bannerId) => {
    try {
      setIsLoading(true);
      await axiosPrivate.delete(endpoints.BANNERS_URL + "/" + bannerId, {
        signal: controller.signal,
      });

      props.fetchHandler();

      // Toast
      toast.success("Banner has been removed successfully", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteItem = (e) => {
    e.stopPropagation();
    setDangerModalOpen(true);
  };

  if (props.isLoading) {
    return (
      <div className="animate-pulse col-span-full sm:col-span-6 xl:col-span-3 bg-white shadow-lg rounded-lg border border-slate-200">
        <div className="flex flex-col h-full">
          {/* Card top */}
          <div className="grow">
            <div className="relative flex justify-between items-start">
              {/* Image + name */}
              <header className="w-full">
                <div className="flex flex-col mb-2">
                  <div className="relative w-full h-48">
                    <div className="h-full w-full bg-slate-200 rounded-t-lg" />
                  </div>
                </div>

                <div className="mt-1 p-5">
                  <div className="flex flex-col items-start gap-y-2">
                    <div className="h-5 bg-slate-200 w-1/2 rounded-full"></div>
                    <div className="h-5 bg-slate-200 w-full rounded-full"></div>
                  </div>
                </div>
              </header>
            </div>
          </div>
          {/* Card footer */}
          <div className="border-t border-slate-200">
            <div className="flex divide-x divide-slate-200r">
              <div className="block flex-1 text-center text-sm text-emerald-500 hover:text-emerald-600 font-medium px-3 py-4">
                <div className="flex items-center justify-center">
                  <div className="h-5 w-full bg-emerald-200 rounded-full"></div>
                </div>
              </div>
              <div className="block flex-1 text-center text-sm text-slate-600 hover:text-slate-800 font-medium px-3 py-4 group">
                <div className="flex items-center justify-center">
                  <div className="h-5 w-full bg-slate-200 rounded-full"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        {/* Empty Trash Modal */}
        <DeleteModal
          id={props.id}
          deleteFunction={removeBannerHandler}
          open={dangerModalOpen}
          setOpen={setDangerModalOpen}
          isLoading={isLoading}
        />

        {/* Rest */}
        <div className="col-span-full sm:col-span-6 xl:col-span-3 bg-white shadow-lg rounded-lg border border-slate-200">
          <div className="flex flex-col h-full">
            {/* Card top */}
            <div className="grow">
              <div className="relative flex justify-between items-start">
                {/* Image + name */}
                <header className="w-full">
                  <div className="flex flex-col">
                    <div className="relative w-full h-32">
                      <img
                        className="h-full w-full object-cover overflow-hidden rounded-t-lg"
                        src={props.coverImg}
                        alt={props.title}
                      />
                    </div>

                    {props.type === "user" && (
                      <div className="text-sm font-bold bg-slate-800 text-slate-100 text-center px-2.5 py-1">
                        User Banner
                      </div>
                    )}
                    {props.type === "link" && (
                      <div className="text-sm font-bold bg-emerald-800 text-slate-100 text-center px-2.5 py-1">
                        Static Link
                      </div>
                    )}
                    {props.type === "library" && (
                      <div className="text-sm font-bold bg-amber-800 text-slate-100 text-center px-2.5 py-1">
                        Library
                      </div>
                    )}
                    {props.type === "giftcode" && (
                      <div className="text-sm font-bold bg-indigo-800 text-slate-100 text-center px-2.5 py-1">
                        Giftcode
                      </div>
                    )}
                    <div className="p-5">
                      <div className="flex flex-col gap-y-2">
                        <div className="text-sm text-gray-500 inline-flex items-center gap-x-2">
                          {props.title}
                        </div>
                      </div>
                    </div>
                  </div>
                </header>
              </div>
            </div>
            {/* Card footer */}
            <div className="border-t border-slate-200">
              <div className="flex divide-x divide-slate-200r">
                <button
                  className="block flex-1 text-center text-sm text-rose-600 hover:text-rose-800 font-medium px-3 py-4 group"
                  onClick={deleteItem}
                >
                  <div className="flex items-center justify-center">
                    <svg
                      className="w-4 h-4 fill-current text-rose-400 group-hover:text-rose-500 shrink-0 mr-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.7.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM4.6 14H2v-2.6l6-6L10.6 8l-6 6zM12 6.6L9.4 4 11 2.4 13.6 5 12 6.6z" />
                    </svg>
                    <span>Delete</span>
                  </div>
                </button>
                <Link
                  className="block flex-1 text-center text-sm text-slate-600 hover:text-slate-800 font-medium px-3 py-4 group"
                  to={`${props.editUrl}/${props.id}/edit`}
                >
                  <div className="flex items-center justify-center">
                    <svg
                      className="w-4 h-4 fill-current text-slate-400 group-hover:text-slate-500 shrink-0 mr-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.7.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM4.6 14H2v-2.6l6-6L10.6 8l-6 6zM12 6.6L9.4 4 11 2.4 13.6 5 12 6.6z" />
                    </svg>
                    <span>Edit</span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default BannerCard;
