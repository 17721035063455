import React from "react";
import AddLanguagesBtn from "./AddLanguagesBtn";

const LanguageSelectBar = (props) => {
  const renderLocale = (locale) => {
    const item = props.languages.find((item) => item.value === locale);
    return item?.title;
  };

  const addLanguage = (locale) => {
    const lastLang =
      props.formik.values.languages[props.formik.values.languages.length - 1];

    const newLang =
      lastLang === undefined
        ? {
            title: "",
            description: "",
            coverImg: "",
            medias: [],
            locale: locale,
          }
        : {
            title: lastLang.title,
            description: lastLang.description,
            coverImg: lastLang.coverImg,
            medias: lastLang.medias,
            locale: locale,
          };

    // Add Language to array
    props.formik.setFieldValue("languages", [
      ...props.formik.values.languages,
      newLang,
    ]);

    // Select new locale
    props.setSelectedLocale(locale);
  };

  const deleteLanguages = (idx) => {
    props.formik.setFieldValue(
      "languages",
      props.formik.values.languages.filter((item, index) => index !== idx)
    );
  };

  const moveToTop = (idx) => {
    props.formik.setFieldValue("languages", [
      props.formik.values.languages[idx],
      ...props.formik.values.languages.filter((item, index) => index !== idx),
    ]);
  };

  const hasError = (idx) => {
    if (props.formik.errors.languages) {
      console.log(props.formik.errors.languages[idx]);
    }
    return (
      props.formik.errors.languages &&
      props.formik.errors.languages[idx] !== undefined
    );
  };

  const getOtherLanguages = (languages, formik) => {
    const avalibleLanguages = languages.filter(
      (lang) =>
        formik.values.languages
          .map((item) => item.locale)
          .indexOf(lang.value) === -1
    );

    return avalibleLanguages;
  };

  const renderLanguageItem = (item, idx) => {
    return (
      <li key={`langItem-${idx}`} className="relative">
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            props.setSelectedLocale(item?.locale);
          }}
          className={`w-full h-full text-left py-3 px-4 rounded ${
            hasError(idx)
              ? "border-rose-500 border-2"
              : props.selectedLocale === item?.locale
              ? "border-emerald-500 border-2"
              : "bg-white"
          } border border-slate-200 hover:border-emerald-600 shadow-sm duration-150 ease-in-out`}
        >
          <div className="flex flex-wrap items-center justify-between mb-0.5">
            <span className="font-semibold text-slate-800">
              {renderLocale(item?.locale)}
            </span>
          </div>
          <div className="text-sm text-emerald-500">{item?.title}</div>
        </button>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            deleteLanguages(idx);
            props.setSelectedLocale(
              props.formik.values.languages[Math.max(0, idx - 1)].locale
            );
          }}
          className="group flex justify-center items-center absolute h-5 w-5 top-0 right-0 -mt-1 -mr-1 z-20"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="flex absolute h-4 w-4 z-10 text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
          <span className="relative inline-flex rounded-full h-5 w-5 bg-rose-500 group-hover:bg-rose-600"></span>
        </button>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            moveToTop(idx);
          }}
          className="group flex justify-center items-center absolute h-5 w-5 top-7 right-0 -mt-1 -mr-1 z-20"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="flex absolute h-4 w-4 z-10 text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"
            />
          </svg>

          <span className="relative inline-flex rounded-full h-5 w-5 bg-amber-500 group-hover:bg-amber-600"></span>
        </button>
      </li>
    );
  };

  return (
    <div>
      <div className="bg-white p-5 shadow-lg rounded-md border border-slate-200 lg:w-72 xl:w-96">
        <div className="text-sm text-slate-800 font-semibold mb-3">
          Select Languages
        </div>
        <ul className="space-y-2 sm:space-x-2 sm:flex lg:space-x-0 lg:flex-col mb-4">
          {props.formik.values.languages.map((lang, idx) =>
            renderLanguageItem(lang, idx)
          )}
        </ul>
        <div className="mb-4">
          <AddLanguagesBtn
            languages={getOtherLanguages(props.languages, props.formik)}
            addLanguagesHandler={addLanguage}
          />
        </div>
      </div>
    </div>
  );
};

export default LanguageSelectBar;
