import { useState, useEffect } from "react";
import {
  Link,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import PasswordStrengthBar from "react-password-strength-bar";

import ModalBlank from "../../components/ModalBlank";
import useAuth from "../../hooks/useAuth"; // Auth
import ProcessingBtn from "../../components/btn/ProcessingBtn";
import { ReactComponent as Logo } from "../../images/logo/Logo.svg";
import AuthImage from "../../images/auth-image.jpg";
// import AuthVideo from "../../images/auth-video.mp4";
import AuthDecoration from "../../images/auth-decoration.png";
import axios from "../../api/axios";
import endpoints from "../../api/endpoints";

import Success from "../../components/alert/Success";
import Warning from "../../components/alert/Warning";
import Danger from "../../components/alert/Danger";

function Signup() {
  // Warning
  const [warning, setWarning] = useState();

  // Success Modal
  const [isSuccess, setIsSuccess] = useState(false);

  // Auth
  const { setAuth, token, setToken } = useAuth();

  // Promocode
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const loginHandlerTest = async (values, { setErrors, resetForm }) => {
    setLoading(true);
    console.log(formik.values);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const toLoginPage = () => {
    navigate("/signin", { replace: true });
  };

  const loginHandler = async (values, { setErrors, resetForm }) => {
    setLoading(true);
    try {
      const response = await axios.post(
        endpoints.REGISTER_URL,
        JSON.stringify(formik.values),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      // Disable jwt
      // console.log(JSON.stringify(response?.data));
      setAuth(response?.data);

      setIsSuccess(true);
    } catch (err) {
      if (!err?.response) {
        console.error("No Server res");
        setWarning(<Danger title="No Server Response" />);
      } else {
        let error = err?.response?.data?.errors?.msg;
        setWarning(<Warning title={error} />);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    localStorage.setItem("token", token);
  }, [token]);

  // Form

  const [isLoading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      username: "",
      password: "",
      confirmPassword: "",
      promocode: searchParams.get("pc") || "",
      tnc: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("This is not a valid email")
        .required("Email is required"),
      username: Yup.string().required("Username is required"),
      firstName: Yup.string().trim().required("First name is required"),
      lastName: Yup.string().trim(),
      password: Yup.string()
        .trim()
        .min(8, "Password must be at least 8 characters")
        .required("Password is required"),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
      promocode: Yup.string().trim(),
      tnc: Yup.boolean().oneOf([true], "You must agree to the terms"),
    }),
    onSubmit: loginHandler,
  });

  return (
    <main className="bg-white">
      <ModalBlank
        id="success-modal"
        modalOpen={isSuccess}
        setModalOpen={setIsSuccess}
      >
        <div className="p-5 flex space-x-4">
          <div className="flex flex-col w-full justify-center gap-y-2">
            <div className="flex gap-x-4">
              {/* Content */}
              <div className="mb-2">
                {/* Modal header */}
                <div className="">
                  <div className="text-lg font-semibold text-slate-800">
                    Success
                  </div>
                </div>
                {/* Modal content */}
                <div className="text-sm">
                  <div className="space-y-2">
                    <p>
                      Registered Successfully! Please also verify your email
                      address!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Modal footer */}
            <div className="flex justify-center w-full space-x-2">
              <button
                className="btn bg-sky-500 hover:bg-sky-600 text-white w-full"
                onClick={toLoginPage}
              >
                Login Now
              </button>
            </div>
          </div>
        </div>
      </ModalBlank>
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-full">
          <div className="min-h-screen h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <Logo className="h-8 w-8" />
                </Link>
              </div>
            </div>

            <div className="max-w-sm mx-auto sm:px-4 py-8 w-96">
              <h1 className="text-3xl text-slate-800 font-bold mb-6">
                Create your Account ✨
              </h1>
              {/* Warning */}
              {warning}

              {/* Form */}
              <form onSubmit={formik.handleSubmit}>
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="email"
                    >
                      Email Address <span className="text-rose-500">*</span>
                    </label>
                    <input
                      name="email"
                      className="form-input w-full text-lg sm:text-base"
                      type="string"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <p className="text-red-600 text-sm">
                      {formik.errors.email && formik.touched.email
                        ? `Error: ${formik.errors.email}`
                        : null}
                    </p>
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="name"
                    >
                      Frist Name <span className="text-rose-500">*</span>
                    </label>
                    <input
                      name="firstName"
                      className="form-input w-full text-lg sm:text-base"
                      type="text"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <p className="text-red-600 text-sm">
                      {formik.errors.firstName && formik.touched.firstName
                        ? `Error: ${formik.errors.firstName}`
                        : null}
                    </p>
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="name"
                    >
                      Last Name
                    </label>
                    <input
                      name="lastName"
                      className="form-input w-full text-lg sm:text-base"
                      type="text"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <p className="text-red-600 text-sm">
                      {formik.errors.lastName && formik.touched.lastName
                        ? `Error: ${formik.errors.lastName}`
                        : null}
                    </p>
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="name"
                    >
                      Username <span className="text-rose-500">*</span>
                    </label>
                    <input
                      name="username"
                      className="form-input w-full text-lg sm:text-base"
                      type="text"
                      value={formik.values.username}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <p className="text-red-600 text-sm">
                      {formik.errors.username && formik.touched.username
                        ? `Error: ${formik.errors.username}`
                        : null}
                    </p>
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Password <span className="text-rose-500">*</span>
                    </label>
                    <input
                      name="password"
                      className="form-input w-full text-lg sm:text-base"
                      type="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <div className="">
                      <PasswordStrengthBar
                        minLength={8}
                        scoreWordClassName="text-sm font-medium"
                        password={formik.values.password}
                      />
                    </div>
                    <p className="text-red-600 text-sm">
                      {formik.errors.password && formik.touched.password
                        ? `Error: ${formik.errors.password}`
                        : null}
                    </p>
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="confirmPassword"
                    >
                      Confirm Password <span className="text-rose-500">*</span>
                    </label>
                    <input
                      name="confirmPassword"
                      className="form-input w-full text-lg sm:text-base"
                      type="password"
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <p className="text-red-600 text-sm">
                      {formik.errors.confirmPassword &&
                      formik.touched.confirmPassword
                        ? `Error: ${formik.errors.confirmPassword}`
                        : null}
                    </p>
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="confirmPassword"
                    >
                      Promo code
                    </label>
                    <input
                      name="promocode"
                      className="form-input w-full text-lg sm:text-base"
                      type="text"
                      value={formik.values.promocode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <p className="text-red-600 text-sm">
                      {formik.errors.promocode && formik.touched.promocode
                        ? `Error: ${formik.errors.promocode}`
                        : null}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between mt-6">
                  <div className="mr-1">
                    <label className="flex items-center">
                      <input
                        name="tnc"
                        type="checkbox"
                        className="form-checkbox"
                        value={formik.tnc}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <span className="text-sm ml-2">
                        i agree to the terms and conditions
                      </span>
                    </label>
                    <p className="text-red-600 text-sm">
                      {formik.errors.tnc && formik.touched.tnc
                        ? `Error: ${formik.errors.tnc}`
                        : null}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between mt-6">
                  {!isLoading && (
                    <button
                      type="submit"
                      className="inline-block btn bg-emerald-500 hover:bg-emerald-600 text-white whitespace-nowrap w-full"
                      to="/"
                    >
                      Sign Up
                    </button>
                  )}
                  {isLoading && <ProcessingBtn title="Sign Up" />}
                </div>
              </form>
              {/* Footer */}
              <div className="pt-5 mt-6 border-t border-slate-200">
                <div className="text-sm flex justify-center gap-x-2">
                  <p>Have an account?</p>
                  <Link
                    className="font-medium text-emerald-500 hover:text-emerald-600"
                    to="/signin"
                  >
                    Sign In
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Signup;
