let API_ENDPOINT;

switch (process.env.REACT_APP_MODE) {
  case "development":
    API_ENDPOINT = "https://api.lab.storiusapp.com";
    break;
  case "production":
    API_ENDPOINT = "https://api.storiusapp.com";
    break;
  default:
    API_ENDPOINT = "http://localhost:8000";
}

export default API_ENDPOINT;
