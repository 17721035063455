import SearchUserBar from "./SearchUserBar";
import { toast } from "react-toastify";
import { List, arrayMove } from "react-movable";

const UserSelection = ({ formik, users, setUsers }) => {
  const editUsers = (payload) => {
    setUsers(payload);

    if (formik) {
      formik.setFieldValue(
        "users",
        payload.map((user) => user.id)
      );
    }
  };

  const addUser = (user) => {
    // Check if user already exists
    if (users.find((s) => s.id === user.id)) {
      toast.info("User already exists", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      editUsers([...users, user]);
    }
  };

  const removeUser = (id) => {
    editUsers(users.filter((user) => user.id !== id));
  };

  const renderUsersList = () => {
    return (
      <List
        values={users}
        onChange={({ oldIndex, newIndex }) =>
          editUsers(arrayMove(users, oldIndex, newIndex))
        }
        renderList={({ children, props }) => (
          <ul
            role="list"
            className="divide-y divide-gray-200 max-w-screen-xs"
            {...props}
          >
            {children}
          </ul>
        )}
        renderItem={({ value, props }) => (
          <li
            className="py-4 flex items-center justify-between cursor-move"
            {...props}
          >
            <div className="flex items-center">
              <div className="ml-3">
                <div className="flex items-center gap-x-1">
                  <p className="text-sm font-medium text-gray-900">
                    {value.username}
                  </p>
                </div>

                <p className="text-sm text-gray-500">{value?.id}</p>
              </div>
            </div>

            <div className="flex items-center gap-x-2">
              <a
                target="_blank"
                href={`/users/${value.id}/edit`}
                className="text-sky-500 underline"
              >
                Edit
              </a>
              <button
                onClick={() => {
                  removeUser(value.id);
                }}
                type="button"
                className="text-rose-500 underline"
              >
                Remove
              </button>
            </div>
          </li>
        )}
      />
    );
  };

  return (
    <div>
      {<SearchUserBar setUser={addUser} locale={navigator.language} />}
      <div className="max-h-48 overflow-scroll">{renderUsersList()}</div>
      <p className="text-red-600 text-sm">
        {formik && formik.errors.users && formik.touched.users
          ? `Error: ${formik.errors.users}`
          : null}
      </p>
    </div>
  );
};

export default UserSelection;
