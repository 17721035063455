import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";
import Danger from "../../components/alert/Danger";
import ProcessingBtn from "../../components/btn/ProcessingBtn";

export default function CreateGiftcodeModal({
  open,
  setOpen,
  id,
  isSingleCode,
  updateRedemptionCount,
}) {
  const [warning, setWarning] = useState();
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const [isLoading, setLoading] = useState(false);

  const submitHandler = async (values) => {
    setLoading(true);
    try {
      if (values.hasExpiry === false) {
        delete values.expiredAt;
      }

      await axiosPrivate.post(
        `${endpoints.EXCLUSIVE_PACKAGES_URL}/${id}/code`,
        values,
        {
          signal: controller.signal,
        }
      );

      await updateRedemptionCount();

      // Close the modal
      setOpen(!open);
    } catch (err) {
      if (!err?.response) {
        console.error("No Server res");
      } else {
        // API Error
        let error = err?.response?.data?.errors?.msg;

        if (error) {
          setWarning(<Danger title={error} />);
        } else {
          setWarning(<Danger title="Error Occured" />);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      number: isSingleCode ? 1 : 30,
      expiredAt: null,
      hasExpiry: false,
    },
    validationSchema: Yup.object({
      number: Yup.number().required("This field is required"),
      hasExpiry: Yup.boolean().required("This field is required"),
    }),
    onSubmit: submitHandler,
  });

  console.log(formik.errors);
  useEffect(() => {
    if (open) {
      formik.setFieldValue("number", isSingleCode ? 1 : 30)
    }
  }, [open])

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => {
            setOpen(false);
          }}
        >
          {/* The backdrop, rendered as a fixed sibling to the panel container */}
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Bulk Create Redemption Code
                  </Dialog.Title>

                  {/*  */}

                  {/* Warning */}
                  <div className="py-2 text-rose-500 font-bold">
                    Please note the new generated code will override the current
                    codes
                  </div>

                  {/* Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="space-y-4">
                      <div>
                        <label
                          className="block text-sm font-medium mb-1"
                          htmlFor="number"
                        >
                          Number of Code
                        </label>
                        <input
                          name="number"
                          className="form-input w-full text-lg sm:text-base"
                          type="string"
                          readOnly={isSingleCode}
                          value={formik.values.number}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <p className="text-red-600 text-sm">
                          {formik.errors.number && formik.touched.number
                            ? `Error: ${formik.errors.number}`
                            : null}
                        </p>
                      </div>
                      <div>
                        <div className="flex items-center space-x-4">
                          <label
                            className="block text-sm font-medium mb-1"
                            htmlFor="hasExpiry"
                          >
                            Has Expiry
                          </label>
                          <div className="form-switch">
                            <input
                              name="hasExpiry"
                              type="checkbox"
                              id="hasExpiry"
                              className="sr-only"
                              checked={formik.values.hasExpiry}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <label className="bg-slate-400" htmlFor="hasExpiry">
                              <span
                                className="bg-white shadow-sm"
                                aria-hidden="true"
                              ></span>
                              <span className="sr-only">Will Expired</span>
                            </label>
                          </div>
                        </div>
                        <p className="text-red-600 text-sm">
                          {formik.errors.hasExpiry && formik.touched.hasExpiry
                            ? `Error: ${formik.errors.hasExpiry}`
                            : null}
                        </p>
                      </div>
                      {formik.values.hasExpiry && (
                        <div>
                          <label
                            className="block text-sm font-medium mb-1"
                            htmlFor="expiredAt"
                          >
                            Expiry Day
                          </label>
                          <input
                            name="expiredAt"
                            className="form-input w-full text-lg sm:text-base"
                            type="date"
                            value={formik.values.expiredAt}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <p className="text-red-600 text-sm">
                            {formik.errors.expiredAt && formik.touched.expiredAt
                              ? `Error: ${formik.errors.expiredAt}`
                              : null}
                          </p>
                        </div>
                      )}
                    </div>

                    <div className="flex items-center justify-between mt-6 space-x-4">
                      <button
                        type="button"
                        onClick={() => setOpen(!open)}
                        className="btn bg-black text-white w-full"
                      >
                        Close
                      </button>
                      {!isLoading && (
                        <button
                          type="submit"
                          className="btn bg-emerald-500 hover:bg-emerald-600 text-white w-full"
                        >
                          Submit
                        </button>
                      )}
                      {isLoading && <ProcessingBtn title="Create Giftcode" />}
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
