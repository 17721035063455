import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDebouncedValue, usePagination } from "@mantine/hooks";

import useAxiosPrivate from "../hooks/useAxiosPrivate";
import endpoints from "../api/endpoints";
import useAuth from "../hooks/useAuth";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import SearchForm from "../partials/actions/SearchForm";
import WritingCardTrash from "../partials/writing/WritingCardTrash";
import PaginationNumeric from "../components/PaginationNumeric";
import DeleteWritingModal from "../partials/writing/DeleteWritingModal";
import Banner from "../components/Banner";
import Loader from "../partials/Loader";

function Trash() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [dangerModalOpen, setDangerModalOpen] = useState(false);
  const [bannerWarningOpen, setBannerWarningOpen] = useState(true);

  // Get User Profile
  const { auth } = useAuth();
  const user = auth.user;
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  // Get Writings
  const [writings, setWritings] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [debouncedSearchString] = useDebouncedValue(searchString, 200, {
    leading: true,
  });
  const [page, onChange] = useState(1);
  const [totalPage, setTotalPage] = useState(10);
  const pagination = usePagination({ total: totalPage, page, onChange });

  // Render Writings
  const renderWritings = async () => {
    try {
      // Check if debouncedSearchString
      let params = {};
      if (debouncedSearchString) {
        params = { filter: debouncedSearchString, fields: "title,content" };
      }

      params = {
        ...params,
        page: page,
        limit: 10,
        type: "trash",
      };

      const res = await axiosPrivate.get(endpoints.WRITINGS_URL, {
        signal: controller.signal,
        params: params,
      });

      // Check update page number if total page is less than current page
      if (page > res?.data?.totalPages) {
        onChange(res?.data?.totalPages);
      }

      setWritings(res?.data?.docs);
      setTotalPage(res?.data?.totalPages);
    } catch (error) {
      console.error(error);
      // navigate("/signin", { state: { from: location }, replace: true });
    }
  };

  // Move writing to trash
  const deleteWriting = async (id) => {
    try {
      await axiosPrivate.delete(endpoints.WRITINGS_URL + "/" + id, {
        signal: controller.signal,
      });
      console.log("Delete writing success");
      renderWritings();
    } catch (error) {
      console.error(error);
    }
  };

  // Put back writing
  const putbackWriting = async (id) => {
    try {
      await axiosPrivate.patch(
        endpoints.WRITINGS_URL + "/" + id,
        { isDeleted: false },
        {
          signal: controller.signal,
        }
      );
      console.log("Put back writing success");

      renderWritings();
    } catch (error) {
      console.error(error);
    }
  };

  // Empty Trash
  const emptyTrash = async () => {
    try {
      await axiosPrivate.delete(endpoints.WRITINGS_URL, {
        signal: controller.signal,
      });
      console.log("Empty trash success");
      renderWritings();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    renderWritings();
  }, [debouncedSearchString, page]);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        user={user}
      />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
        />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Empty Trash Banner */}
            <div className="relative rounded-sm mb-8">
              <Banner open={bannerWarningOpen} setOpen={setBannerWarningOpen}>
                Documents in the trash are permanently deleted after 30 days.{" "}
              </Banner>
            </div>

            {/* Empty Trash Modal */}
            <DeleteWritingModal
              deleteFunction={emptyTrash}
              dangerModalOpen={dangerModalOpen}
              setDangerModalOpen={setDangerModalOpen}
            />

            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                  Trash Can 🗑
                </h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Search form */}
                <SearchForm value={searchString} setValue={setSearchString} />
                {/* Create campaign button */}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setDangerModalOpen(true);
                  }}
                  className="btn bg-rose-500 hover:bg-rose-600 text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 fill-current opacity-50 shrink-0"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
                    />
                  </svg>

                  <span className="hidden xs:block ml-2">Empty Trash</span>
                </button>
              </div>
            </div>

            {/* Cards */}
            <div className="grid grid-cols-12 gap-6">
              {writings &&
                writings.map((item) => {
                  return (
                    <WritingCardTrash
                      key={item._id}
                      id={item._id}
                      title={item.title ? item.title : "Untitled"}
                      link={item.link ? item.link : "https://google.com"}
                      content={item.content ? item.content : "No content"}
                      createdAt={item.createdAt}
                      updatedAt={item.updatedAt}
                      deleteFunction={deleteWriting}
                      putbackFunction={putbackWriting}
                    />
                  );
                })}
            </div>

            {/* Pagination */}
            <div className="mt-8">
              <PaginationNumeric
                pagination={pagination}
                page={page}
                onChange={onChange}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Trash;
