import {
  useSearchParams,
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

import { getLanguages } from "../../utils/listen/api.js";
import { renderLocales } from "../../utils/listen/helper.js";
import TourPlayer from "../../partials/listen/TourPlayer.js";
import Header from "../../partials/listen/Header.js";
import NotFound from "./NotFound";
import Loader from "../../partials/Loader";
import ExclusiveModal from "../../partials/listen/ExclusiveModal";

import useAxiosTryPrivate from "../../hooks/useAxiosTryPrivate";
import endpoints from "../../api/endpoints";

export default function TourPlaylist() {
  let [searchParams] = useSearchParams();
  const { id } = useParams();

  const [tour, setTour] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  // popup Exclusive tour
  const [dangerExclusiveModalOpen, setDangerExclusiveModalOpen] = useState(false);

  // Get User Profile
  const [user, setUser] = useState(null);

  const axiosPrivate = useAxiosTryPrivate();
  const controller = new AbortController();

  // Get User Profile
  const getUserProfile = async () => {
    try {
      const res = await axiosPrivate.get(endpoints.PROFILE_URL, {
        signal: controller.signal,
      });

      setUser(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getTour = async (id, locale) => {
    let URL = `${endpoints.LISTEN_TOUR_URL}/${id}`;

    if (locale !== undefined && locale !== null) {
      URL += `?locale=${locale}`;
    }

    const res = await axiosPrivate.get(URL, {
      signal: controller.signal,
    });

    return res?.data;
  };

  const init = async () => {
    setIsLoading(true);

    try {
      // Get User Profile
      await getUserProfile();

      // Get Locale (if any)
      const locale = searchParams.get("locale");

      // Check if storyId tourId langId inside the payload
      const [languages, tour] = await Promise.all([
        getLanguages(),
        getTour(id, locale),
      ]);

      // Apply Locales
      tour.stories.forEach((story) => {
        story.otherLangs = renderLocales(story.otherLangs, languages);
      });

      setTour(tour);
      if(tour.isExclusive) {
        setDangerExclusiveModalOpen(true);
      }

      setIsLoading(false);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, [searchParams, id]);

  if (!isLoading) {
    if (tour) {
      // Tour Player
      return (
        <>
          <ToastContainer />
          <ExclusiveModal
            id={tour.id}
            open={dangerExclusiveModalOpen}
            setOpen={setDangerExclusiveModalOpen}
          />
          <Header tour={tour} user={user} />
          <div className="pt-[72px] md:pt-24 flex justify-center">
            <TourPlayer tour={tour} />
          </div>
        </>
      );
    } else {
      return (
        <>
          <ToastContainer />
          <Header tour={tour} user={user} />
          <div className="pt-[72px] md:pt-24 flex justify-center">
            <NotFound />
          </div>
        </>
      );
    }
  } else {
    return <Loader />;
  }
}
