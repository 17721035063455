import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../partials/Loader";
import LanguageSelectBar from "../../partials/stories/LanguageSelectBar";

/**
 * @typedef { {
 * 		type: string,
 * 		languages: {
 * 			title: string,
 * 			body: string,
 * 			locale: string,
 *    }[]
 *  } } UserAlert
 */

/**
 * @param { {
 * 	languages: string[],
 * 	value: UserAlert,
 * 	isFetching: boolean,
 *  onSubmit: (value: UserAlert, helpers: import('formik').FormikHelpers) => void,
 * 	onClear: () => void,
 * }? } props
 */
export default function AlertForm(props) {
  // Get User Profile
  const [selectedLocale, setSelectedLocale] = useState("en");

  const AlertTypes = Object.freeze([
    Object.freeze({ name: "general", uiLabel: "General" }),
    Object.freeze({ name: "new_version", uiLabel: "New Version Available" }),
    Object.freeze({ name: "update_required", uiLabel: "Update Required" }),
  ]);

  const formik = useFormik({
    initialValues: {
      type: "general",
      ...props.value,
      languages: props.value.languages ?? [
        {
          locale: "en",
          title: props.value.title ?? "",
          body: props.value.body ?? "",
        },
      ],
    },
    validationSchema: Yup.object({
      type: Yup.string().oneOf(
        AlertTypes.map((type) => type.name),
        "Not a valid alert type"
      ),
      languages: Yup.array().of(
        Yup.object().shape({
          title: Yup.string().required("This field is required"),
          body: Yup.string().required("This field is required"),
        })
      ),
    }),
    onSubmit: props.onSubmit,
  });

  useEffect(() => {
    formik.setValues({
      type: "general",
      ...props.value,
      languages: props.value.languages ?? [
        {
          locale: "en",
          title: props.value.title ?? "",
          body: props.value.body ?? "",
        },
      ],
    });
  }, [props.value]);

  const renderLanguageForm = (item, index) => {
    if (item.locale !== selectedLocale) return;
    return (
      <div key={item.locale}>
        <hr />

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:py-5">
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Title <span className="text-rose-500">*</span>
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <textarea
              name={`languages[${index}].title`}
              rows={3}
              value={formik.values.languages[index].title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="max-w-lg shadow-sm block w-full focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border border-gray-300 rounded-md"
            />
            <p className="text-red-600 text-sm">
              {formik.errors.languages &&
              formik.touched.languages &&
              formik.errors.languages[index]?.title
                ? `Error: ${formik.errors.languages[index]?.title}`
                : null}
            </p>
          </div>
        </div>

        <hr />

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:py-5">
          <label
            htmlFor="body"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Body <span className="text-rose-500">*</span>
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <textarea
              name={`languages[${index}].body`}
              rows={3}
              value={formik.values.languages[index].body}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="max-w-lg shadow-sm block w-full focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border border-gray-300 rounded-md"
            />
            <p className="text-red-600 text-sm">
              {formik.errors.languages &&
              formik.touched.languages &&
              formik.errors.languages[index]?.body
                ? `Error: ${formik.errors.languages[index]?.body}`
                : null}
            </p>
          </div>
        </div>

        <hr />
      </div>
    );
  };

  if (props.isFetching) {
    return <Loader useFullScreen={false} />;
  } else {
    return (
      <form onSubmit={formik.handleSubmit}>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:py-5 sm:mx-4">
          <label
            htmlFor="type"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Type <span className="text-rose-500">*</span>
          </label>
          <div className="mt-1 smLmt-0 sm:col-span-2">
            <select
              name="type"
              id="type"
              value={formik.values.type}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="max-w-lg shadow-sm block focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border border-gray-300 rounded-md"
            >
              {AlertTypes.map((type) => (
                <option key={type.name} value={type.name}>
                  {type.uiLabel}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="max-w-5xl mx-auto flex flex-col lg:flex-row lg:space-x-8 xl:space-x-16 space-y-8 lg:space-y-0">
          {/* Sidebar */}
          <LanguageSelectBar
            languages={props.languages}
            selectedLocale={selectedLocale}
            setSelectedLocale={setSelectedLocale}
            formik={formik}
          />

          {/* Language */}
          <div className="w-full">
            {/* Page content */}
            {formik.values.languages.map((item, idx) => {
              return renderLanguageForm(item, idx);
            })}
          </div>
        </div>

        <div className="py-5">
          <div className="flex justify-end items-center space-x-4">
            {props.isFetching ? (
              <span className="btn bg-rose-300 text-white">Clearing...</span>
            ) : (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  props.onClear();
                }}
                type="button"
                className="group btn border-slate-200 group-hover:border-slate-300 bg-white text-rose-500 hover:bg-rose-500 hover:text-white"
              >
                Clear Alert
              </button>
            )}

            <div>
              {props.isFetching ? (
                <span className="btn bg-emerald-300 text-white">
                  Posting...
                </span>
              ) : (
                <button
                  type="submit"
                  className="btn bg-emerald-500 hover:bg-emerald-600 text-white"
                >
                  Post Alert
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    );
  }
}
