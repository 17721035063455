import moment from "moment";
import FRONTEND_ENDPOINT from "../../utils/frontend";

function RequestTableItem(props) {
  console.log(props);
  return (
    <tr key={props.request.id}>
      {/* <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        <div className="flex items-center">
          <label className="inline-flex">
            <span className="sr-only">Select</span>
            <input
              id={props.user.id}
              className="form-checkbox"
              type="checkbox"
              onChange={props.user.handleClick}
              checked={props.user.isChecked}
            />
          </label>
        </div>
      </td> */}
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="flex items-center">
          {props.request.asset &&
            moment(props.request.asset.createdAt).fromNow()}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="flex items-center">
          <a
            href={
              props.request.asset
                ? `${FRONTEND_ENDPOINT}/listen/${props.request.type.toLowerCase()}/${
                    props.request.asset.id
                  }`
                : // eslint-disable-next-line no-script-url
                  "javascript:void(0)"
            }
            className={
              props.request.asset
                ? "bg-sky-50 hover:bg-sky-100 text-sky-600 block px-4 py-1 text-sm rounded-md mr-2"
                : "bg-sky-50 hover:bg-sky-100 text-grey-600 block px-4 py-1 text-sm rounded-md mr-2"
            }
            target="_blank"
            rel="noreferrer"
          >
            Preview
          </a>
          {props.request.asset?.title ?? "[NOT FOUND]"}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">{props.request.type}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left font-medium text-emerald-500">
          {props.request.status === "approved" && (
            <div className="text-xs inline-flex font-extrabold bg-emerald-100 text-emerald-600 rounded-full text-center px-2.5 py-1">
              Approved
            </div>
          )}
          {props.request.status === "rejected" && (
            <div className="text-xs inline-flex font-extrabold bg-rose-100 text-rose-600 rounded-full text-center px-2.5 py-1">
              Rejected
            </div>
          )}
          {props.request.status === "pending" && (
            <div className="text-xs inline-flex font-medium bg-slate-100 text-slate-500 rounded-full text-center px-2.5 py-1">
              Pending
            </div>
          )}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        {props.request.status === "pending" && (
          <div className="flex space-x-2 items-center">
            <button
              type="button"
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-emerald-600 hover:bg-emerald-700"
              onClick={() => {
                props.confirmBtn(props.request.id, "approved");
              }}
            >
              Approve
            </button>

            <button
              type="button"
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-rose-600 hover:bg-rose-700"
              onClick={() => {
                props.confirmBtn(props.request.id, "rejected");
              }}
            >
              Reject
            </button>
          </div>
        )}
      </td>
    </tr>
  );
}

export default RequestTableItem;
