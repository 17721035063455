import { ChevronDownIcon } from "@heroicons/react/solid";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const LangSelect = ({ story }) => {
  const [toggle, setToggle] = useState(false);

  const renderDropdownItem = (langs) => {
    let dom = [];
    for (const lang of langs) {
      // Selected story

      // other story
      dom.push(
        <li key={lang.value} className="border-b-2 border-storius-blue">
          <Link
            to={`/listen/story/${story.id}?locale=${lang.value}`}
            className="block py-2 px-4"
            onClick={() => {
              setToggle(false);
            }}
          >
            {lang.title}
          </Link>
        </li>
      );
    }
    return dom;
  };

  const renderDropdownMenu = (langs) => {
    if (toggle) {
      return (
        <div className="absolute w-72 mt-11 bg-white shadow max-h-96 overflow-scroll">
          <ul className="text-sm border-x-2 border-storius-blue text-storius-blue">
            {renderDropdownItem(langs)}
          </ul>
        </div>
      );
    }
  };

  if (story) {
    return (
      <>
        {/* Mobile */}
        <div className="visible sm:hidden">
          <button
            onClick={() => {
              setToggle(!toggle);
            }}
            className="text-storius-blue text-sm px-4 py-2 inline-flex items-center"
            type="button"
          >
            {story.otherLangs.length > 1 && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802"
                />
              </svg>
            )}
          </button>
        </div>
        {/* Desktop */}
        <div className="hidden sm:block">
          <button
            onClick={() => {
              setToggle(!toggle);
            }}
            className="text-storius-blue border-2 border-storius-blue text-sm px-4 py-2 w-72 inline-flex justify-between items-center"
            type="button"
          >
            {story.locale?.title}

            {story.otherLangs.length > 1 && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            )}
          </button>
        </div>

        {story.otherLangs.length > 1 && renderDropdownMenu(story.otherLangs)}
      </>
    );
  }
};

export default LangSelect;
