import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { PlayIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";

export default function StoryLanguagePlayBtn({ tour, story }) {
  if (story.otherLangs.length > 1) {
    return (
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="relative inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 transition ease-in-out hover:scale-110 duration-150">
            <PlayIcon
              className="-ml-1 mr-2 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <span>Play</span>
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 z-20 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {story.otherLangs.map((locale) => (
                <Menu.Item key={locale._id}>
                  <Link
                    to={`/listen/tour/${tour.id}/${story.id}?locale=${locale.value}`}
                    className="text-gray-700 block px-4 py-2 text-sm"
                  >
                    {locale.title}
                  </Link>
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  } else {
    return (
      <div className="relative inline-block text-left">
        <Link
          to={`/listen/tour/${tour.id}/${story.id}?locale=${story.otherLangs[0].value}`}
          className="relative inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 transition ease-in-out hover:scale-110 duration-150"
        >
          <PlayIcon
            className="-ml-1 mr-2 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <span>Play</span>
        </Link>
      </div>
    );
  }
}
