const endpoints = {
  // Auth
  LOGIN_URL: "/auth/login",
  REGISTER_URL: "/auth/register",
  REFRESH_URL: "/auth/refresh",
  VERIFY_URL: "/auth/verify",
  FORGOTPW_URL: "/auth/forgot",
  RESETPW_URL: "/auth/reset",
  VERIFY_RESET_TOKEN_URL: "/auth/reset/verify",
  ERROR_404_URL: "/404",

  // Profile
  PROFILE_URL: "/profile",
  CHANGEPW_URL: "/profile/changePassword",
  SENDEMAILVERIFICATION_URL: "/profile/emailVerification",
  MY_STORIES_ALL: "/profile/stories",
  MY_TOURS_ALL: "/profile/tours",
  CHANGE_ADMIN_SETTING: "/profile/setting",

  // Explore
  EXPLORE_URL: "/explore",
  EXPLORE_BANNERS: "/explore/banners",
  EXPLORE_TOURS: "/explore/tours",
  EXPLORE_STORIES: "/explore/stories",
  EXPLORE_THEMES: "/explore/themes",

  // Admin
  STORIES_URL: "/stories",
  TOURS_URL: "/tours",
  PROXY_VIDEO_URL: "/stories/proxy/bilibili",

  // Upload
  UPLOAD_AVATAR: "/upload/avatar",
  UPLOAD_IMAGE: "/upload/image",
  UPLOAD_ADUIO: "/upload/audio",

  // Giftcodes
  COUPONS_URL: "/coupons",

  // Plan
  PLAN_URL: "/plans",

  // Region
  REGION_URL: "/countries",

  // Languages
  LANGS_URL: "/languages",

  // Feedbacks
  FEEDBACKS_URL: "/feedbacks",

  // Search
  SEARCH_USER_URL: "/search/users",
  SEARCH_STORY_URL: "/search/stories",
  SEARCH_TOUR_URL: "/search/tours",

  // Alert
  ALERT_URL: "/alerts",

  // Users
  USERS_URL: "/users",

  // Banners
  BANNERS_URL: "/banners",
  BANNERS_COUNT_URL: "/banners/count",

  // Exclusive Packages
  EXCLUSIVE_PACKAGES_URL: "/exclusivepackages",

  // Publishes
  PUBLISHES_URL: "/publishes",
  PUBLISHES_COUNT_URL: "/publishes/count",

  // SSO
  GOOGLE_SSO_AUTH: "auth/sso/google",
  APPLE_SSO_AUTH: "auth/sso/apple",

  // Listen
  LISTEN_STORY_URL: "/listen/story",
  LISTEN_TOUR_URL: "/listen/tour",

  // Legacy
  LEGACY_URL: "/legacy",

  // Notiication
  NOTIFICATION_URL: "/notification",

  //External API Bilibili Video 
  BILIBILI_URL: "https://api.bilibili.com/x/web-interface/view?bvid=",
};

export default endpoints;
