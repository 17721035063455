import { Link } from "react-router-dom";

export default function PackageTableItem({ exPackage }) {
  return (
    <tr>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">{exPackage.title}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">{exPackage.remark ?? "N/A"}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">
          {exPackage.redeemedCodeCount}/{exPackage.isSingleCode ? "unlimited" :exPackage.totalCodeCount}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        <Link
          to={`/exclusivepackages/${exPackage.id}/edit`}
          className="bg-gray-50 hover:bg-gray-100 text-gray-600 block px-4 py-1 text-sm rounded-md"
        >
          Edit
        </Link>
      </td>
    </tr>
  );
}
