import PackageTableItem from "./PackageTableItem";

export default function PackageTable({ isFetching, exPackages }) {
  return (
    <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative">
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-slate-200">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Title</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Remark</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Redeemed/Total</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <span className="sr-only">Menu</span>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="table-auto text-sm divide-y divide-slate-200">
              {exPackages.map((item, id) => {
                return <PackageTableItem key={id} exPackage={item} />;
              })}
              {!isFetching && exPackages.length === 0 && (
                <tr>
                  <td colSpan="6">
                    <div className="col-span-12 flex items-center justify-center py-5 text-xl">
                      {/* Show Empty */}
                      No Found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
