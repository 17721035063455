import React from 'react'

/**
 * Renders "Showing 1-50 of 200" text for pagination
 * @param {{
 *   offset: number,
 *   limit: number,
 *   count: number,
 * }} props
 */
function PaginationPageNumber(props) {
  return (
    <>
      Showing {props.offset + 1} -{' '}
      {Math.min(props.limit + props.offset, props.count)} of {props.count}
    </>
  )
}

export default PaginationPageNumber
