import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { useDebouncedValue } from "@mantine/hooks";
import { ToastContainer } from "react-toastify";
import { Combobox } from "@headlessui/react";
import useAuth from "../../hooks/useAuth";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";

import StoryCard from "../../partials/stories/StoryCard";
import PaginationClassic from "../../components/PaginationClassic";
import PaginationPageNumber from "../../components/PaginationPageNumber";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import SearchForm from "../../partials/actions/SearchForm";

function AllStories() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  // Get User Profile
  const { auth } = useAuth();
  const user = auth.user;

  // Get Stories
  const [stories, setStories] = useState(Array(20).fill(""));
  const [searchString, setSearchString] = useState("");
  const [debouncedSearchString] = useDebouncedValue(searchString, 200, {
    leading: true,
  });
  const [targetUser, setTargetUser] = useState();
  const [searchUsername, setSearchUsername] = useState("");
  const [debouncedSearchUsername] = useDebouncedValue(searchUsername, 200, {
    leading: true,
  });
  const [userList, setUserList] = useState([]);
  const [page, onChange] = useState(1);
  const [count, setCount] = useState(0);
  const [langCount, setLangCount] = useState(0);

  const [offset, setOffset] = useState(0);
  const limit = 20;

  // Render Stories
  const getStories = async () => {
    try {
      setIsFetching(true);
      // Check if debouncedSearchString
      let params = {};
      if (debouncedSearchString) {
        params = { ...params, search: debouncedSearchString };
      }

      if (debouncedSearchUsername && targetUser) {
        params = {
          ...params,
          userId: targetUser.id,
        };
      }

      params = { ...params, locale: navigator.language, offset, limit };

      const res = await axiosPrivate.get(endpoints.STORIES_URL, {
        signal: controller.signal,
        params: params,
      });

      const resCount = await axiosPrivate.get(
        endpoints.STORIES_URL + "/count",
        {
          signal: controller.signal,
          params: params,
        }
      );

      // Set Count
      setCount(resCount.data.count || 0);
      // Set Language Count
      setLangCount(resCount.data.totalLangs || 0);

      // Check update page number if total page is less than current page
      if (page > res?.data?.totalPages) {
        onChange(res?.data?.totalPages);
      }

      setStories(res?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    // Search user by debouncedSearchUsername
    const searchUser = async () => {
      try {
        const res = await axiosPrivate.get(endpoints.SEARCH_USER_URL, {
          signal: controller.signal,
          params: { search: debouncedSearchUsername },
        });
        setUserList(res?.data);
      } catch (error) {
        console.error(error);
      }
    };

    if (debouncedSearchUsername) {
      searchUser();
    }
  }, [debouncedSearchUsername]);

  useEffect(() => {
    // Reset Page Number when input
    onChange(1);
    setOffset(0);
  }, [debouncedSearchString, debouncedSearchUsername]);

  useEffect(() => {
    // Render Stories
    getStories();
  }, [debouncedSearchString, targetUser, offset, limit]);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        user={user}
      />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
        />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <ToastContainer />

            {/* Page header */}
            <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
              All Stories ✨
            </h1>
            <div className="text-slate-600 text-sm flex">
              <PaginationPageNumber
                offset={offset}
                limit={limit}
                count={count}
              />{" "}
              (by languages: {langCount})
            </div>
            <div className="sm:flex sm:justify-between mb-8 mt-4">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0 grid grid-flow-col sm:auto-cols-max justify-between items-start gap-2">
                {/* Search form */}
                <div className="flex flex-col gap-y-1">
                  <SearchForm value={searchString} setValue={setSearchString} />
                  {/* <SearchForm
                    value={searchUsername}
                    setValue={setSearchUsername}
                    placeholder="Username..."
                  /> */}
                  <Combobox
                    value={targetUser?.username}
                    onChange={setTargetUser}
                  >
                    <div className="relative">
                      <div className="relative">
                        <Combobox.Input
                          onChange={(event) =>
                            setSearchUsername(event.target.value)
                          }
                          className="form-input pl-9 focus:border-slate-300"
                          placeholder="Search user"
                        />
                        <button
                          disabled
                          className="absolute inset-0 right-auto group"
                          aria-label="Search"
                        >
                          <svg
                            className="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-3 mr-2"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                            <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                          </svg>
                        </button>
                      </div>
                      <Combobox.Options className="z-20 absolute bg-white w-full rounded-b-lg shadow">
                        {userList.map((user) => (
                          <Combobox.Option
                            key={user.id}
                            value={user}
                            as={Fragment}
                          >
                            {({ active }) => (
                              <li
                                className={`${
                                  active
                                    ? "bg-sky-500 text-white"
                                    : "bg-white text-black"
                                } block px-4 py-2 text-sm cursor-pointer last:rounded-b-lg`}
                              >
                                {user.username}
                              </li>
                            )}
                          </Combobox.Option>
                        ))}
                      </Combobox.Options>
                    </div>
                  </Combobox>
                </div>

                {/* Filter button */}
                <div>
                  <Link
                    to="/allstories/new"
                    className="btn border-slate-200 hover:border-slate-300 bg-white text-emerald-500 hover:bg-emerald-500 hover:text-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 sm:mr-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                      />
                    </svg>
                    <span className="hidden sm:inline-block">New Story</span>
                  </Link>
                </div>
              </div>

              {/* Right: Actions */}
              <div className="flex flex-col gap-y-1">
                {/* Pagination */}
                <PaginationClassic
                  offset={offset}
                  setOffset={setOffset}
                  limit={limit}
                  count={count}
                  result={stories.length}
                />
              </div>
            </div>

            {/* Table */}
            {/* Cards */}
            <div className="grid grid-cols-12 gap-6">
              {stories.map((item, idx) => {
                return (
                  <StoryCard
                    key={`storycard-${idx}`}
                    story={item}
                    user={user}
                    fetchHandler={getStories}
                    isLoading={isFetching}
                    editUrl="/allstories"
                    user={user}
                  />
                );
              })}
              {!isFetching && stories.length === 0 && (
                <div className="col-span-12 flex items-center justify-center font-bold py-5 text-2xl">
                  {/* Show Empty */}
                  No Found
                </div>
              )}
            </div>

            <div className="sm:flex sm:justify-between sm:items-center sm:mt-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0 grid grid-flow-col sm:auto-cols-max justify-between sm:justify-start gap-2"></div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-between items-center gap-2 gap-x-4">
                <div className="text-slate-600 text-sm flex items-center">
                  <PaginationPageNumber
                    offset={offset}
                    limit={limit}
                    count={count}
                  />{" "}
                  (by languages: {langCount})
                </div>
                {/* Pagination */}
                <PaginationClassic
                  offset={offset}
                  setOffset={setOffset}
                  limit={limit}
                  count={count}
                  result={stories.length}
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default AllStories;
