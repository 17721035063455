import React, { useState, useEffect } from "react";
import { useDebouncedValue } from "@mantine/hooks";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";

const SearchTourBar = (props) => {
  // Search Tour
  const [searchString, setSearchString] = useState("");
  const [debounced] = useDebouncedValue(searchString, 200);
  const [searchTourResult, setSearchTourResult] = useState([]);
  const [results, setResults] = useState([]);

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const searchTour = async (string) => {
    try {
      const res = await axiosPrivate.get(
        endpoints.SEARCH_TOUR_URL + `?search=${string}&locale=${props.locale}`,
        {
          signal: controller.signal,
        }
      );

      console.log(res.data);
      setResults(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Search Tour by text
    if (debounced.trim()) {
      searchTour(debounced);
    } else {
      setResults([]);
    }
  }, [debounced]);

  return (
    <div className="relative w-96">
      <input
        type="text"
        placeholder="Search tour..."
        value={searchString}
        onKeyDown={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        onChange={(e) => {
          e.preventDefault();
          setSearchString(e.target.value);
        }}
        className="block w-full shadow-sm focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border-gray-300 rounded-md"
      />
      <div className="w-full z-10 absolute overflow-auto max-h-48 rounded-b-md drop-shadow-md">
        {results.map((item) => {
          return (
            <button
              key={item.id}
              type="button"
              onClick={() => {
                props.addTour({
                  title: item.title,
                  id: item.id,
                  username: item.username,
                  coverImg: item.coverImg,
                });

                // Remove Results
                setResults([]);

                // Remove search text
                setSearchString("");
              }}
              className="bg-gray-50 hover:bg-gray-100 text-gray-900 block px-4 py-2 text-sm w-full text-left"
            >
              {item.title}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default SearchTourBar;
