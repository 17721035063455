import API_ENDPOINT from "../backend";

export async function getLanguages() {
  const response = await fetch(`${API_ENDPOINT}/languages`);
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    return response.ok;
  }
}

export async function getTrending() {
  const response = await fetch(
    `${API_ENDPOINT}/recommendations/trending?limit=50`
  );
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    return response.ok;
  }
}

export async function getRecommended(id, locale) {
  const response = await fetch(
    `${API_ENDPOINT}/recommendations/?id=${id}&locale=${locale}`
  );
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    return response.ok;
  }
}
