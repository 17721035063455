import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Transition from "../utils/Transition";
import moment from "moment";

function DropdownProfile(props) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  const renderIsPremium = (isPremium) => {
    if (isPremium === true) {
      return (
        <div className="text-xs font-bold bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">
          Premium
        </div>
      );
    } else {
      return (
        <div className="text-xs font-bold bg-clip-text text-transparent bg-gradient-to-r from-teal-500 to-sky-500">
          Free
        </div>
      );
    }
  };

  const renderIsAdmin = (role) => {
    if (role === "admin") {
      return <div className="text-xs font-bold text-pink-500">Admin</div>;
    }
  };

  const renderUserIcon = (user) => {
    let initial =
      (user?.firstName ? user?.firstName[0] : "U") +
      (user?.lastName ? user?.lastName[0] : "");
    initial = initial.toUpperCase();

    if (user?.avatar) {
      return (
        <img
          className="w-12 h-12 rounded-full object-cover"
          src={props.user.avatar}
          width="32"
          height="32"
          alt="User"
        />
      );
    } else {
      return (
        <>
          <div className="flex justify-center items-center w-12 h-12 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
            <span className="text-2xl font-bold text-white">{initial}</span>
          </div>
        </>
      );
    }
  };

  if (!props.user) {
    return (
      <Link
        to="/signin"
        className="relative px-4 py-2 hover:bg-gray-100 rounded-md"
      >
        Sign In
      </Link>
    );
  } else {
    return (
      <div className="relative inline-flex items-center gap-x-2">
        <button
          ref={trigger}
          className="inline-flex justify-center items-center group"
          aria-haspopup="true"
          onClick={() => setDropdownOpen(!dropdownOpen)}
          aria-expanded={dropdownOpen}
        >
          {renderUserIcon(props?.user)}
        </button>

        <Transition
          className={`origin-top-right z-10 absolute top-full min-w-44 bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${
            props.align === "right" ? "right-0" : "left-0"
          }`}
          show={dropdownOpen}
          enter="transition ease-out duration-200 transform"
          enterStart="opacity-0 -translate-y-2"
          enterEnd="opacity-100 translate-y-0"
          leave="transition ease-out duration-200"
          leaveStart="opacity-100"
          leaveEnd="opacity-0"
        >
          <div
            ref={dropdown}
            onFocus={() => setDropdownOpen(true)}
            onBlur={() => setDropdownOpen(false)}
          >
            <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200 w-56">
              <div className="flex items-center gap-x-4">
                {renderUserIcon(props?.user)}
                <div>
                  <div className="font-bold text-lg text-slate-800">
                    {props?.user?.username}
                    {renderIsAdmin(props?.user?.role)}
                  </div>
                  <Link to="/settings">
                    {renderIsPremium(props?.user?.isPremium)}
                  </Link>
                  {moment(props?.user?.premiumExpires).isAfter() && (
                    <div className="text-xs text-slate-500 font-bold">
                      Expired At:{" "}
                      <span className="text-sky-500">
                        {moment(props?.user?.premiumExpires).fromNow()}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <ul>
              <li>
                <Link
                  className="font-medium text-sm text-sky-500 hover:text-sky-600 flex items-center py-1 px-3"
                  to="/stories"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  Studio
                </Link>
              </li>
              <li>
                <Link
                  className="font-medium text-sm text-sky-500 hover:text-sky-600 flex items-center py-1 px-3"
                  to="/settings"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  Settings
                </Link>
              </li>
              <li>
                <Link
                  className="font-medium text-sm text-sky-500 hover:text-sky-600 flex items-center py-1 px-3"
                  to="/signout"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  Sign Out
                </Link>
              </li>
            </ul>
          </div>
        </Transition>
      </div>
    );
  }
}

export default DropdownProfile;
