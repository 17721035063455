import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDebouncedValue } from "@mantine/hooks";
import useAuth from "../../hooks/useAuth";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";

import PaginationClassic from "../../components/PaginationClassic";
import PaginationPageNumber from "../../components/PaginationPageNumber";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import SearchForm from "../../partials/actions/SearchForm";
import { ToastContainer } from "react-toastify";
import RequestTable from "../../partials/publishes/RequestTable";
import ConfirmModal from "../../components/ConfirmModal";

function AllPublishRequest() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  // API
  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
    console.log([...selectedItems]);
  };

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  // Get User Profile
  const { auth } = useAuth();
  const user = auth.user;

  // Query Paramters
  const [searchParams, setSearchParams] = useSearchParams();

  // Get Requests
  const [requests, setRequests] = useState([]);
  const [searchString, setSearchString] = useState(
    searchParams.get("query") || ""
  );
  const [debouncedSearchString] = useDebouncedValue(searchString, 200, {
    leading: true,
  });

  const [searchUsername, setSearchUsername] = useState("");
  const [debouncedSearchUsername] = useDebouncedValue(searchUsername, 200, {
    leading: true,
  });
  const [page, onChange] = useState(1);

  const [offset, setOffset] = useState(0);
	const [count, setCount] = useState(0)
  const limit = 20;

  // Modal
  const [open, setOpen] = useState(false);

  // Set Status
  const [status, setStatus] = useState("");
  const [id, setId] = useState("");

  // Update Query Parameters
  useEffect(() => {
    setSearchParams({
      query: searchString,
    });
    setOffset(0);
  }, [searchString]);

  // Render Requests
  const getRequests = async () => {
    try {
      setIsFetching(true);
      // Check if debouncedSearchString
      let params = {};
      if (debouncedSearchString) {
        params = { ...params, search: debouncedSearchString };
      }

      if (debouncedSearchUsername) {
        params = { ...params, username: debouncedSearchUsername };
      }

      params = { ...params, locale: navigator.language, offset, limit };

      const res = await axiosPrivate.get(endpoints.PUBLISHES_URL, {
        signal: controller.signal,
        params: params,
      });

			const count = res.headers['x-total-count'] && parseInt(res.headers['x-total-count'])
			if (typeof count === 'number' && !Number.isNaN(count)) {
				setCount(count)
			} else {
				const resCount = await axiosPrivate.get(endpoints.PUBLISHES_COUNT_URL, {
					signal: controller.signal,
					params: params,
				});

				setCount(resCount.data.count)
			}

      // Check update page number if total page is less than current page
      if (page > res?.data?.totalPages) {
        onChange(res?.data?.totalPages);
      }

      setRequests(res?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  const confirmBtn = (id, action) => {
    setId(id);
    setStatus(action);
    setOpen(true);
  };

  const sendRequest = async () => {
    await axiosPrivate.patch(
      endpoints.PUBLISHES_URL + `/${id}`,
      { id, status },
      {
        signal: controller.signal,
      }
    );

    // Refresh List
    await getRequests();
  };

  useEffect(() => {
    // Render Requests
    getRequests();
  }, [debouncedSearchString, debouncedSearchUsername, offset, limit]);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        user={user}
      />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
        />

        <main>
          <ConfirmModal
            open={open}
            setOpen={setOpen}
            onConfirm={sendRequest}
            title="Comfirmation"
            msg={status === "approved" ? "Approve?" : "Reject?"}
          />

          <ToastContainer />
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <h1 className="text-2xl md:text-3xl text-slate-800 font-bold mb-8">
              All Requests ✨
            </h1>
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0 grid grid-flow-col sm:auto-cols-max justify-between sm:justify-start sm:justify-end gap-2">
                {/* Search form */}
                <SearchForm
                  value={searchString}
                  setValue={setSearchString}
                  placeholder="Search by ID..."
                />
                {/* <SearchForm
                  value={searchUsername}
                  placeholder="Username..."
                  setValue={setSearchUsername}
                /> */}
                {/* Filter button */}
              </div>

              {/* Right: Actions */}
              <div className='grid grid-flow-col sm:auto-cols-max justify-between gap-2 gap-x-4'>
                <div className='text-slate-600 text-sm flex items-center'>
                  <PaginationPageNumber
                    offset={offset}
                    limit={limit}
                    count={count}
                  />
                </div>
                {/* Pagination */}
                <PaginationClassic
                  offset={offset}
                  setOffset={setOffset}
                  limit={limit}
                  count={count}
                  result={requests.length}
                />
              </div>
            </div>

            {/* Table */}
            <RequestTable
              requests={requests}
              selectedItems={selectedItems}
              handleSelectedItems={handleSelectedItems}
              isLoading={isFetching}
              confirmBtn={confirmBtn}
            />
          </div>
        </main>
      </div>
    </div>
  );
}

export default AllPublishRequest;
