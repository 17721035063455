import Register from "./pages/auth/Signup";
import Login from "./pages/auth/Signin";
import Signout from "./pages/auth/Signout";
import ResetPassword from "./pages/auth/ResetPassword";
import PageNotFound from "./pages/error/PageNotFound";
import Verify from "./pages/auth/Verify";
import Reset from "./pages/auth/Reset";

// Web player
import StoryPlayer from "./pages/listen/StoryPlayer";
import StoryTourPlayer from "./pages/listen/StoryTourPlayer";
import TourPlaylist from "./pages/listen/TourPlaylist";
import CheckLegacy from "./pages/listen/CheckLegacy";

// Stories
import Stories from "./pages/stories/Stories";
import AllStories from "./pages/stories/AllStories";
import CreateStory from "./pages/stories/CreateStory";
import EditStory from "./pages/stories/EditStory";

// Tours
import Tours from "./pages/tours/Tours";
import AllTours from "./pages/tours/AllTours";
import CreateTour from "./pages/tours/CreateTour";
import EditTour from "./pages/tours/EditTour";

// Banners
import AllBanners from "./pages/banners/AllBanners";
import CreateBanner from "./pages/banners/CreateBanner";
import EditBanner from "./pages/banners/EditBanner";

// Exclusive Packages
import AllPackages from "./pages/exclusivepackages/AllPackages";
import CreatePackage from "./pages/exclusivepackages/CreatePackage";
import EditPackage from "./pages/exclusivepackages/EditPackage";

// Users
import AllUsers from "./pages/users/AllUsers";
import EditUser from "./pages/users/EditUser";


// Giftcodes
import Giftcodes from "./pages/giftcodes/Giftcodes";

// Publishes
import AllPublishRequest from "./pages/publishes/AllPublishRequest";

// Alert
import Alert from "./pages/alert/Alert";

// Trash
import Trash from "./pages/Trash";

// Settings
import Settings from "./pages/settings/Settings";
import Delete from "./pages/settings/Delete";

// Onboarding
import Onboarding01 from "./pages/onboarding/Onboarding01";
import Onboarding02 from "./pages/onboarding/Onboarding02";
import Onboarding03 from "./pages/onboarding/Onboarding03";
import Onboarding04 from "./pages/onboarding/Onboarding04";

// Components
import ButtonPage from "./pages/component/ButtonPage";
import FormPage from "./pages/component/FormPage";
import DropdownPage from "./pages/component/DropdownPage";
import AlertPage from "./pages/component/AlertPage";
import ModalPage from "./pages/component/ModalPage";
import PaginationPage from "./pages/component/PaginationPage";
import TabsPage from "./pages/component/TabsPage";
import BreadcrumbPage from "./pages/component/BreadcrumbPage";
import BadgePage from "./pages/component/BadgePage";
import AvatarPage from "./pages/component/AvatarPage";
import TooltipPage from "./pages/component/TooltipPage";
import AccordionPage from "./pages/component/AccordionPage";
import IconsPage from "./pages/component/IconsPage";

// Loading
import Loader from "./partials/Loader";

// For Reference Only
import Layout from "./components/Layout";
import Unauthorized from "./components/Unauthorized";
import Lounge from "./components/Lounge";
import LinkPage from "./components/LinkPage";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import { Routes, Route, Navigate } from "react-router-dom";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* web player */}
          <Route exact path="/" element={<CheckLegacy />} />
          <Route exact path="/listen/tour/:id" element={<TourPlaylist />} />
          <Route
            exact
            path="/listen/tour/:id/:storyId"
            element={<StoryTourPlayer />}
          />
          <Route exact path="/listen/story/:id" element={<StoryPlayer />} />

          {/* public routes */}
          <Route path="signin" element={<Login />} />
          <Route path="signup" element={<Register />} />
          <Route path="signout" element={<Signout />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="reset/:resetToken" element={<Reset />} />
          <Route path="linkpage" element={<LinkPage />} />
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="verify/:verify_token" element={<Verify />} />
          <Route path="loading" element={<Loader />} />
          {/* <Route path="/SearchResult" element={<SearchResult />} /> */}

          {/* we want to protect these routes */}
          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth allowedRoles="user" />}>
              <Route path="/stories" element={<Stories />} />
              <Route path="/stories/new" element={<CreateStory />} />
              <Route
                path="/stories/:id/edit"
                element={<EditStory fallback="/stories" />}
              />
              <Route path="/tours" element={<Tours />} />
              <Route
                path="/tours/new"
                element={<CreateTour fallback="/tours" />}
              />
              <Route
                path="/tours/:id/edit"
                element={<EditTour fallback="/tours" />}
              />
              {/* <Route path="/trash" element={<Trash />} /> */}

              {/* Settings */}
              <Route path="/settings/" element={<Settings />} />
              <Route path="/delete" element={<Delete />} />
            

              {/* Onboarding */}
              <Route path="/onboarding-01" element={<Onboarding01 />} />
              <Route path="/onboarding-02" element={<Onboarding02 />} />
              <Route path="/onboarding-03" element={<Onboarding03 />} />
              <Route path="/onboarding-04" element={<Onboarding04 />} />
              {/* Button Page */}
              <Route path="/component/button" element={<ButtonPage />} />
              <Route path="/component/form" element={<FormPage />} />
              <Route path="/component/dropdown" element={<DropdownPage />} />
              <Route path="/component/alert" element={<AlertPage />} />
              <Route path="/component/modal" element={<ModalPage />} />
              <Route
                path="/component/pagination"
                element={<PaginationPage />}
              />
              <Route path="/component/tabs" element={<TabsPage />} />
              <Route
                path="/component/breadcrumb"
                element={<BreadcrumbPage />}
              />
              <Route path="/component/badge" element={<BadgePage />} />
              <Route path="/component/avatar" element={<AvatarPage />} />
              <Route path="/component/tooltip" element={<TooltipPage />} />
              <Route path="/component/accordion" element={<AccordionPage />} />
              <Route path="/component/icons" element={<IconsPage />} />
            </Route>

            {/* <Route element={<RequireAuth allowedRoles={[ROLES.Editor]} />}>
            <Route path="editor" element={<Editor />} />
          </Route> */}

            <Route element={<RequireAuth allowedRoles="admin" />}>
              {/* Stories */}
              <Route path="/allstories" element={<AllStories />} />
              <Route
                path="/allstories/new"
                element={<CreateStory fallback="/allstories" />}
              />
              <Route
                path="/allstories/:id/edit"
                element={<EditStory fallback="/allstories" />}
              />

              {/* Tours */}
              <Route path="/alltours" element={<AllTours />} />
              <Route
                path="/alltours/new"
                element={<CreateTour fallback="/alltours" />}
              />
              <Route
                path="/alltours/:id/edit"
                element={<EditTour fallback="/alltours" />}
              />

              {/* Tours */}
              <Route path="/allusers" element={<AllUsers />} />
              {/* <Route
              path="/alltours/new"
              element={<CreateTour fallback="/alltours" />}
            /> */}
              <Route
                path="/allusers/:id/edit"
                element={<EditUser fallback="/allusers" />}
              />

              {/* Banners */}
              <Route path="/allbanners" element={<AllBanners />} />
              <Route
                path="/allbanners/new"
                element={<CreateBanner fallback="/allbanners" />}
              />
              <Route
                path="/allbanners/:id/edit"
                element={<EditBanner fallback="/allbanners" />}
              />

              {/* Exclusive Packages */}
              <Route path="/exclusivepackages" element={<AllPackages />} />
              <Route
                path="/exclusivepackages/new"
                element={<CreatePackage fallback="/exclusivepackages" />}
              />
              <Route
                path="/exclusivepackages/:id/edit"
                element={<EditPackage fallback="/exclusivepackages" />}
              />

              {/* All Publish Requests */}
              <Route path="/publishes" element={<AllPublishRequest />} />

              <Route path="/giftcodes" element={<Giftcodes />} />
              <Route path="/alert" element={<Alert />} />
            </Route>

            {/* <Route
            element={<RequireAuth allowedRoles={[ROLES.Editor, ROLES.Admin]} />}
          >
            <Route path="lounge" element={<Lounge />} />
          </Route> */}
          </Route>

          {/* catch all */}
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
