import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";

import LoadingBtn from "../../components/LoadingBtn";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import StorySelection from "../../partials/stories/StorySelection";
import TourSelection from "../../partials/tours/TourSelection";

export default function CreatePackage(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const { auth } = useAuth();
  const user = auth?.user;
  const [stories, setStories] = useState([]);
  const [tours, setTours] = useState([]);

  const submitHandler = async () => {
    setIsSaving(true);
    const values = formik.values;
    try {
      // Preprocess stories and tours
      values.stories = stories.map((story) => story.id);
      values.tours = tours.map((tour) => tour.id);

      const res = await axiosPrivate.post(
        endpoints.EXCLUSIVE_PACKAGES_URL,
        values,
        {
          signal: controller.signal,
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      const id = res.data?.id;

      // Create Number of Code
      await axiosPrivate.post(
        `${endpoints.EXCLUSIVE_PACKAGES_URL}/${id}/code`,
        { number: values.numberOfCode }
      );

      toast.success("Create Successfully!", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setIsSaving(false);
    } catch (err) {
      console.log(err);

      toast.error("Error occurred!", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      if (!err?.response) {
        console.error("No Server res");
      } else {
        let error = err?.response.data.errors.msg;
        // setWarning(<Warning title="Invalid Token" />);
      }
    } finally {
      setIsSaving(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      remark: "",
      stories: [],
      tours: [],
      numberOfCode: 0,
      isEnabled: true,
      isSingleCode: false,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("This field is required"),
      remark: Yup.string(),
      numberOfCode: Yup.number().required("This field is required"),
      isEnabled: Yup.boolean().required("This field is required"),
      isSingleCode: Yup.boolean().required("This field is required"),
    }),
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  return (
    <>
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
        />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            user={user}
          />

          <main>
            <ToastContainer />

            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full">
              <form onSubmit={formik.handleSubmit}>
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                  {/*  Global Setting */}
                  <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Edit Exclusive Package
                      </h3>
                    </div>
                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                        <label
                          htmlFor="title"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Title <span className="text-rose-500">*</span>
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="sm:col-span-2">
                            <div className="mt-4 space-y-4">
                              <div className="relative w-96">
                                <input
                                  type="text"
                                  name="title"
                                  value={formik.values.title}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  className="block w-full shadow-sm focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>

                              <p className="text-red-600 text-sm">
                                {formik.errors.title && formik.touched.title
                                  ? `Error: ${formik.errors.title}`
                                  : null}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                        <label
                          htmlFor="remark"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Remark
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="sm:col-span-2">
                            <div className="mt-4 space-y-4">
                              <div className="relative w-96">
                                <input
                                  type="text"
                                  name="remark"
                                  value={formik.values.remark}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  className="block w-full shadow-sm focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>

                              <p className="text-red-600 text-sm">
                                {formik.errors.remark && formik.touched.remark
                                  ? `Error: ${formik.errors.remark}`
                                  : null}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                        <label
                          htmlFor="isPremium"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Stories <span className="text-rose-500">*</span>
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="sm:col-span-2">
                            <div className="mt-4 space-y-4">
                              <StorySelection
                                formik={formik}
                                stories={stories}
                                setStories={setStories}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                        <label
                          htmlFor="isPremium"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Tours <span className="text-rose-500">*</span>
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="sm:col-span-2">
                            <div className="mt-4 space-y-4">
                              <TourSelection
                                formik={formik}
                                tours={tours}
                                setTours={setTours}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                        <div>
                          <div
                            className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                            id="label-notifications"
                          >
                            Enable Status{" "}
                            <span className="text-rose-500">*</span>
                          </div>
                          <p className="text-sm text-gray-500">
                            Whether this exPackage is visible to normal user
                            (User are not able to see this exPackage even if
                            they are the user)
                          </p>
                        </div>
                        <div className="sm:col-span-2">
                          <div className="max-w-lg">
                            <div className="mt-4 space-y-4">
                              <select
                                id="isEnabled"
                                name="isEnabled"
                                value={formik.values.isEnabled}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className="max-w-lg block focus:ring-emerald-500 focus:border-emerald-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                              >
                                <option value="true">Enabled</option>
                                <option value="false">Disabled</option>
                              </select>
                              <p className="text-red-600 text-sm">
                                {formik.errors.isEnabled &&
                                  formik.touched.isEnabled
                                  ? `Error: ${formik.errors.isEnabled}`
                                  : null}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                        <div>
                          <div
                            className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                            id="label-notifications"
                          >
                            Single Code{" "}
                            <span className="text-rose-500">*</span>
                          </div>

                        </div>
                        <div className="sm:col-span-2">
                          <div className="max-w-lg">
                            <div className="mt-4 space-y-4">
                              <select
                                id="isSingleCode"
                                name="isSingleCode"
                                value={formik.values.isSingleCode}
                                onChange={(e) => {
                                  formik.setFieldValue("isSingleCode", e.target.value === 'true')
                                  if (e.target.value === 'true') {
                                    formik.setFieldValue("numberOfCode", 1)
                                  }
                                }}
                                onBlur={formik.handleBlur}
                                className="max-w-lg block focus:ring-emerald-500 focus:border-emerald-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                              >
                                <option value="true">Enabled</option>
                                <option value="false">Disabled</option>
                              </select>
                              <p className="text-red-600 text-sm">
                                {formik.errors.isSingleCode &&
                                  formik.touched.isSingleCode
                                  ? `Error: ${formik.errors.isSingleCode}`
                                  : null}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                        <label
                          htmlFor="numberOfCode"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Number of redemption code
                          <span className="text-rose-500">*</span>
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="sm:col-span-2">
                            <div className="mt-4 space-y-4">
                              <div className="relative w-96">
                                <input
                                  type="text"
                                  name="numberOfCode"
                                  value={formik.values.isSingleCode ? 1 : formik.values.numberOfCode}
                                  readOnly={formik.values.isSingleCode}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  className="block w-full shadow-sm focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>

                              <p className="text-red-600 text-sm">
                                {formik.errors.numberOfCode &&
                                  formik.touched.numberOfCode
                                  ? `Error: ${formik.errors.numberOfCode}`
                                  : null}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="py-5">
                  <div className="flex justify-end items-center space-x-4">
                    <Link
                      to={props.fallback || "/stories"}
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                    >
                      Go Back
                    </Link>
                    <div>
                      {isSaving ? (
                        <LoadingBtn text="Saving" />
                      ) : Object.keys(formik.errors).length > 0 ? (
                        <button
                          type="button"
                          disabled
                          className="btn bg-emerald-400 text-white cursor-not-allowed"
                        >
                          Save Changes
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn bg-emerald-500 hover:bg-emerald-600 text-white"
                        >
                          Save Changes
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
              {/* Content */}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
