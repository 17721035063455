import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useParams} from "react-router-dom";
import * as Yup from "yup";
import LoadingBtn from "../../components/LoadingBtn";

import endpoints from "../../api/endpoints";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Banner from "../../components/Banner";

function ConfigPanel(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [warning, setWarning] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const [config, setConfig] = useState({
    midpoint: 1,
    steepness: 1,
  });

  const getAdminSetting = async () => {
    try {
      const res = await axiosPrivate.get(endpoints.CHANGE_ADMIN_SETTING);
      setConfig(res.data); 
      changeAdminSettingForm.setValues(res.data)

    } catch (error) {
      console.error(error);
    }
  };


  const main = async () => {
    try {
      await Promise.all([getAdminSetting()]);
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  useEffect(() => {
    main();
  }, []);

  // Change Admin setting Handler
  const changeAdminSettingHandler = async (values, { setErrors, resetForm }) => {
    setIsLoading(true);
    // Reset Alert
    setSuccess();
    setWarning();

    try {
      const res = await axiosPrivate.patch(endpoints.CHANGE_ADMIN_SETTING, values);
      setSuccess("Admin setting changed successfully");

      
    } catch (error) {
      console.log(error.response.data.errors.msg);
      setWarning(error.response.data.errors.msg);
    } finally {
      setIsLoading(false);
    }
  };

  const changeAdminSettingForm = useFormik({
    initialValues: {
      id: config.id,
      midpoint: config.midpoint,
      steepness: config.steepness,
    },
    validationSchema: Yup.object({
      midpoint: Yup.number()
      .integer()
      .min(1)
      .max(100)
      .required("This field is required"),
      steepness: Yup.number()
      .integer()
      .min(1)
      .max(10)
      .required("This field is required"),
    }),
    onSubmit: changeAdminSettingHandler,
  });
  return (
    <div className="grow">
      <form onSubmit={changeAdminSettingForm.handleSubmit}>
        {/* Panel body */}
        <div className="p-6 space-y-6">
          <h2 className="text-2xl text-slate-800 font-bold mb-5">Admin Settings</h2>
          {/* Password */}
          <section>
            <h2 className="text-xl leading-snug text-slate-800 font-bold mb-1">
            Change the sorting algorithm
            </h2>
            <Banner type="warning" open={warning} setOpen={setWarning}>
              {warning}
            </Banner>

            <Banner type="success" open={success} setOpen={setSuccess}>
              {success}
            </Banner>

            <div className="mt-5">
              <div className="sm:flex sm:items-start space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                <div className="sm:w-1/3">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="midpoint"
                  >
                    Mid point
                  </label>
                  <input
                    id="midpoint"
                    name="midpoint"
                    className="form-input w-full text-lg sm:text-base"
        
                    onChange={changeAdminSettingForm.handleChange}
                    value={changeAdminSettingForm.values.midpoint}
                  />
                  <p className="text-red-600 text-sm">
                    {changeAdminSettingForm.errors.midpoint &&
                    changeAdminSettingForm.touched.midpoint
                      ? `Error: ${changeAdminSettingForm.errors.midpoint}`
                      : null}
                  </p>
                </div> 
                <div className="sm:w-1/3">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="steepness"
                  >
                    Steepness
                  </label>
                  <input
                    id="steepness"
                    name="steepness"
                    className="form-input w-full text-lg sm:text-base"
            
                    onChange={changeAdminSettingForm.handleChange}
                    value={changeAdminSettingForm.values.steepness}
                  />
                  <p className="text-red-600 text-sm">
                    {changeAdminSettingForm.errors.steepness &&
                    changeAdminSettingForm.touched.steepness
                      ? `Error: ${changeAdminSettingForm.errors.steepness}`
                      : null}
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* Panel footer */}
        <footer>
          <div className="flex flex-col px-6 py-5 border-t border-slate-200">
            <div className="flex self-end ml-3">
              {isLoading ? (
                <LoadingBtn text="Saving" />
              ) : (
                <button
                  type="submit"
                  className="btn bg-emerald-500 hover:bg-emerald-600 text-white"
                >
                  Save Changes
                </button>
              )}
            </div>
          </div>
        </footer>
      </form>
    </div>
  );
}

export default ConfigPanel;
