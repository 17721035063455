import React, { useEffect, useState } from "react";
import { getRecommended } from "../../utils/listen/api";
import { Link } from "react-router-dom";

const Related = ({ id, locale }) => {
  const [related, setRelated] = useState([]);

  useEffect(() => {
    const getRelated = async () => {
      const result = await getRecommended(id, locale);
      setRelated(result);
    };
    getRelated();
  }, []);

  return (
    <div className="mt-4 py-4 sm:p-0">
      <div className="font-extrabold text-2xl mb-2">Related Stories/Tours</div>
      <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8">
        {related.map((item) => (
          <li key={item.id} className="relative">
            <Link
              to={`/listen/${item.type}/${item.id}`}
              className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden"
            >
              <img
                src={item.coverImg}
                alt={item.title}
                className="w-full h-full aspect-square object-cover pointer-events-none group-hover:opacity-75"
              />
            </Link>
            <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">
              {item.title}
            </p>
            <p className="block text-sm font-medium text-gray-500 pointer-events-none">
              {item.owner.username}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Related;
