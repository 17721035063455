import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FileUploader } from "react-drag-drop-files";
import { ToastContainer, toast } from "react-toastify";
import { Source, Layer, Map, GeolocateControl } from "react-map-gl";
import * as turf from "@turf/turf";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";

import GeocoderControl from "../../partials/maps/geocoder-control";
import LoadingBtn from "../../components/LoadingBtn";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import LanguageSelectBar from "../../partials/banners/LanguageSelectBar";
import Spinner from "../../partials/Spinner";
import SearchUserBar from "../../partials/users/SearchUserBar";
import StorySelection from "../../partials/stories/StorySelection";
import TourSelection from "../../partials/tours/TourSelection";
import UserSelection from "../../partials/users/UserSelection";

const imageTypes = ["JPG", "JPEG", "PNG"];

function CreateBanner(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedLocale, setSelectedLocale] = useState("");

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const { auth } = useAuth();
  const user = auth?.user;
  const [marker, setMarker] = useState([null, null]);
  const [radius, setRadius] = useState(2000);
  const [circle, setCircle] = useState();
  const [users, setUsers] = useState([]);
  const [stories, setStories] = useState([]);
  const [tours, setTours] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [bannerOwner, setOwner] = useState("");

  const getLanguages = async () => {
    try {
      const res = await axiosPrivate.get(endpoints.LANGS_URL, {
        signal: controller.signal,
      });

      setLanguages(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteImage = (langIdx, idx, field) => {
    // deleteImage(idx, mediaIdx, "medias");
    if (idx !== null) {
      formik.setFieldValue(
        `languages[${langIdx}].${field}`,
        formik.values.languages[langIdx][field].filter(
          (item, index) => index !== idx
        )
      );
    } else {
      formik.setFieldValue(`languages[${langIdx}].${field}`, "");
    }
  };

  const updateLocation = (lnglat, inputRadius) => {
    // Update Formik
    formik.setFieldValue("targetingMeta.location.coordinates", lnglat);

    // Update Marker
    setMarker(lnglat);

    // Update circle
    const paintRadius = inputRadius || radius;
    const range = turf.circle(lnglat, paintRadius, {
      units: "meters",
    });
    setCircle(range);
    setRadius(paintRadius);

    formik.setFieldValue("targetingMeta.radius", paintRadius);
  };

  const handleMapClick = (event) => {
    const lnglat = [event.lngLat.lng, event.lngLat.lat];
    updateLocation(lnglat);
  };

  const moveImageToTop = (langIdx, idx, field) => {
    // deleteImage(idx, mediaIdx, "medias");

    if (idx) {
      formik.setFieldValue(`languages[${langIdx}].${field}`, [
        formik.values.languages[langIdx][field][idx],
        ...formik.values.languages[langIdx][field].filter(
          (item, index) => index !== idx
        ),
      ]);
    } else {
      formik.setFieldValue(`languages[${langIdx}].${field}`, "");
    }
  };

  useEffect(() => {
    getLanguages();
  }, []);

  const fileUpload = async (blob, type) => {
    let formData = new FormData();
    formData.append(type, blob);

    if (type === "image") {
      const res = await axiosPrivate.post(endpoints.UPLOAD_IMAGE, formData);
      return res.data.UPLOAD;
    } else if (type === "audio") {
      const res = await axiosPrivate.post(endpoints.UPLOAD_ADUIO, formData);
      return res.data.UPLOAD;
    } else {
      throw new Error("UNKNOWN_TYPE");
    }
  };

  const handleFileUpload = async (file, idx, field, type) => {
    if (file.length > 0) {
      // Activate Loader
      formik.setFieldValue(`languages[${idx}].${field}`, [
        ...formik.values.languages[idx][field],
        ...Array(file.length).fill("loader"),
      ]);

      // Upload to server
      const urls = await Promise.all(
        Array.from(file).map((item) => fileUpload(item, type))
      );

      // Update URL to form
      formik.setFieldValue(`languages[${idx}].${field}`, [
        ...formik.values.languages[idx][field],
        ...urls,
      ]);
    } else {
      // Activate Loader
      formik.setFieldValue(`languages[${idx}].${field}`, "loader");

      // Upload to server
      const url = await fileUpload(file, type);

      // Update URL to form
      formik.setFieldValue(`languages[${idx}].${field}`, url);
    }
  };

  const submitHandler = async (values, { setErrors, resetForm }) => {
    setIsSaving(true);
    try {
      if (values.languages.length === 0) {
        toast.error("Please add at least one language", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        // if type is not user, remove user field
        if (values.type !== "user") {
          delete values.user;
        }

        await axiosPrivate.post(endpoints.BANNERS_URL, formik.values, {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        });

        toast.success("Create Successfully!", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

      setIsSaving(false);
    } catch (err) {
      console.log(err);

      toast.error("Error occurred!", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      if (!err?.response) {
        console.error("No Server res");
      } else {
        let error = err?.response.data.errors.msg;
        // setWarning(<Warning title="Invalid Token" />);
      }
    } finally {
      setIsSaving(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      languages: [],
      ordering: 99,
      stories: [],
      tours: [],
      user: "",
      type: "user",
      staticLink: "",
      giftcode: "",
      isPrivate: false,
      users: [],
      isEnabled: true,
      isDraft: false,
    },
    validationSchema: Yup.object({
      languages: Yup.array().of(
        Yup.object().shape({
          title: Yup.string().required("This field is required"),
          description: Yup.string().optional(),
          coverImg: Yup.string().required("This field is required"),
          medias: Yup.array().of(Yup.string()).optional(),
          locale: Yup.string().required("This field is required"),
        })
      ),
      type: Yup.string().required("This field is required"),
      user: Yup.string().when("type", (type, schema) => {
        if (type === "user") {
          return schema.required("This field is required");
        }
        return schema;
      }),
      staticLink: Yup.string().when("type", (type, schema) => {
        if (type === "link") {
          return schema.required("This field is required");
        }
        return schema;
      }),
      ordering: Yup.number().required("This field is required"),
      isEnabled: Yup.boolean().required("This field is required"),
      isDraft: Yup.boolean().required("This field is required"),
    }),
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  console.log(formik.errors);

  const copyToOtherLanguages = (idx, field) => {
    const value = formik.values.languages[idx][field];
    const languages = formik.values.languages;
    languages.forEach((item, i) => {
      if (i !== idx) {
        formik.setFieldValue(`languages[${i}].${field}`, value);
      }
    });

    toast.success("Copied to other languages!", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const setBannerOwner = (user) => {
    // Display
    setOwner(user);

    // Formik
    formik.setFieldValue("user", user.id);
  };

  const renderLanguageForm = (item, idx) => {
    if (item.locale !== selectedLocale) return;
    return (
      <div key={`langForm-${idx}`}>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:py-5">
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Title <span className="text-rose-500">*</span>
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <textarea
              id="title"
              name={`languages[${idx}].title`}
              rows={3}
              value={formik.values.languages[idx].title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="max-w-lg shadow-sm block w-full focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border border-gray-300 rounded-md"
            />
            <p className="text-red-600 text-sm">
              {formik.errors.languages &&
              formik.touched.languages &&
              formik.errors.languages[idx]?.title
                ? `Error: ${formik.errors.languages[idx]?.title}`
                : null}
            </p>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Description
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <textarea
              id="description"
              name={`languages[${idx}].description`}
              rows={10}
              value={formik.values.languages[idx].description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="max-w-lg shadow-sm block w-full focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border border-gray-300 rounded-md"
            />
            <p className="text-red-600 text-sm">
              {formik.errors.languages &&
              formik.touched.languages &&
              formik.errors.languages[idx]?.description
                ? `Error: ${formik.errors.languages[idx]?.description}`
                : null}
            </p>
            <p className="mt-2 text-sm text-gray-500">
              Write a few sentences about the banner.
            </p>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t sm:border-gray-200 py-5">
          <div className="flex justify-between items-center sm:block sm:space-y-4">
            <label
              htmlFor="coverImg"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Cover image <span className="text-rose-500">*</span>
            </label>
            <button
              type="button"
              onClick={() => {
                copyToOtherLanguages(idx, "coverImg");
              }}
              className="btn btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
            >
              Copy to other language(s)
            </button>
          </div>

          <div className="mt-1 sm:mt-0 sm:col-span-2">
            {formik.values.languages[idx].coverImg &&
              (formik.values.languages[idx].coverImg === "loader" ? (
                <div className="relative h-24 w-full">
                  <Spinner />
                </div>
              ) : (
                <div className="relative aspect-12/5">
                  <img
                    src={formik.values.languages[idx].coverImg}
                    alt="Cover Image"
                    className="h-full w-full object-cover mb-2"
                  />
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      // TODO: Delete Cover Images
                      deleteImage(idx, null, "coverImg");
                    }}
                    className="group flex justify-center items-center absolute h-5 w-5 top-0 right-0 -mt-1 -mr-1 z-20"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="flex absolute h-4 w-4 z-10 text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                    <span className="relative inline-flex rounded-full h-5 w-5 bg-rose-500 group-hover:bg-rose-600"></span>
                  </button>
                </div>
              ))}
            <FileUploader
              multiple={false}
              handleChange={(file) =>
                handleFileUpload(file, idx, "coverImg", "image")
              }
              name={`languages[${idx}].coverImg`}
              classes="h-48 max-w-lg flex justify-center px-6 py-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
              types={imageTypes}
            />
            <p className="mt-2 text-sm text-gray-500">
              Optimal aspect ratio: 12:5{" "}
            </p>
            <p className="text-red-600 text-sm">
              {formik.errors.languages &&
              formik.touched.languages &&
              formik.errors.languages[idx]?.coverImg
                ? `Error: ${formik.errors.languages[idx]?.coverImg}`
                : null}
            </p>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
          <div className="flex justify-between items-center sm:block sm:space-y-4">
            <label
              htmlFor="medias"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Other photo(s)
            </label>
            <button
              type="button"
              onClick={() => {
                copyToOtherLanguages(idx, "medias");
              }}
              className="btn btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
            >
              Copy to other language(s)
            </button>
          </div>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="grid grid-cols-2 md:grid-cols-3 gap-x-2">
              {formik.values.languages[idx].medias &&
                formik.values.languages[idx].medias.length > 0 &&
                formik.values.languages[idx].medias.map((media, mediaIdx) => {
                  if (media === "loader") {
                    return (
                      <div className="relative h-24 w-full" key={mediaIdx}>
                        <Spinner />
                      </div>
                    );
                  } else {
                    return (
                      <div className="relative" key={mediaIdx}>
                        <img
                          src={media}
                          alt="Cover Image"
                          className="w-full mb-2"
                        />
                        <button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            deleteImage(idx, mediaIdx, "medias");
                          }}
                          className="group flex justify-center items-center absolute h-5 w-5 top-0 right-0 -mt-1 -mr-1 z-20"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="flex absolute h-4 w-4 z-10 text-white"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                          <span className="relative inline-flex rounded-full h-5 w-5 bg-rose-500 group-hover:bg-rose-600"></span>
                        </button>
                        <button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            moveImageToTop(idx, mediaIdx, "medias");
                          }}
                          className="group flex justify-center items-center absolute h-5 w-5 top-7 right-0 -mt-1 -mr-1 z-20"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="flex absolute h-4 w-4 z-10 text-white"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"
                            />
                          </svg>
                          <span className="relative inline-flex rounded-full h-5 w-5 bg-amber-500 group-hover:bg-amber-600"></span>
                        </button>
                      </div>
                    );
                  }
                })}
            </div>

            <FileUploader
              multiple={true}
              handleChange={(file) =>
                handleFileUpload(file, idx, "medias", "image")
              }
              name={`languages[${idx}].medias`}
              classes="h-48 max-w-lg flex justify-center px-6 py-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
              types={imageTypes}
            />
            <p className="text-red-600 text-sm">
              {formik.errors.languages &&
              formik.touched.languages &&
              formik.errors.languages[idx]?.medias
                ? `Error: ${formik.errors.languages[idx]?.medias}`
                : null}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        user={user}
      />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
        />

        <main>
          <ToastContainer />

          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full">
            <form onSubmit={formik.handleSubmit}>
              <div className="max-w-5xl mx-auto flex flex-col lg:flex-row lg:space-x-8 xl:space-x-16 space-y-8 lg:space-y-0">
                {/* Sidebar */}
                <LanguageSelectBar
                  languages={languages}
                  selectedLocale={selectedLocale}
                  setSelectedLocale={setSelectedLocale}
                  formik={formik}
                />

                {/* Language */}
                <div className="w-full">
                  {/* Page content */}
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Create Banner
                    </h3>
                  </div>
                  <div className="space-y-6 sm:space-y-5">
                    {formik.values.languages.map((item, idx) => {
                      return renderLanguageForm(item, idx);
                    })}
                  </div>
                </div>
              </div>

              <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                {/*  Global Setting */}
                <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Global Settings
                    </h3>
                  </div>
                  <div className="space-y-6 sm:space-y-5">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                      <label
                        htmlFor="ordering"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Ordering <span className="text-rose-500">*</span>
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="sm:col-span-2">
                          <div className="mt-4 space-y-4">
                            <div className="relative w-96">
                              <input
                                type="number"
                                placeholder="e.g. 10"
                                name="ordering"
                                value={formik.values.ordering}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className="block w-full shadow-sm focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border-gray-300 rounded-md"
                              />
                            </div>

                            <p className="text-red-600 text-sm">
                              {formik.errors.ordering && formik.touched.ordering
                                ? `Error: ${formik.errors.ordering}`
                                : null}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                      <label
                        htmlFor="type"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Type <span className="text-rose-500">*</span>
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="sm:col-span-2">
                          <div className="mt-4 space-y-4">
                            <select
                              id="type"
                              name="type"
                              value={formik.values.type}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className="max-w-lg block focus:ring-emerald-500 focus:border-emerald-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            >
                              <option value="user">User Banner</option>
                              <option value="library">Library</option>
                              <option value="link">Static Link</option>
                              <option value="giftcode">Giftcode</option>
                            </select>
                            <p className="text-red-600 text-sm">
                              {formik.errors.type && formik.touched.type
                                ? `Error: ${formik.errors.type}`
                                : null}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {formik.values.type === "user" && (
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                        <label
                          htmlFor="user"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          User <span className="text-rose-500">*</span>
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          {bannerOwner.username && (
                            <span className="mb-2 text-medium inline-flex items-center font-extrabold bg-emerald-100 text-emerald-600 rounded-full text-center px-2.5 py-1">
                              {bannerOwner.username}
                            </span>
                          )}
                          <SearchUserBar setUser={setBannerOwner} />

                          <p className="text-red-600 text-sm">
                            {formik.errors.user && formik.touched.type
                              ? `Error: ${formik.errors.user}`
                              : null}
                          </p>
                        </div>
                      </div>
                    )}

                    {/* Private */}
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                      <label
                        htmlFor="isPrivate"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Private Banner <span className="text-rose-500">*</span>
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="sm:col-span-2">
                          <div className="mt-4 space-y-4">
                            <select
                              id="isPrivate"
                              name="isPrivate"
                              value={formik.values.isPrivate}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className="max-w-lg block focus:ring-emerald-500 focus:border-emerald-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            >
                              <option value={true}>Private</option>
                              <option value={false}>Public</option>
                            </select>
                            <p className="mt-2 text-sm text-gray-500">
                              If set to "Private", this banner is only visible
                              to a subset of users
                            </p>
                            <p className="text-red-600 text-sm">
                              {formik.errors.isPrivate &&
                              formik.touched.isPrivate
                                ? `Error: ${formik.errors.isPrivate}`
                                : null}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {(formik.values.isPrivate === "true" ||
                      formik.values.isPrivate === true) && (
                      <>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                          <label
                            htmlFor="users"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Users
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="sm:col-span-2">
                              <div className="mt-4 space-y-4">
                                <UserSelection
                                  formik={formik}
                                  users={users}
                                  setUsers={setUsers}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {/* Targeting */}
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                      <label
                        htmlFor="targetingMode"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Targeting Mode <span className="text-rose-500">*</span>
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="sm:col-span-2">
                          <div className="mt-4 space-y-4">
                            <select
                              id="targetingMode"
                              name="targetingMode"
                              value={formik.values.targetingMode}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className="max-w-lg block focus:ring-emerald-500 focus:border-emerald-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            >
                              <option value="global">Global</option>
                              <option value="local">Local</option>
                            </select>
                            <p className="text-red-600 text-sm">
                              {formik.errors.targetingMode &&
                              formik.touched.targetingMode
                                ? `Error: ${formik.errors.targetingMode}`
                                : null}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Local Targeting */}
                    {formik.values.targetingMode === "local" && (
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                        <label
                          htmlFor="owner"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Location <span className="text-rose-500">*</span>
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2 space-y-2">
                          <Map
                            initialViewState={{
                              longitude: marker[0] || 114.152666056,
                              latitude: marker[1] || 22.28416553,
                              zoom: marker ? 10 : 10,
                            }}
                            style={{
                              height: 400,
                              borderRadius: 10,
                            }}
                            mapStyle="mapbox://styles/mapbox/streets-v9"
                            attributionControl={false}
                            onClick={handleMapClick}
                            mapboxAccessToken="pk.eyJ1Ijoid2FxYXM0NjkzIiwiYSI6ImNrcWF2MHF4OTBhYjQydG4weHVuZHRtbjMifQ.XSXJ4c0-03CovvpTCnDSqw"
                          >
                            <Source type="geojson" data={circle}>
                              <Layer
                                type="fill"
                                paint={{
                                  "fill-opacity": 0.5,
                                  "fill-color": "#F69E5E",
                                }}
                              />
                            </Source>{" "}
                            <GeolocateControl position="bottom-right" />
                            <GeocoderControl
                              mapboxAccessToken="pk.eyJ1Ijoid2FxYXM0NjkzIiwiYSI6ImNrcWF2MHF4OTBhYjQydG4weHVuZHRtbjMifQ.XSXJ4c0-03CovvpTCnDSqw"
                              position="top-left"
                              language={
                                navigator.language || navigator.userLanguage
                              }
                              updateLocation={updateLocation}
                            />
                          </Map>
                          <input
                            type="number"
                            placeholder="Radius (meters)"
                            name="radius"
                            value={radius}
                            min={0}
                            step={1000}
                            onChange={(e) => {
                              e.preventDefault();
                              updateLocation(marker, e.target.value);
                            }}
                            className="block w-full shadow-sm focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    )}

                    {formik.values.type === "link" && (
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:py-5">
                        <label
                          htmlFor="staticLink"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Static Link <span className="text-rose-500">*</span>
                        </label>
                        <div>
                          <input
                            type="text"
                            placeholder="URL: https://example.com"
                            name="staticLink"
                            value={formik.values.staticLink}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="block w-full shadow-sm focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border-gray-300 rounded-md"
                          />
                          <div className="mt-1 flex items-center space-x-1">
                            <button
                              type="button"
                              onClick={() => {
                                // append "{{user.id}}" to staticLink
                                formik.setFieldValue(
                                  "staticLink",
                                  formik.values.staticLink + "{{user.id}}"
                                );
                              }}
                              class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-bold text-white bg-black"
                            >
                              Add Variable: userId
                            </button>
                          </div>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <p className="text-red-600 text-sm">
                              {formik.errors.staticLink && formik.touched.type
                                ? `Error: ${formik.errors.staticLink}`
                                : null}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}

                    {formik.values.type === "giftcode" && (
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:py-5">
                        <label
                          htmlFor="giftcode"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Giftcode <span className="text-rose-500">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Giftcode: SAMPLE01"
                          name="giftcode"
                          value={formik.values.giftcode}
                          onChange={(e) => {
                            // Convert to uppercase
                            e.target.value = e.target.value.toUpperCase();
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          className="block w-full shadow-sm focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border-gray-300 rounded-md"
                        />
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <p className="text-red-600 text-sm">
                            {formik.errors.giftcode && formik.touched.type
                              ? `Error: ${formik.errors.giftcode}`
                              : null}
                          </p>
                        </div>
                      </div>
                    )}

                    {formik.values.type === "library" && (
                      <>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                          <label
                            htmlFor="isPremium"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Stories <span className="text-rose-500">*</span>
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="sm:col-span-2">
                              <div className="mt-4 space-y-4">
                                <StorySelection
                                  formik={formik}
                                  stories={stories}
                                  setStories={setStories}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                          <label
                            htmlFor="isPremium"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Tours <span className="text-rose-500">*</span>
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="sm:col-span-2">
                              <div className="mt-4 space-y-4">
                                <TourSelection
                                  formik={formik}
                                  tours={tours}
                                  setTours={setTours}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                {/* Admin Settings */}
                {user.role === "admin" && (
                  <div className="divide-y divide-gray-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Admin Settings
                      </h3>
                    </div>
                    <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
                      <div className="pt-6 sm:py-5">
                        <div role="group" aria-labelledby="label-notifications">
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                            <div>
                              <div
                                className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                                id="label-notifications"
                              >
                                Publish Status{" "}
                                <span className="text-rose-500">*</span>
                              </div>
                              <p className="text-sm text-gray-500">
                                Whether this banner is published
                              </p>
                            </div>
                            <div className="sm:col-span-2">
                              <div className="max-w-lg">
                                <div className="mt-4 space-y-4">
                                  <select
                                    id="isDraft"
                                    name="isDraft"
                                    value={formik.values.isDraft}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className="max-w-lg block focus:ring-emerald-500 focus:border-emerald-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                  >
                                    <option value="true">Draft</option>
                                    <option value="false">Published</option>
                                  </select>
                                  <p className="text-red-600 text-sm">
                                    {formik.errors.isDraft &&
                                    formik.touched.isDraft
                                      ? `Error: ${formik.errors.isDraft}`
                                      : null}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pt-6 sm:py-5">
                        <div role="group" aria-labelledby="label-notifications">
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                            <div>
                              <div
                                className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                                id="label-notifications"
                              >
                                Enable Status{" "}
                                <span className="text-rose-500">*</span>
                              </div>
                              <p className="text-sm text-gray-500">
                                Whether this banner is visible to normal user
                                (User are not able to see this banner even if
                                they are the user)
                              </p>
                            </div>
                            <div className="sm:col-span-2">
                              <div className="max-w-lg">
                                <div className="mt-4 space-y-4">
                                  <select
                                    id="isEnabled"
                                    name="isEnabled"
                                    value={formik.values.isEnabled}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className="max-w-lg block focus:ring-emerald-500 focus:border-emerald-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                  >
                                    <option value="true">Enabled</option>
                                    <option value="false">Disabled</option>
                                  </select>
                                  <p className="text-red-600 text-sm">
                                    {formik.errors.isEnabled &&
                                    formik.touched.isEnabled
                                      ? `Error: ${formik.errors.isEnabled}`
                                      : null}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="py-5">
                <div className="flex justify-end items-center space-x-4">
                  <Link
                    to={props.fallback || "/stories"}
                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                  >
                    Go Back
                  </Link>
                  <div>
                    {isSaving ? (
                      <LoadingBtn text="Saving" />
                    ) : Object.keys(formik.errors).length > 0 ? (
                      <button
                        type="button"
                        disabled
                        className="btn bg-emerald-400 text-white cursor-not-allowed"
                      >
                        Save Changes
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn bg-emerald-500 hover:bg-emerald-600 text-white"
                      >
                        Save Changes
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
            {/* Content */}
          </div>
        </main>
      </div>
    </div>
  );
}

export default CreateBanner;
