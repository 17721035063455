import StoryLanguagePlayBtn from "./StoryLanguagePlayBtn";

export default function StoryListItem({ tour, story }) {
  return (
    <div className="bg-white">
      <div className="-ml-4 -mt-4 flex justify-between items-center flex-nowrap">
        <div className="ml-4 mt-4 overflow-hidden">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <img
                className="h-12 w-12 rounded-full object-cover"
                src={story.coverImg}
                alt=""
              />
            </div>
            <div className="ml-4">
              <h3 className="text-lg leading-6 font-medium text-gray-900 truncate">
                {story.title}
              </h3>
              <p className="text-sm text-gray-500 truncate">
                {story.transcript}
              </p>
            </div>
          </div>
        </div>
        <div className="ml-4 mt-4 flex-shrink-0 flex">
          <StoryLanguagePlayBtn
            tour={tour}
            story={story}
            languages={story.otherLangs}
          />
        </div>
      </div>
    </div>
  );
}
