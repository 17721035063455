import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

function UsersTableItem(props) {
  return (
    <tr>
      {/* <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        <div className="flex items-center">
          <label className="inline-flex">
            <span className="sr-only">Select</span>
            <input
              id={props.user.id}
              className="form-checkbox"
              type="checkbox"
              onChange={props.user.handleClick}
              checked={props.user.isChecked}
            />
          </label>
        </div>
      </td> */}
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="flex items-center">
          <div className="w-10 h-10 shrink-0 mr-2 sm:mr-3">
            {props.user?.avatar ? (
              <img
                className="rounded-full object-cover w-10 h-10"
                src={props.user.avatar}
                alt={props.user.username}
              />
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-10 h-10 text-gray-500"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
          </div>
          <div className="text-left">{props.user.username}</div>
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">{props.user.email}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        {props.user.role === "user" && (
          <div className="text-xs inline-flex font-extrabold bg-emerald-100 text-emerald-600 rounded-full text-center px-2.5 py-1">
            User
          </div>
        )}
        {props.user.role === "admin" && (
          <div className="text-xs inline-flex font-extrabold bg-rose-100 text-rose-600 rounded-full text-center px-2.5 py-1">
            Admin
          </div>
        )}
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left font-medium text-emerald-500">
          {props.user.isPremium}
          {props.user.isPremium === false && (
            <div className="text-xs inline-flex font-extrabold bg-emerald-100 text-emerald-600 rounded-full text-center px-2.5 py-1">
              Free Plan
            </div>
          )}
          {props.user.isPremium === true && (
            <div className="text-xs inline-flex font-extrabold bg-gradient-to-r from-main to-secondary text-white rounded-full text-center px-2.5 py-1">
              Premium
            </div>
          )}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left font-medium text-emerald-500">
          {props.user.isTippable === true && (
            <div className="text-xs inline-flex font-extrabold bg-emerald-100 text-emerald-600 rounded-full text-center px-2.5 py-1">
              Tippable
            </div>
          )}
          {props.user.isTippable === false && (
            <div className="text-xs inline-flex font-extrabold bg-rose-100 text-rose-600 rounded-full text-center px-2.5 py-1">
              Not Tippable
            </div>
          )}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        <Link
          to={`/allusers/${props.user.id}/edit`}
          className="bg-gray-50 hover:bg-gray-100 text-gray-600 block px-4 py-1 text-sm rounded-md"
        >
          Edit
        </Link>

        {/* <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="text-slate-400 hover:text-slate-500 rounded-full">
              <span className="sr-only">Menu</span>
              <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
                <circle cx="16" cy="16" r="2" />
                <circle cx="10" cy="16" r="2" />
                <circle cx="22" cy="16" r="2" />
              </svg>
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 w-20 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div>
                <Menu.Item>
                  <Link
                    to={`/allusers/${props.user.id}/edit`}
                    className="hover:bg-gray-100 text-gray-900 block px-4 py-3 text-sm"
                  >
                    Edit
                  </Link>
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu> */}
      </td>
    </tr>
  );
}

export default UsersTableItem;
