import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDebouncedValue } from "@mantine/hooks";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";
import useAuth from "../../hooks/useAuth";

import BannerCard from "../../partials/banners/BannerCard";
import PaginationClassic from "../../components/PaginationClassic";
import PaginationPageNumber from "../../components/PaginationPageNumber";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import SearchForm from "../../partials/actions/SearchForm";
import { ToastContainer } from "react-toastify";

function AllBanners() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  // Get User Profile
  const { auth } = useAuth();
  const user = auth.user;

  // Get Banners
  const [banners, setBanners] = useState(Array(20).fill(''))
  const [searchString, setSearchString] = useState('')
  const [debouncedSearchString] = useDebouncedValue(searchString, 200, {
    leading: true,
  })
  const [searchUsername, setSearchUsername] = useState('')
  const [debouncedSearchUsername] = useDebouncedValue(searchUsername, 200, {
    leading: true,
  })
  const [page, onChange] = useState(1)

  const [offset, setOffset] = useState(0)
  const [count, setCount] = useState(0)
  const limit = 20

  // Render Banners
  const getBanners = async () => {
    try {
      setIsFetching(true)
      // Check if debouncedSearchString
      let params = {}
      if (debouncedSearchString) {
        params = { ...params, search: debouncedSearchString }
      }

      if (debouncedSearchUsername) {
        params = { ...params, username: debouncedSearchUsername }
      }

      params = { ...params, locale: navigator.language, offset, limit }

      const res = await axiosPrivate.get(endpoints.BANNERS_URL, {
        signal: controller.signal,
        params: params,
      })

      const count =
        res.headers['x-total-count'] && parseInt(res.headers['x-total-count'])
      if (typeof count === 'number' && !Number.isNaN(count)) {
        setCount(count)
      } else {
        const resCount = await axiosPrivate.get(endpoints.BANNERS_COUNT_URL, {
          signal: controller.signal,
          params: params,
        })

        setCount(resCount.data.count)
      }

      // Check update page number if total page is less than current page
      if (page > res?.data?.totalPages) {
        onChange(res?.data?.totalPages)
      }

      setBanners(res?.data)
    } catch (error) {
      console.error(error)
    } finally {
      setIsFetching(false)
    }
  }
  
  useEffect(() => {
    // Render Banners
    getBanners()
  }, [debouncedSearchString, debouncedSearchUsername, offset, limit])

  return (
    <div className='flex h-screen overflow-hidden'>
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        user={user}
      />

      {/* Content area */}
      <div className='relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden'>
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
        />

        <main>
          <ToastContainer />
          <div className='px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto'>
            {/* Page header */}
            <h1 className='text-2xl md:text-3xl text-slate-800 font-bold mb-8'>
              All Banners ✨
            </h1>
            <div className='sm:flex sm:justify-between sm:items-center mb-8'>
              {/* Left: Title */}
              <div className='mb-4 sm:mb-0 grid grid-flow-col sm:auto-cols-max justify-between sm:justify-start sm:justify-end gap-2'>
                {/* Search form */}
                <SearchForm value={searchString} setValue={setSearchString} />
                {/* <SearchForm
                  value={searchUsername}
                  placeholder="Username..."
                  setValue={setSearchUsername}
                /> */}
                {/* Filter button */}
                <div>
                  <Link
                    to='/allbanners/new'
                    className='btn border-slate-200 hover:border-slate-300 bg-white text-emerald-500 hover:bg-emerald-500 hover:text-white'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='w-5 h-5 mr-2'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z'
                      />
                    </svg>
                    New Banner
                  </Link>
                </div>
              </div>

              {/* Right: Actions */}
              <div className='grid grid-flow-col sm:auto-cols-max justify-between gap-2 gap-x-4'>
                <div className='text-slate-600 text-sm flex items-center'>
                  <PaginationPageNumber
                    offset={offset}
                    limit={limit}
                    count={count}
                  />
                </div>
                {/* Pagination */}
                <PaginationClassic
                  offset={offset}
                  setOffset={setOffset}
                  limit={limit}
                  count={count}
                  result={banners.length}
                />
              </div>
            </div>

            {/* Table */}
            {/* Cards */}
            <div className="grid grid-cols-12 gap-6">
              {banners.map((item, idx) => {
                return (
                  <BannerCard
                    key={`bannercard-${idx}`}
                    id={item.id}
                    user={item.user}
                    title={item.title}
                    coverImg={item.coverImg}
                    address={item.address}
                    otherLangs={item.otherLangs}
                    type={item.type}
                    ordering={item.ordering}
                    locale={item.locale}
                    isLoading={isFetching}
                    fetchHandler={getBanners}
                    editUrl='/allbanners'
                  />
                )
              })}
              {!isFetching && banners.length === 0 && (
                <div className='col-span-12 flex items-center justify-center font-bold py-5 text-2xl'>
                  {/* Show Empty */}
                  No Found
                </div>
              )}
            </div>

            <div className='sm:flex sm:justify-between sm:items-center sm:mt-8'>
              {/* Left: Title */}
              <div className='mb-4 sm:mb-0 grid grid-flow-col sm:auto-cols-max justify-between sm:justify-start gap-2'></div>

              {/* Right: Actions */}
              <div className='grid grid-flow-col sm:auto-cols-max justify-between gap-2 gap-x-4'>
                <div className='text-slate-600 text-sm flex items-center'>
									<PaginationPageNumber
                    offset={offset}
                    limit={limit}
                    count={count}
                  />
                </div>
                {/* Pagination */}
                <PaginationClassic
                  offset={offset}
                  setOffset={setOffset}
                  limit={limit}
                  count={count}
                  result={banners.length}
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

export default AllBanners
