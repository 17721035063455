import React, { useCallback } from 'react'

/**
 * @param { {
 * 	limit: number,
 * 	offset: number,
 * 	count?: number,
 * 	result: number,
 * 	setOffset: (value: number) => void
 * } } props
 */
function PaginationClassic(props) {
  const { offset, limit, count, result, setOffset } = props

  const hasPrevious = useCallback(() => offset > 0, [offset])

  const hasNext = useCallback(
    () => (count !== undefined ? offset + limit < count : limit === result),
    [offset, limit, count, result],
  )

  const next = useCallback(() => {
    if (hasNext()) {
      setOffset(offset + limit)
    }
  }, [offset, limit, setOffset, hasNext])

  const previous = useCallback(() => {
    if (hasPrevious()) {
      setOffset(Math.max(offset - limit, 0))
    }
  }, [offset, limit, setOffset, hasPrevious])

  return (
    <div className='flex flex-col w-full sm:flex-row sm:items-center justify-between'>
      <nav
        className='sm:mb-0 sm:order-1'
        role='navigation'
        aria-label='Navigation'
      >
        <ul className='flex justify-between'>
          <li className='ml-3 first:ml-0'>
            {props.isFetching && (
              <span className='btn bg-white border-slate-200 text-slate-300 cursor-not-allowed gap-x-1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={3}
                  stroke='currentColor'
                  className='w-4 h-4'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75'
                  />
                </svg>
                Previous...
              </span>
            )}
            {!props.isFetching && (
              <button
                className={
                  hasPrevious()
                    ? 'btn bg-white border-slate-200 hover:border-slate-300 text-emerald-500 gap-x-1'
                    : 'btn bg-white border-slate-200 text-slate-300 cursor-not-allowed gap-x-1'
                }
                disabled={!hasPrevious()}
                onClick={() => previous()}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={3}
                  stroke='currentColor'
                  className='w-4 h-4'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75'
                  />
                </svg>
                Previous
              </button>
            )}
          </li>
          <li className='ml-3 first:ml-0'>
            {props.isFetching && (
              <span className='btn bg-white border-slate-200 text-slate-300 cursor-not-allowed gap-x-1'>
                Next...
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={3}
                  stroke='currentColor'
                  className='w-4 h-4'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75'
                  />
                </svg>
              </span>
            )}
            {!props.isFetching && (
              <button
                className={
                  hasNext()
                    ? 'btn bg-white border-slate-200 hover:border-slate-300 text-emerald-500 gap-x-1'
                    : 'btn bg-white border-slate-200 text-slate-300 cursor-not-allowed gap-x-1'
                }
                disabled={!hasNext()}
                onClick={() => next()}
              >
                Next
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={3}
                  stroke='currentColor'
                  className='w-4 h-4'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75'
                  />
                </svg>
              </button>
            )}
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default PaginationClassic
