import StoriusLogoS from "../../assets/logo_s.svg";

const CoverPicture = ({ img, isPremium }) => {
  const renderImage = () => {
    if (img !== "") {
      return (
        <div className="relative drop-shadow-md flex justify-center items-center -z-10">
          <img
            alt=""
            src={img}
            className="w-full aspect-square md:rounded-md object-cover"
          />
          {isPremium && (
            <div className="absolute z-10 right-5 top-5 text-sm inline-flex font-extrabold bg-gradient-to-r from-main to-secondary text-white rounded-full text-center px-2.5 py-1">
              Premium
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div
          className={`relative drop-shadow-md -z-10 flex justify-center items-center w-screen h-80 md:w-96 md:h-96 md:rounded-md bg-gradient-to-br from-storius-orange to-storius-blue`}
        >
          <img
            alt="No media is found"
            src={StoriusLogoS}
            className="h-20 w-20"
          />
          {isPremium && (
            <div className="absolute z-10 right-5 top-5 text-sm inline-flex font-extrabold bg-gradient-to-r from-main to-secondary text-white rounded-full text-center px-2.5 py-1">
              Premium
            </div>
          )}
        </div>
      );
    }
  };

  return <>{renderImage()}</>;
};

export default CoverPicture;
