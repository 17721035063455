import StoryListItem from "./StoryListItem";

export default function StoryList({ tour, storyList }) {
  return (
    <div className="relative bg-white md:drop-shadow-md rounded-md z-0">
      <ul role="list" className="relative divide-y divide-gray-200">
        {storyList.map((item) => (
          <li key={item.id} className="relative md:px-6 py-4">
            {/* Your content */}
            <StoryListItem tour={tour} story={item} />
          </li>
        ))}
      </ul>
    </div>
  );
}
